import {
    CONSOLE_INVOICES
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        business_data: false,
        invoices: [],
        paginator: {
            queries: 0,
            limit: 15,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_BUSINESS_DATA (state, value) {
            state.business_data = value
        },
        SET_INVOICES (state, value) {
            state.invoices = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${CONSOLE_INVOICES}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_BUSINESS_DATA', result.data.business_data)
            context.commit('SET_INVOICES', result.data.items)
            context.commit('SET_PAGINATOR', {
                queries: result.data.queries,
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async DOWNLOAD (context, data) {
            const opt = {
                responseType: 'blob'
            }
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${CONSOLE_INVOICES}/download?${qs}`, opt)
            } catch (error) {
                return error.response
            }
            return result
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
