<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="cart-check" :size="20" />
                    <span class="align-middle">{{$t('menu.products')}}</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <span v-html="$t('products.you_have_x_products', { PRODUCTS: paginator.total, QUOTA: quota.item_limit })"></span>
                        <Tooltip class="ms-2" value="View complete quota details in the Subscriptions page." icon="info-circle" />
                    </div>
                    <router-link :to="{name: 'console_store_product'}" v-if="paginator.total < quota.item_limit && (user.role === 'admin' || user.permissions.api.includes('POST_/v1/items'))" class="btn btn-sm btn-primary bg-gradient shadow-sm px-4">{{$t('products.add_product')}}</router-link>
                </div>
            </div>
        </div>

        <div v-if="paginator.total > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3 table-responsive">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr>
                                <th scope="col" class="border-0 pb-3 text-end">{{$t('products.state')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.name')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.category')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.unit')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.fields')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.images')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.sku')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.barcode')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('products.created')}}</th>
                                <th scope="col" class="border-0 pb-3 text-end">{{$t('products.actions')}}</th>
                            </tr>
                            <tr>
                                <th>
                                    <div class="input-group" role="group">
                                        <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span v-if="filter.state === PRODUCT_STATE_ACTIVE_INAPP">{{$t('products.active_in_app')}}</span>
                                            <span v-if="filter.state === PRODUCT_STATE_ACTIVE_INSHOP">{{$t('products.active_in_shop')}}</span>
                                            <span v-if="filter.state === PRODUCT_STATE_ACTIVE_FULL">{{$t('products.active_in_app_and_shop')}}</span>
                                            <span v-if="filter.state === PRODUCT_STATE_INACTIVE">{{$t('products.inactive')}}</span>
                                            <span v-if="filter.state === PRODUCT_STATE_ARCHIVED">{{$t('products.archived')}}</span>
                                            <span v-if="filter.state === null">{{$t('products.all_states')}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-start dropdown-menu-long fw-normal small">
                                            <li @click="filter.state = PRODUCT_STATE_ACTIVE_INAPP" class="p-2 pe-pointer"><BootstrapIcon color="fill-teal-500 text-teal-500" class="me-2 align-middle" name="circle-fill" :size="16" /> {{$t('products.active_in_app')}}</li>
                                            <li @click="filter.state = PRODUCT_STATE_ACTIVE_INSHOP" class="p-2 pe-pointer"><BootstrapIcon color="fill-teal-500 text-teal-500" class="me-2 align-middle" name="circle-fill" :size="16" /> {{$t('products.active_in_shop')}}</li>
                                            <li @click="filter.state = PRODUCT_STATE_ACTIVE_FULL" class="p-2 pe-pointer"><BootstrapIcon color="fill-teal-500 text-teal-500" class="me-2 align-middle" name="circle-fill" :size="16" /> {{$t('products.active_in_app_and_shop')}}</li>
                                            <li @click="filter.state = PRODUCT_STATE_INACTIVE" class="p-2 pe-pointer"><BootstrapIcon color="fill-gray-500 text-gray-500" class="me-2 align-middle" name="circle-fill" :size="16" /> {{$t('products.inactive')}}</li>
                                            <li @click="filter.state = PRODUCT_STATE_ARCHIVED" class="p-2 pe-pointer"><BootstrapIcon color="fill-red-500 text-red-500" class="me-2 align-middle" name="circle-fill" :size="16" /> {{$t('products.archived')}}</li>
                                        </ul>
                                        <button v-if="filter.state !== null" @click="filter.state = null" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th>
                                    <div class="input-group" role="group">
                                        <input v-model="filter.name" type="text" class="form-control" :placeholder="$t('products.filter_by_name')">
                                        <button v-if="filter.name.length > 0" @click="filter.name = ''" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th>
                                    <div v-if="categories.length > 0" class="input-group" role="group">
                                        <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span v-if="filter.category && filter.category.id === DEFAULT_CATEGORY_ID">{{$t('products.uncategorized')}}</span>
                                            <span v-else-if="filter.category && filter.category.id">{{filter.category.name}}</span>
                                            <span v-else>{{$t('products.all_categories')}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-start fw-normal small">
                                            <li @click="filter.category = {id: DEFAULT_CATEGORY_ID}" class="p-2 pe-pointer">{{$t('products.uncategorized')}}</li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li @click="filter.category = category" v-for="(category, index) in categories" :key="index" class="p-2 pe-pointer">
                                                {{category.name}}
                                            </li>
                                        </ul>
                                        <button v-if="typeof filter.category.id !== 'undefined'" @click="filter.category = {}" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <div class="input-group" role="group">
                                        <input v-model="filter.sku" type="text" class="form-control" :placeholder="$t('products.filter_by_sku')">
                                        <button v-if="filter.sku.length > 0" @click="filter.sku = ''" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th>
                                    <div class="input-group" role="group">
                                        <input v-model="filter.barcode" type="text" class="form-control" :placeholder="$t('products.filter_by_barcode')">
                                        <button v-if="filter.barcode.length > 0" @click="filter.barcode = ''" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="items.length > 0" class="text-gray-700 border-top-0">
                            <tr v-for="(item, index) in items" :key="index" class="align-middle">
                                <td class="text-center">
                                    <span v-if="item.state === PRODUCT_STATE_ACTIVE_INAPP" class="badge rounded-pill bg-teal-500">{{$t('products.active_in_app')}}</span>
                                    <span v-if="item.state === PRODUCT_STATE_ACTIVE_INSHOP" class="badge rounded-pill bg-teal-500">{{$t('products.active_in_shop')}}</span>
                                    <span v-if="item.state === PRODUCT_STATE_ACTIVE_FULL" class="badge rounded-pill bg-teal-500">{{$t('products.active_in_app_and_shop')}}</span>
                                    <span v-else-if="item.state === PRODUCT_STATE_INACTIVE" class="badge rounded-pill bg-gray-500">{{$t('products.inactive')}}</span>
                                    <span v-else-if="item.state === PRODUCT_STATE_ARCHIVED" class="badge rounded-pill bg-red-500">{{$t('products.archived')}}</span>
                                </td>
                                <td>
                                    <span class="align-middle"><HighlightText :search="filter.name" :value="item.name" /></span>
                                </td>
                                <td>{{item.category_id ? item.category.name : $('products.uncategorized')}}</td>
                                <td>{{$t('units.unit_abbr_'+item.unit)}}</td>
                                <td>
                                    <span v-if="item.data && item.data.fields && item.data.fields.length > 0">{{item.data.fields.length}} {{$t('products.fields')}}</span>
                                    <span v-else></span>
                                </td>
                                <td>
                                    <div v-if="item.images.length > 0" class="d-flex">
                                        <div v-for="(image, index) in item.images" :key="index" class="border rounded overflow-hidden d-inline-block me-2 position-relative" style=" min-width: 30px; max-width: 30px;">
                                            <div class="cover-image" :style="'background-image: url(' + SPACES_PATH + image.url + ')'"></div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-start">
                                    <Clipboard v-if="item.sku" :value="item.sku" class="me-2" />
                                    <HighlightText :search="filter.sku" :value="item.sku" />
                                </td>
                                <td class="text-start">
                                    <Clipboard v-if="item.barcode" :value="item.barcode" class="me-2" />
                                    <HighlightText :search="filter.barcode" :value="item.barcode" />
                                </td>
                                <td class="text-nowrap">{{$filters.PrettyDate(item.created_at)}}</td>
                                <td class="text-nowrap text-start">
                                    <router-link :to="{name: 'console_store_product', query: {product_id: item.id}}" v-if="item.state !== PRODUCT_STATE_ARCHIVED && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/items/:item_id'))" class="btn btn-sm btn-secondary bg-gradient shadow-sm mx-1">{{$t('controls.edit')}}</router-link>
                                    <button v-if="item.state !== PRODUCT_STATE_ARCHIVED && (user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/items/:item_id'))" type="button" @click="request_confirm_archive_item(item)" class="btn btn-sm btn-danger bg-gradient shadow-sm mx-1">{{$t('controls.archive')}}</button>
                                    <button v-if="item.state !== PRODUCT_STATE_ARCHIVED && item.state === PRODUCT_STATE_ACTIVE_INAPP && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/items/:item_id'))" type="button" @click="toggle_state(item, PRODUCT_STATE_INACTIVE)" class="btn btn-sm btn-warning bg-gradient shadow-sm mx-1">{{$t('controls.disable')}}</button>
                                    <!-- <button v-if="item.state !== 9 && item.state === 1 && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/items/:item_id'))" type="button" @click="toggle_state(item, 0)" class="btn btn-sm btn-success bg-gradient shadow-sm mx-1">{{$t('controls.enable')}}</button> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else class="text-gray-700 border-top-0">
                            <tr class="align-middle">
                                <td colspan="9">
                                    <EmptyResponse :with_border="false" :description="$t('products.no_products_match_filter')" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse :description="$t('products.no_products_created')" />
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'items'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalConfirmArchiveItem :item="selected_item" />
    </div>
</template>

<script>
import {
    PRODUCT_STATE_ACTIVE_INAPP,
    PRODUCT_STATE_INACTIVE,
    PRODUCT_STATE_ACTIVE_INSHOP,
    PRODUCT_STATE_ACTIVE_FULL,
    PRODUCT_STATE_ARCHIVED,
    DEFAULT_CATEGORY_ID,
    SPACES_PATH
} from '@/common/constants.js'
import Tooltip from '@/common/directive/Tooltip'
import Clipboard from '@/common/directive/Clipboard'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryItemStore from '@/common/store/inventory_item.js'
import InventoryCategoryStore from '@/common/store/inventory_category.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import ModalConfirmArchiveItem from '@/common/modal/console/modal-confirm_archive_item.vue'
import Paginator from '@/common/directive/Paginator.vue'
import HighlightText from '@/common/directive/HighlightText.vue'
import is_numeric from '@/common/mixin/is_numeric.js'

export default {
    mixins: [is_numeric],
    components: {
        Tooltip,
        HighlightText,
        Clipboard,
        BootstrapIcon,
        Paginator,
        EmptyResponse,
        ModalConfirmArchiveItem
    },
    data: function () {
        return {
            SPACES_PATH,
            PRODUCT_STATE_ACTIVE_INAPP,
            PRODUCT_STATE_INACTIVE,
            PRODUCT_STATE_ACTIVE_INSHOP,
            PRODUCT_STATE_ACTIVE_FULL,
            PRODUCT_STATE_ARCHIVED,
            DEFAULT_CATEGORY_ID,
            paginate_timeout: 0,
            selected_item: {},
            filter: {
                category: {},
                name: '',
                sku: '',
                barcode: '',
                state: null
            }
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        },
        categories: () => InventoryCategoryStore.state.categories,
        items: function () {
            const items = InventoryItemStore.state.items
            return items
        },
        paginator: () => InventoryItemStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        quota: function () {
            return this.$store.state.quota
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        },
        '$route.query.page': function (nv) {
            if (this.is_numeric(this.$route.query.page)) {
                InventoryItemStore.commit('SET_PAGE', parseInt(this.$route.query.page, 10))
            } else {
                InventoryItemStore.commit('SET_PAGE', 1)
            }
            this.paginate()
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/items/set_page')
        this.$bus.$on('Paginator/items/set_page', (value) => {
            this.set_page(value)
        })

        // Emitted on product create/edit
        this.$bus.$off('Products/paginate')
        this.$bus.$on('Products/paginate', () => {
            this.paginate()
        })

        // Attempt to read page from URL query
        if (this.is_numeric(this.$route.query.page)) {
            InventoryItemStore.commit('SET_PAGE', parseInt(this.$route.query.page, 10))
        }

        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            InventoryItemStore.commit('SET_PAGE', page)
            this.$router.push({ name: 'console_store_products', query: { page } }).catch(() => {})
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.category && typeof self.filter.category.id !== 'undefined') {
                    query.category_id = self.filter.category.id
                }
                if (self.filter.name && self.filter.name.length >= 1) {
                    query.name = self.filter.name
                }
                if (self.filter.sku && self.filter.sku.length >= 1) {
                    query.sku = self.filter.sku
                }
                if (self.filter.barcode && self.filter.barcode.length >= 1) {
                    query.barcode = self.filter.barcode
                }
                if (self.filter.state !== null) {
                    query.state = self.filter.state
                }
                await InventoryItemStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 200)
        },
        toggle_state: async function (item, state) {
            const res = await InventoryItemStore.dispatch('UPDATE', {
                id: item.id,
                params: {
                    state
                }
            })

            if (res.status === 200) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been successfully applied!', level: 'success' })
                this.paginate()
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        request_confirm_archive_item: function (item) {
            this.selected_item = item
            this.$bus.$emit('Modal/show/confirm-archive-item')
        }
    }
}
</script>
