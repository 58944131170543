import {
    PATH_CUSTOMERS
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        customers: [],
        paginator: {
            limit: 20,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_CUSTOMERS (state, value) {
            state.customers = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${PATH_CUSTOMERS}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_CUSTOMERS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async ANONYMIZE (context, data) {
            let response = {}
            try {
                response = await axios.put(`${PATH_CUSTOMERS}/${data.id}`, data.params)
                context.dispatch('PAGINATE', {})
            } catch (error) {
                return error.response
            }
            return response
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
