<template>
    <Modal :close_button="true" :size="'modal-lg'" mid="create-ticket">
        <template v-slot:header>
            <span>Create Ticket</span>
        </template>
        <template v-slot:body>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Subject</label>
                    <input v-model="form.subject" type="text" class="form-control" placeholder="e.g. My API Keys does not work" aria-label="Subject">
                    <small class="d-block mt-1 mb-2">Write a short title describing the issue.</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Description</label>
                    <textarea v-model="form.description" rows="10" class="form-control" aria-label="description"></textarea>
                    <small class="d-block mt-1 mb-2">Provide a detailed description of the issue you are encountering. If required also upload some screenshots and source code.</small>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!valid_form && !loading" type="button" class="btn btn-primary pl-3 pr-3 ml-3 mr-3" @click="submit()">
                <span class="small">CREATE</span>
            </button>
            <button type="button" class="btn btn-secondary pl-3 pr-3 ml-3 mr-3" @click="cancel()">
                <span class="small">CANCEL</span>
            </button>
        </template>
    </Modal>
</template>

<script>
import TicketStore from '@/common/store/ticket.js'
// import BootstrapIcon from '@/common/directive/BootstrapIcon'

export default {
    components: {
        // BootstrapIcon
    },
    data: function () {
        return {
            loading: false,
            form: {
                subject: '',
                description: '',
                file: {}
            }
        }
    },
    computed: {
        valid_form: function () {
            return this.form.subject.length > 3 && this.form.description.length > 30
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/create-ticket')
        },
        submit: async function () {
            this.loading = true

            const payload = {
                subject: this.form.subject,
                description: this.form.description
            }

            const response = await TicketStore.dispatch('CREATE', payload)

            if (response.status === 0) {
                this.$bus.$emit('Modal/hide/create-ticket')
                this.form.subject = ''
                this.form.description = ''
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The ticket has been successfully created!', level: 'success' })
                this.$router.push('/console/helpdesk/' + response.id)
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
