<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="person-circle" :size="20" />
                    <span class="align-middle">{{$t('menu.my_account')}}</span>
                </h1>
            </div>
        </div>

        <!-- Email -->
        <div class="row mb-4">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center bg-white rounded shadow-sm px-4 py-3">
                    <div>
                        <strong class="text-gray-600 d-inline-block pb-2">Email</strong>
                        <div>{{user.email}}</div>
                    </div>
                    <!-- <div>
                        <button type="button" class="btn btn-outline-primary">Change Email</button>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- 2FA -->
        <!-- <div class="row mb-4">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center bg-white rounded shadow-sm px-4 py-3">
                    <div>
                        <div class="pb-2">
                            <strong class="text-gray-600 align-middle me-3">2 Factor Auth</strong>
                            <span v-if="false" class="badge bg-brand align-middle">ENABLED</span>
                            <span v-else class="badge bg-danger align-middle">DISABLED</span>
                        </div>
                        <div>Boost your account security with 2FA.<br>When activated, you will need to enter a unique code generated using an Authentication App.</div>
                    </div>
                    <div>
                        <router-link to="/2fa" class="btn btn-outline-success">Enable</router-link>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Email Notifications -->
        <!-- <div class="row mb-4">
            <div class="col">
                <div class="d-flex align-items-start bg-white rounded shadow-sm px-4 py-3">
                    <div class="pe-5">
                        <strong class="text-gray-600">Email Notifications</strong>
                    </div>
                    <div v-if="user">
                        <div class="form-check form-switch mb-2">
                            <input v-model="send_quota_alert" class="form-check-input" type="checkbox" id="send_quota_alert">
                            <label class="form-check-label pe-pointer" for="send_quota_alert">Send notification when reached 90% quota</label>
                        </div>
                        <div class="form-check form-switch">
                            <input v-model="send_limit_alert" class="form-check-input" type="checkbox" id="send_limit_alert">
                            <label class="form-check-label pe-pointer" for="send_limit_alert">Send notification when cap reached</label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Logo Upload -->
        <!-- <div v-if="has_ultimate" class="row mb-4">
            <div class="col">
                <div class="bg-white rounded shadow-sm px-4 py-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="pe-5">
                            <strong class="text-gray-600">My Logos</strong>
                            <div>Assign logos to domains from <router-link to="/dashboard">API Keys</router-link> page.</div>
                            <div class="d-flex align-items-center mt-2">
                                <FileUpload v-if="organization.files.length < domain_limit" tag="organization_logo" text="Upload logo" />
                                <Tooltip class="d-inline-block px-3" :value="'JPEG or PNG image<br>Filesize <= '+IMAGE_MAX_FILESIZE/1000+'KB<br>1:1 Aspect Ratio<br>Side between 50px to 100px'" icon="info-circle" />
                            </div>
                        </div>
                        <div v-if="organization.files.length > 0" class="rounded overflow-hidden border">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr class="align-middle">
                                        <td><p class="text-gray-600 m-0 text-center">Logo</p></td>
                                        <td><p class="text-gray-600 m-0 text-center">Created at</p></td>
                                        <td><p class="text-gray-600 m-0 text-center">Actions</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(file, index) in organization.files" :key="index">
                                        <td class="align-middle"><p class="text-gray-700 m-0 text-center"><img :src="'data:image/jpeg;base64, ' + file.src" style="width: 50px; height: 50px;"></p></td>
                                        <td class="align-middle"><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(file.created_at)}}</p></td>
                                        <td class="text-center align-middle">
                                            <button @click="delete_file(file)" type="button" class="btn btn-sm btn-outline-danger">Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="align-middle h-100">No logos uploaded yet.</div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Sessions -->
        <div class="row mb-4">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center bg-white rounded shadow-sm px-4 py-3">
                    <div>
                        <strong class="text-gray-600">My Sessions</strong>
                        <div>All the active sessions. To close the current session use the Logout function.</div>
                    </div>
                    <div class="rounded overflow-hidden border">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr class="align-middle">
                                    <td><p class="text-gray-600 m-0 text-center">IP</p></td>
                                    <td><p class="text-gray-600 m-0 text-center">Browser</p></td>
                                    <td><p class="text-gray-600 m-0 text-center">Created at</p></td>
                                    <td><p class="text-gray-600 m-0 text-center">Actions</p></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(session, index) in sessions" :key="index">
                                    <td><p class="text-gray-700 m-0 text-center">{{session.ip}}</p></td>
                                    <td><p class="text-gray-700 m-0 text-center">{{$filters.ParseUserAgent(session.ua)}}</p></td>
                                    <td><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(session.created_at)}}</p></td>
                                    <td class="text-center">
                                        <span v-if="session.current" class="text-gray-600">Current Session</span>
                                        <button v-else @click="delete_session(session)" type="button" class="btn btn-sm btn-outline-danger">Remove</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- GDPR Export -->
        <div class="row mb-4">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center bg-white rounded shadow-sm px-4 py-3">
                    <div>
                        <strong class="text-gray-600">Export My Data</strong>
                        <div>Get a copy of all the data we store for your account.<br>You will be prompted to download document with all your data.<br>This operation might take a few seconds..</div>
                    </div>
                    <div>
                        <button :disabled="gdpr_export_loading" @click="gdpr_export()" type="button" class="btn btn-outline-secondary">Get Data</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
// import Paginator from '@/common/directive/Paginator.vue'
import SessionStore from '@/common/store/session.js'
// import UserStore from '@/common/store/user.js'
// import FileUpload from '@/common/directive/FileUpload.vue'
// import Tooltip from '@/common/directive/Tooltip.vue'
// import OrganizationStore from '@/common/store/organization.js'
// import ModalConfirmDeleteAccount from '@/common/modal/console/modal-confirm_delete_account.vue'
import {
    // ORGANIZATION_PROPERTIES,
    // BILLING_PROPERTIES,
    IMAGE_MAX_FILESIZE
} from '@/common/constants.js'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        BootstrapIcon
        // FileUpload,
        // Tooltip,
        // ModalConfirmDeleteAccount
        // Paginator
    },
    data: function () {
        return {
            gdpr_export_loading: false,
            paginate_timeout: 0,
            send_quota_alert: null,
            send_limit_alert: null,
            show_limit_error: null,
            error_code: -1,
            form: {
                name: null,
                address: null,
                zipcode: null,
                state: null,
                city: null,
                country: null,
                phone: null,
                vat_number: null,
                trade_registry: null,
                bank_account: null,
                bank_name: null,
                bank_currency: null
            }
        }
    },
    computed: {
        IMAGE_MAX_FILESIZE: () => IMAGE_MAX_FILESIZE,
        sessions: () => SessionStore.state.sessions,
        paginator: () => SessionStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        user: function () {
            return this.$store.state.user
        },
        organization: function () {
            return this.$store.state.organization
        },
        domain_limit: function () {
            return this.$store.state.subscriptions.reduce((acc, sub) => acc + sub.domain_limit, 0)
        },
        locale: function () {
            return this.$i18n.locale
        }
    },
    watch: {
        // send_quota_alert: async function (newv, oldv) {
        //     if (oldv !== null) {
        //         await this.update_organization_settings()
        //     }
        // },
        // send_limit_alert: async function (newv, oldv) {
        //     if (oldv !== null) {
        //         await this.update_organization_settings()
        //     }
        // },
        // show_limit_error: async function (newv, oldv) {
        //     if (oldv !== null) {
        //         await this.update_organization_settings()
        //     }
        // },
        // quota_overflow: async function (newv, oldv) {
        //     if (oldv !== null) {
        //         await this.update_organization_settings()
        //     }
        // }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        // this.send_quota_alert = this.organization.send_quota_alert
        // this.send_limit_alert = this.organization.send_limit_alert
        // this.show_limit_error = this.organization.show_limit_error

        // this.form.name = this.organization.name
        // this.form.address = this.organization.address
        // this.form.zipcode = this.organization.zipcode
        // this.form.state = this.organization.state
        // this.form.city = this.organization.city
        // this.form.country = this.organization.country
        // this.form.phone = this.organization.phone
        // this.form.bank_account = this.organization.bank_account
        // this.form.bank_name = this.organization.bank_name
        // this.form.bank_currency = this.organization.bank_currency
        // this.form.vat_number = this.organization.vat_number
        // this.form.trade_registry = this.organization.trade_registry

        await this.paginate()

        // this.$bus.$off('FileUpload/completed/organization_logo')
        // this.$bus.$on('FileUpload/completed/organization_logo', async (value) => {
        //     await this.$store.dispatch('GET_INFO')
        // })

        // this.$bus.$off('Paginator/questions/set_page')
        // this.$bus.$on('Paginator/questions/set_page', (value) => {
        //     this.set_page(value)
        // })
    },
    methods: {
        date_format: function (date) {
            return DateFormat(new Date(date), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS }).toUpperCase()
        },
        set_page: async function (page) {
            SessionStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            // self.$bus.$emit('ListingLoader/show')
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                await SessionStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
                // self.$bus.$emit('ListingLoader/hide')
            }, 50)
        },
        delete_session: async function (session) {
            await SessionStore.dispatch('DELETE_SESSION', {
                session_id: session.id
            })
        },
        // delete_file: async function (file) {
        //     await this.$store.dispatch('DELETE_FILE', {
        //         file_id: file.id
        //     })
        // },
        gdpr_export: async function (setting, value) {
            this.export_loading = true
            const response = await this.$store.dispatch('GDPR_EXPORT')
            this.export_loading = false

            if (response.status === 0) {
                this.trigger_download(response)
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        },
        trigger_download: function (data) {
            const a = document.createElement('a')
            a.download = `tapgiant_export_${Date.now()}.json`
            a.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2))
            a.click()
        }
    }
}
</script>
