<template>
    <div class="form-signin">
        <header role="banner" class="text-center">
            <router-link to="/" class="brand-logo">
                <img src="/img/identity/tgt_ro.png" alt="logo">
            </router-link>
        </header>

        <div class="p-4 shadow-sm bg-white rounded">
            <div>
                <h4 class="text-gray-700 text-center">{{$t('one_last_step')}}</h4>
                <div class="text-gray-600 text-center fs-6">
                    <p>{{$t('login_using_instructions', {EMAIL: email})}}</p>
                </div>
                <div class="row fs-6 pt-4">
                    <div class="col text-center">
                        <router-link to="/login" class="px-3 py-2 fs-6 text-teal-500"><u><strong>{{$t('resend_mail')}}</strong></u></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center pt-3">
            <router-link to="/" title="Back to homepage">{{$t('back_to_homepage')}}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            error: 0,
            status: -1,
            email: ''
        }
    },
    computed: {
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    created: async function () {
        if (this.is_authorized) {
            this.$router.replace('/console/account')
        }

        if (typeof this.$route.query.q === 'undefined') {
            this.$router.replace({
                name: 'account_login_step1'
            }).catch(() => {})
        } else {
            this.email = this.$route.query.q
        }
    },
    methods: {
    }
}
</script>
