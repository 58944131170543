<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-delete-member">
        <template v-slot:header>Remove member</template>
        <template v-slot:body>
            <p v-if="member">Member: <strong>{{member.username}}</strong></p>
            <p>Notice: the team member will be permanently deleted and access revoked. The team member will no longer be able to provision or create requisitions.</p>
            <p>All the current requisitions created by this team member will be discarded.</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">Delete</button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryMemberStore from '@/common/store/inventory_member.js'

export default {
    props: {
        member: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            agree: false
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-delete-member')
        },
        submit: async function () {
            const response = await InventoryMemberStore.dispatch('DELETE_MEMBER', { member_id: this.member.id })
            if (response.status === 0) {
                this.agree = false
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The member has been removed', level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-delete-member')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        }
    }
}
</script>
