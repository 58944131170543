<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-withdraw-user">
        <template v-slot:header>{{$t('console.confirm_removal')}}</template>
        <template v-slot:body>
            <p v-if="user">{{$t('console.user')}}: <strong>{{user.email || user.pemail}}</strong></p>
            <p><span class="badge bg-warning">{{$t('console.notice')}}</span> {{$t('console.confirm_user_removal_description')}}</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">{{$t('controls.remove')}}</button>
            <button type="button" class="btn px-4" @click="cancel()">{{$t('controls.cancel')}}</button>
        </template>
    </Modal>
</template>

<script>
import UserStore from '@/common/store/user.js'

export default {
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-withdraw-user')
        },
        submit: async function () {
            const response = await UserStore.dispatch('WITHDRAW', { id: this.user.id })
            if (response.status === 200) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The user has been successfully removed from this organization.', level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-withdraw-user')
                this.$bus.$emit('Organization/info')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        }
    }
}
</script>
