<template>
    <div class="row">
        <div class="col text-center">
            <nav v-if="pages>0">
                <ul class="listing-paginator justify-content-center p-0 mb-2">
                    <li :class="{'disabled': page===1}" class="paginator-item">
                        <button :disabled="page===1" class="btn btn-sm btn-outline-secondary p-1 p-sm-2" @click="set_page(page-1, $event)">
                            <BootstrapIcon name="chevron-left" :size="16" />
                        </button>
                    </li>
                    <li v-for="key in paginator_items" :key="key.id" :class="{'active': page===(key)}" class="paginator-item">
                        <button v-if="key >= 0" :disabled="page===(key)" class="btn btn-sm btn-outline-secondary p-1 p-sm-2" @click="set_page(key, $event)">
                            <span class="px-1">{{key}}</span>
                        </button>
                        <span v-else class="paginator-text c-default text-gray-500">...</span>
                    </li>
                    <li :class="{'disabled': page===(pages)}" class="paginator-item">
                        <button :disabled="page===(pages)" class="btn btn-sm btn-outline-secondary p-1 p-sm-2" @click="set_page(page+1, $event)">
                            <BootstrapIcon name="chevron-right" :size="16" />
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        tag: {
            type: String,
            default: ''
        },
        page: {
            type: Number,
            default: 1
        },
        limit: {
            type: Number,
            default: 0
        },
        records: {
            type: Number,
            default: 0
        }
    },
    data: function () {
        return {
            pages: 0,
            paginator_items: []
        }
    },
    watch: {
        records: function () {
            this.set_pages()
        },
        limit: function () {
            this.set_pages()
        },
        page: function () {
            this.set_pages()
        }
    },
    created: function () {
        this.set_pages()
    },
    methods: {
        set_pages: function () {
            this.pages = Math.ceil(this.records / this.limit)
            this.generate_paginator(this.page, this.pages)
        },
        generate_paginator: function (cp, maxp) {
            const current_page = cp
            const max_page = maxp
            const neighbors = 1
            const left = current_page - neighbors
            const right = current_page + neighbors + 1
            const range = []
            const paginator_items = []

            for (let i = 1; i <= max_page; i++) {
                if (i === 1 || i === max_page || (i >= left && i < right)) {
                    range.push(i)
                }
            }

            let l
            for (const i of range) {
                if (l) {
                    if (i - l === 2) {
                        paginator_items.push(l + 1)
                    } else if (i - l !== 1) {
                        paginator_items.push('...')
                    }
                }
                paginator_items.push(i)
                l = i
            }

            this.paginator_items = paginator_items
        },
        set_page: function (val, $event) {
            if (this.page === val) {
                return
            }
            $event.stopPropagation()
            if (this.tag) {
                this.$bus.$emit(`Paginator/${this.tag}/set_page`, val)
            }
        }
    }
}
</script>
