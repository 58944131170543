import {
    SUBSCRIPTIONS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
// import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        paginator: {
            limit: 10,
            count: 0,
            page: 1
        }
    },
    mutations: {
    },
    actions: {
        async ACTIVATE_TRIAL (context, plan_id) {
            let response = {}
            try {
                response = await axios.post(`${SUBSCRIPTIONS_PATH}/${plan_id}`)
            } catch (error) {
                return error.response
            }
            return response
        },
        async CHECKOUT (context, data) {
            let response = {}
            try {
                response = await axios.post(`${SUBSCRIPTIONS_PATH}/checkout`, data)
            } catch (error) {
                return error.response.data
            }
            return response.data
        },
        async UPDATE (context, data) {
            let result = {}
            try {
                result = await axios.put(`${SUBSCRIPTIONS_PATH}/${data.id}`, data.data)
            } catch (error) {
                return error.response.data
            }
            return result.data
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
