import {
    SETTINGS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'

const store = {
    actions: {
        async RETRIEVE (context, scope) {
            let response
            try {
                response = await axios.get(`${SETTINGS_PATH}?scopes=${scope.join(',')}`)
            } catch (error) {
                return error.response
            }
            return response
        },
        async CREATE (context, data) {
            let response = {}
            try {
                response = await axios.post(`${SETTINGS_PATH}`, data.params)
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE (context, data) {
            let response = {}
            try {
                response = await axios.put(`${SETTINGS_PATH}/${data.id}`, data.params)
            } catch (error) {
                return error.response
            }
            return response
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
