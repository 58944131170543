<template>
    <Modal :close_button="true" :size="'modal-lg'" mid="manage-user-enrollment">
        <template v-slot:header>
            <span></span>
            <span v-if="user && user.id">{{$t('console.manage_permissions')}}</span>
            <span v-else>{{$t('console.enroll_user')}}</span>
        </template>
        <template v-slot:body>
            <div class="row mb-3">
                <div class="form-group has-validation">
                    <label class="form-label" for="email">E-mail</label>
                    <BootstrapIcon v-if="valid_email" class="d-inline-block float-end" color="text-teal-500 fill-teal-500" name="check-circle" :size="16" />
                    <BootstrapIcon v-else class="d-inline-block float-end" color="text-gray-600 fill-gray-600" name="x-circle" :size="16" />
                    <input :disabled="user && user.id" ref="email" :class="{'is-invalid': [4002].includes(error_code) || error_code !== -1}" v-model="form.email" type="email" class="form-control form-control-lg fs-6" id="email" :placeholder="$t('email_address')">
                    <div class="invalid-feedback">
                        <span v-if="error_code === 1">{{$t('disposable_emails_not_accepted')}}</span>
                        <span v-if="error_code === 4002">{{$t('email_format_not_valid')}}</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">{{$t('console.role')}}</label>
                    <div class="dropdown">
                        <button type="button" class="btn btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{$t('console.role_'+form.role)}}</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-start">
                            <li @click="form.role = value.key" v-for="(value, key) in ROLES" :key="key" class="p-2 pe-pointer">
                                <strong>{{value.label}}</strong>
                                <p class="small">{{value.description}}</p>
                            </li>
                        </ul>
                    </div>
                    <small class="d-block mt-1 mb-2">{{$t('console.role_'+form.role+'_description')}}</small>
                </div>
            </div>
            <div v-if="form.role === 'custom'" class="row mb-3">
                <div class="col">
                    <div v-for="(section, key1) in PERMISSIONS" :key="key1" class="mb-3 bg-gray-100 rounded py-2 px-3">
                        <div class="mb-2 d-flex">
                            <span class="me-3 flex-fill fw-bold">{{section.name}}</span>
                            <span @click="include_permissions(Object.keys(section.permissions))" class="text-blue-500 ms-3 pe-pointer noselect">{{$t('console.select_all')}}</span>
                            <span @click="exclude_permissions(Object.keys(section.permissions))" class="text-blue-500 ms-3 pe-pointer noselect">{{$t('console.clear_all')}}</span>
                        </div>
                        <div @click="toggle_permission(key2)" v-for="(perm, key2) in section.permissions" :key="key2" class="d-inline-block me-3 noselect">
                            <BootstrapIcon v-if="form.permissions.includes(key2)" color="fill-blue-600 text-blue-600" name="check-square" :size="18" class="me-2" />
                            <BootstrapIcon v-else color="fill-gray-500 text-gray-500" name="square" :size="18" class="me-2" />
                            <label class="align-middle">{{perm}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!valid_form || loading" type="button" class="btn btn-primary pl-3 pr-3 ml-3 mr-3" @click="submit()">
                <span v-if="user && user.id">{{$t('controls.update')}}</span>
                <span v-else>{{$t('controls.enroll')}}</span>
            </button>
            <button type="button" class="btn btn-secondary pl-3 pr-3 ml-3 mr-3" @click="cancel()">
                <span class="small">{{$t('controls.cancel')}}</span>
            </button>
        </template>
    </Modal>
</template>

<script>
import UserStore from '@/common/store/user.js'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import { REGEX_EMAIL } from '@/common/constants'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            loading: false,
            error_code: -1,
            form: {
                email: '',
                role: 'admin',
                permissions: []
            },
            ROLES: [
                {
                    key: 'admin',
                    label: this.$t('console.role_admin'),
                    description: this.$t('console.role_admin_description')
                },
                {
                    key: 'custom',
                    label: this.$t('console.role_custom'),
                    description: this.$t('console.role_custom_description')
                }
            ],
            PERMISSIONS: [
                {
                    name: this.$t('route_section.activity_reports'),
                    permissions: {
                        'GET_/v1/timeline': this.$t('route.view_activity_timeline'),
                        'GET_/v1/statistics': this.$t('route.view_statistics'),
                        'GET_/v1/orders': this.$t('route.view_orders'),
                        'GET_/v1/orders/export': this.$t('route.export_orders'),
                        'GET_/v1/items/supplies': this.$t('route.view_supplies'),
                        'GET_/v1/auditlogs': this.$t('route.view_audit_log')
                    }
                },
                {
                    name: this.$t('route_section.products'),
                    permissions: {
                        'GET_/v1/items': this.$t('route.view_products'),
                        'GET_/v1/items/:item_id': this.$t('route.view_product_details'),
                        'POST_/v1/items': this.$t('route.create_products'),
                        'DELETE_/v1/items/:item_id': this.$t('route.delete_products'),
                        'PUT_/v1/items/:item_id': this.$t('route.update_products')
                    }
                },
                {
                    name: this.$t('route_section.categories'),
                    permissions: {
                        'POST_/v1/categories': this.$t('route.create_categories'),
                        'DELETE_/v1/categories/:category_id': this.$t('route.delete_categories'),
                        'PUT_/v1/categories/:category_id': this.$t('route.update_categories')
                    }
                },
                {
                    name: this.$t('route_section.workplaces'),
                    permissions: {
                        'POST_/v1/locations': this.$t('route.create_workplaces'),
                        // 'GET_/v1/locations/assoc': this.$t('route.'),
                        'DELETE_/v1/locations/:location_id': this.$t('route.delete_workplaces'),
                        'PUT_/v1/locations/:location_id': this.$t('route.update_workplaces')
                    }
                },
                {
                    name: this.$t('route_section.members'),
                    permissions: {
                        'GET_/v1/members': this.$t('route.view_members'),
                        'POST_/v1/members': this.$t('route.create_members'),
                        'DELETE_/v1/members/:member_id': this.$t('route.delete_members'),
                        'PUT_/v1/members/:member_id': this.$t('route.update_members'),
                        'PUT_/v1/members/:member_id/pin': this.$t('route.refresh_members_pin'),
                        'DELETE_/v1/members/sessions/:member_id/:session_id': this.$t('route.delete_member_session')
                    }
                },
                // {
                //     name: this.$t('route_section.customers'),
                //     permissions: {
                //         'GET_/v1/customers': this.$t('route.view_customers'),
                //         'PUT_/v1/customers/anonymize/:customer_id': this.$t('route.anonymize_customers')
                //     }
                // },
                {
                    name: this.$t('route_section.order_configuration'),
                    permissions: {
                        'GET_/v1/settings/:scope': this.$t('route.configure_orders'),
                        'PUT_/v1/settings/:setting_id': this.$t('route.update_orders_settings')
                        // 'POST_/v1/settings': ''
                        // 'GET_/v1/users',
                        // 'GET_/v1/users/export': this.$t(''),
                        // 'PUT_/v1/organizations/settings': ''
                    }
                },
                {
                    name: this.$t('route_section.organization_settings'),
                    permissions: {
                        'GET_/v1/organizations': this.$t('route.view_organization_information'),
                        'PUT_/v1/organizations/billing': this.$t('route.update_billing_information'),
                        'GET_/v1/invoices': this.$t('route.view_invoices'),
                        'GET_/v1/download_invoice': this.$t('route.download_invoice'),
                        'DELETE_/v1/users': this.$t('route.delete_organization'),
                        'POST_/v1/users/keep': this.$t('route.cancel_delete_organization'),
                        'POST_/v1/users/enroll': this.$t('route.enroll_users'),
                        'PUT_/v1/users/:user_id/permissions': this.$t('route.manage_user_permissions'),
                        'POST_/v1/users/withdraw/:user_id': this.$t('route.remove_users')
                    }
                }
                // {
                //     name: 'Helpdesk',
                //     permissions: {
                //         'GET_/v1/tickets': this.$t('route.view_tickets'),
                //         'POST_/v1/tickets': this.$t('route.create_tickets'),
                //         'PUT_/v1/tickets/:ticket_id': this.$t('route.close_tickets'),
                //         'POST_/v1/tickets/:ticket_id': this.$t('route.reply_tickets'),
                //         'GET_/v1/tickets/:ticket_id': this.$t('route.view_discussion')
                //     }
                // }
            ]
        }
    },
    computed: {
        valid_email: function () {
            return REGEX_EMAIL.test(this.form.email)
        },
        valid_form: function () {
            if (!this.valid_email) {
                return false
            }

            return true
        }
    },
    watch: {
        'form.email': function () {
            this.error_code = -1
        },
        user: function () {
            if (this.user && this.user.id) {
                this.form.email = this.user.email || this.user.pemail
                this.form.role = this.user._pivot_role
                this.form.permissions = this.user._pivot_permissions.api
            } else {
                this.form.email = ''
                this.form.role = 'admin'
                this.form.permissions = []
            }
        }
    },
    methods: {
        include_permissions: async function (permissions) {
            const self = this
            permissions.forEach(function (p) {
                if (!self.form.permissions.includes(p)) {
                    self.form.permissions.push(p)
                }
            })
        },
        exclude_permissions: async function (permissions) {
            const self = this
            permissions.forEach(function (p) {
                if (self.form.permissions.includes(p)) {
                    self.form.permissions.splice(self.form.permissions.indexOf(p), 1)
                }
            })
        },
        toggle_permission: async function (permission) {
            if (this.form.permissions.includes(permission)) {
                this.form.permissions.splice(this.form.permissions.indexOf(permission), 1)
            } else {
                this.form.permissions.push(permission)
            }
        },
        cancel: async function () {
            this.$bus.$emit('Modal/hide/manage-user-enrollment')
        },
        submit: async function () {
            this.loading = true

            const payload = {
                email: this.form.email,
                role: this.form.role,
                permissions: this.form.permissions
            }

            let response = null
            if (this.user && this.user.id) {
                response = await UserStore.dispatch('PERMISSIONS', {
                    id: this.user.id,
                    params: payload
                })
            } else {
                response = await UserStore.dispatch('ENROLL', payload)
            }

            /// CUSTOM NOTIFICATION
            if (response.status === 200) {
                if (response.data.status !== 0) {
                    this.error_code = response.data.status
                } else {
                    this.$bus.$emit('Modal/hide/manage-user-enrollment')
                    this.$bus.$emit('Organization/info')

                    if (this.user && this.user.id) {
                        this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The permissions for this user were updated successfully!', level: 'success' })
                    } else {
                        this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The user has been successfully enrolled! An invitation mail has been sent.', level: 'success' })
                    }
                }
            } else if (response.status === 409) {
                this.$bus.$emit('Notification/show', { title: 'User already exists!', msg: 'This e-mail is already added in the organization. Please use a different name or adjust the existing user.', level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
