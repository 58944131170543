<template>
    <div class="form-signin">
        <header role="banner" class="text-center">
            <router-link to="/" class="brand-logo">
                <img src="/img/identity/tgt_ro.png" alt="logo">
            </router-link>
        </header>

        <div class="p-4 shadow-sm bg-white rounded">
            <div>
                <h4 class="text-gray-700">{{$t('login')}}</h4>
                <div class="text-gray-600 fs-6">
                    <p>{{$t('send_link_via_email_to_login')}}<br>{{$t('if_no_account_create_one')}}</p>
                </div>
            </div>
            <form @submit="submit" novalidate>
                <div class="form-group mt-3 has-validation">
                    <label class="form-label" for="email">E-mail</label>
                    <BootstrapIcon v-if="valid_email" class="d-inline-block float-end" color="text-teal-500 fill-teal-500" name="check-circle" :size="16" />
                    <BootstrapIcon v-else class="d-inline-block float-end" color="text-gray-600 fill-gray-600" name="x-circle" :size="16" />
                    <input ref="email" :class="{'is-invalid': [4002].includes(error_code) || error_code !== -1}" v-model="email" type="email" class="form-control form-control-lg fs-6" id="email" :placeholder="$t('email_address')">
                    <div class="invalid-feedback">
                        <span v-if="error_code === 1">{{$t('disposable_emails_not_accepted')}}</span>
                        <span v-if="error_code === 4002">{{$t('email_format_not_valid')}}</span>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <div class="d-grid gap-2">
                        <button :disabled="!valid_form || loading" class="btn btn-lg btn-primary btn-block fs-6 bg-teal-500 border-teal-600" type="submit">{{$t('continue')}}</button>
                    </div>
                </div>
                <div class="text-gray-600 mt-3 text-center px-2">
                    <p class="fs-6">{{$t('by_clicking_you_agree')}} <router-link to="privacy" class="text-teal-500">{{$t('privacy_policy')}}</router-link> &amp; <router-link to="terms" class="text-teal-500">{{$t('terms_conditions')}}</router-link>.</p>
                </div>
            </form>
        </div>
        <div class="text-center pt-3">
            <router-link to="/" title="Back to homepage">{{$t('back_to_homepage')}}</router-link>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import Store from '@/common/store/user'
import { REGEX_EMAIL } from '@/common/constants'
import { nextTick } from 'vue'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            loading: false,
            email: '',
            error_code: -1
        }
    },
    computed: {
        valid_email: function () {
            return REGEX_EMAIL.test(this.email)
        },
        valid_form: function () {
            if (!this.valid_email) {
                return false
            }

            return true
        },
        censor_email: function () {
            if (this.email) {
                let [username, domain] = this.email.split('@')
                username = username[0]+username[1] + '*'.repeat(username.length - 3) + username.slice(-1)
                return `${username}@${domain}`
            }
            return this.email
        },
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    watch: {
        email: function () {
            this.error_code = -1
        }
    },
    created: async function () {
        if (this.is_authorized) {
            this.$router.replace('/console/account')
        }
    },
    mounted: async function () {
        const _self = this
        nextTick(function () {
            _self.$refs.email.focus()
        })
    },
    methods: {
        submit: async function ($event) {
            $event.preventDefault()
            this.loading = true

            const payload = {
                email: this.email
            }

            const response = await Store.dispatch('LOOKUP', payload)

            if (typeof response.status === 'number') {
                this.loading = false
                if (response.status === 0) {
                    this.$router.push({
                        name: 'account_login_step2',
                        query: {
                            q: this.censor_email
                        }
                    }).catch(() => {})
                } else {
                    this.error_code = response.status
                }
            }
        }
    }
}
</script>
