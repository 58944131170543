<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="gear" :size="20" />
                    <span class="align-middle">{{$t('console.order_settings')}}</span>
                </h1>
                <h2 class="h6">{{$t('console.order_settings_description')}}</h2>
            </div>
        </div>

        <div v-for="(setting, index) in settings" :key="index" class="row mb-4">
            <div class="col-12 col-lg-12 col-xl-10">
                <ConsoleSetting :_setting="setting" />
            </div>
        </div>
    </div>
</template>

<script>
import SettingStore from '@/common/store/setting.js'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import ConsoleSetting from '@/common/directive/ConsoleSetting.vue'

export default {
    components: {
        BootstrapIcon,
        ConsoleSetting
    },
    data: function () {
        return {
            loading: true,
            settings: []
        }
    },
    computed: {
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        await this.retrieve()
    },
    methods: {
        retrieve: async function () {
            const response = await SettingStore.dispatch('RETRIEVE', ['store', 'shop'])

            if (typeof response.error !== 'undefined') {
                switch (response.error_code) {
                case 400:
                    this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
                    break
                }
            } else {
                this.settings = response.data.settings
            }

            this.loading = false
        }
    }
}
</script>
