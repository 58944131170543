import { createRouter, createWebHistory } from 'vue-router'

import LayoutPublic from '@/components/LayoutPublic.vue'
import LayoutConsole from '@/components/LayoutConsole.vue'

import Homepage from '@/modules/public/homepage.vue'
import Contact from '@/modules/public/static/contact.vue'
import Pricing from '@/modules/public/static/pricing.vue'

import PrivacyPolicy from '@/modules/public/static/privacy_policy.vue'
import TermsOfService from '@/modules/public/static/terms_of_service.vue'
import HowItWorks from '@/modules/public/static/how_it_works.vue'
import LegalEntity from '@/modules/public/static/legal.vue'
import Static404 from '@/modules/public/static/404.vue'

// CAPTCHA Management
// import ConsoleCAPTCHAIndex from '@/modules/console/captcha/index.vue'

// Store Management
import ConsoleStoreWorkplaces from '@/modules/console/store/workplaces.vue'
import ConsoleStoreMembers from '@/modules/console/store/members.vue'
import ConsoleStoreProducts from '@/modules/console/store/products.vue'
import ConsoleStoreProduct from '@/modules/console/store/product.vue'
import ConsoleStoreCategories from '@/modules/console/store/categories.vue'
import ConsoleStoreSettings from '@/modules/console/store/settings.vue'
import ConsoleStoreActivity from '@/modules/console/store/activity.vue'
import ConsoleStoreCustomers from '@/modules/console/store/customers.vue'

// Business Management
import ConsoleCashRegister from '@/modules/console/store/cash_register.vue'
import ConsoleInternalTransactions from '@/modules/console/store/internal_transactions.vue'

import ConsoleStoreInsightsDashboard from '@/modules/console/store/in_dashboard.vue'
import ConsoleStoreOrders from '@/modules/console/store/orders.vue'
import ConsoleStoreReports from '@/modules/console/store/reports.vue'

import LoginStep1 from '@/modules/public/login_step1.vue'
import LoginStep2 from '@/modules/public/login_step2.vue'

import ConsoleAccountSettings from '@/modules/console/account/settings.vue'
import ConsoleAccountSubscriptions from '@/modules/console/account/subscriptions.vue'
import AccountOrderSuccess from '@/modules/console/account/order_success.vue'
import ConsoleAccountOrganization from '@/modules/console/account/organization.vue'
// import Account2FA from '@/modules/console/account/2fa.vue'

import ConsoleAccountAudit from '@/modules/console/account/audit.vue'

import ConsoleHelpDeskIndex from '@/modules/console/tickets/index.vue'
import ConsoleHelpDeskView from '@/modules/console/tickets/view.vue'

const routes = [
    {
        path: '/console',
        component: LayoutConsole,
        children: [
            {
                path: '/console/store/activity',
                name: 'console_store_activity',
                component: ConsoleStoreActivity
            },
            {
                path: '/console/store',
                name: 'console_store_in_dashboard',
                component: ConsoleStoreInsightsDashboard
            },
            {
                path: '/console/store/orders',
                name: 'console_store_orders',
                component: ConsoleStoreOrders
            },
            {
                path: '/console/store/customers',
                name: 'console_store_customers',
                component: ConsoleStoreCustomers
            },
            {
                path: '/console/store/categories',
                name: 'console_store_categories',
                component: ConsoleStoreCategories
            },
            {
                path: '/console/store/products',
                name: 'console_store_products',
                component: ConsoleStoreProducts
            },
            {
                path: '/console/store/product',
                name: 'console_store_product',
                component: ConsoleStoreProduct
            },
            {
                path: '/console/store/workplaces',
                name: 'console_store_workplaces',
                component: ConsoleStoreWorkplaces
            },
            {
                path: '/console/store/members',
                name: 'console_store_members',
                component: ConsoleStoreMembers
            },
            {
                path: '/console/store/reports',
                name: 'console_store_reports',
                component: ConsoleStoreReports
            },
            {
                path: '/console/business/cash_register',
                name: 'console_cash_register',
                component: ConsoleCashRegister
            },
            {
                path: '/console/business/transactions',
                name: 'console_internal_transcations',
                component: ConsoleInternalTransactions
            },
            {
                path: '/console/store/settings',
                name: 'console_store_settings',
                component: ConsoleStoreSettings
            },
            {
                path: '/console/audit',
                name: 'console_account_audit',
                component: ConsoleAccountAudit
            },
            {
                path: '/console/account',
                name: 'console_account_settings',
                component: ConsoleAccountSettings
            },
            {
                path: '/console/organization',
                name: 'console_account_organization',
                component: ConsoleAccountOrganization
            },
            {
                path: '/console/subscriptions',
                name: 'console_account_subscriptions',
                component: ConsoleAccountSubscriptions
            },
            {
                path: '/console/helpdesk',
                name: 'console_helpdesk_index',
                component: ConsoleHelpDeskIndex
            },
            {
                path: '/console/helpdesk/:ticket_id',
                name: 'console_helpdesk_view',
                component: ConsoleHelpDeskView
            }
            // {
            //     path: '/console/captcha/dashboard',
            //     name: 'console_captcha_dashboard',
            //     component: ConsoleCAPTCHAIndex
            // }
        ]
    },
    {
        path: '/',
        component: LayoutPublic,
        children: [
            {
                path: '/',
                name: 'Tapgiant',
                component: Homepage
            },
            {
                path: '/contact',
                name: 'contact',
                component: Contact
            },
            {
                path: '/pricing',
                name: 'pricing',
                component: Pricing
            },
            {
                path: '/privacy',
                name: 'privacy',
                component: PrivacyPolicy
            },
            {
                path: '/terms',
                name: 'terms',
                component: TermsOfService
            },
            {
                path: '/legal',
                name: 'legal',
                component: LegalEntity
            },
            {
                path: '/how_it_works',
                name: 'how_it_works',
                component: HowItWorks
            }
        ]
    },
    {
        path: '/order_success',
        name: 'account_order_success',
        component: AccountOrderSuccess
    },
    // {
    //     path: '/2fa',
    //     name: 'account_2fa',
    //     component: Account2FA
    // },
    {
        path: '/login',
        name: 'account_login_step1',
        component: LoginStep1
    },
    {
        path: '/activate',
        name: 'account_login_step2',
        component: LoginStep2
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404',
        component: Static404
    }
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

export default router
