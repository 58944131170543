<template>
    <Modal :close_button="true" :size="'modal-md'" mid="manage-store-category">
        <template v-slot:header>
            <span v-if="category && category.id">Edit category</span>
            <span v-else>Create category</span>
        </template>
        <template v-slot:body>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Name<span class="ms-1 text-red-500">*</span></label>
                    <input v-model="form.name" type="text" class="form-control form-control-lg" placeholder="e.g. Drinks" aria-label="Name">
                    <small class="d-block mt-1 mb-2">Write a short name of the category.</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small><span class="text-red-500">*</span> Fields with asterisk are mandatory.</small>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!valid_form && !loading" type="button" class="btn btn-primary bg-gradient px-4" @click="submit()">
                <span v-if="category && category.id">Update</span>
                <span v-else>Create</span>
            </button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryCategoryStore from '@/common/store/inventory_category.js'

export default {
    props: {
        category: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            loading: false,
            form: {
                name: ''
            }
        }
    },
    computed: {
        valid_form: function () {
            return this.form.name.length > 3 &&
                this.form.name.length < 255
        }
    },
    watch: {
        category: function () {
            if (this.category && this.category.id) {
                this.form.name = this.category.name
            } else {
                this.form.name = ''
            }
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/manage-store-category')
        },
        submit: async function () {
            this.loading = true

            const payload = {
                name: this.form.name
            }

            let response = null
            if (this.category && this.category.id) {
                response = await InventoryCategoryStore.dispatch('UPDATE', {
                    id: this.category.id,
                    params: payload
                })
            } else {
                response = await InventoryCategoryStore.dispatch('CREATE', payload)
            }

            if (response.status === 200) {
                this.$bus.$emit('Modal/hide/manage-store-category')
                this.form.name = ''
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
            } else if (response.status === 409) {
                this.$bus.$emit('Notification/show', { title: 'Category limit reached!', msg: 'You have reached your category quota limit.<br>You may upgrade your subscription, purchase another subscription or delete existing categories.', level: 'danger' })
            } else if (response.status === 422) {
                this.$bus.$emit('Notification/show', { title: 'Category already exists!', msg: 'A category with similar name already exists. Please use a different name or edit the existing category.', level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
