<template>
    <nav style="height: 50px;" class="navbar navbar-expand-sm navbar-light bg-white sticky-top border-bottom">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <BootstrapIcon class="mr-1" color="text-teal-500 fill-teal-500" name="grid-horizontal" :size="20" />
            </button>
            <div class="collapse navbar-collapse ms-2 me-3" id="navbarNavAltMarkup">
                <div class="navbar-nav me-auto">
                    <span @click="toggle_sidebar()" class="me-auto p-2 text-center pe-pointer">
                        <BootstrapIcon class="align-middle d-inline-block me-3" color="fill-gray-600 text-gray-600" :name="'window-sidebar'" :size="26" />
                    </span>
                    <!-- <router-link to="/console/webhooks" class="nav-link text-blue-800 px-3 user-select-none" active-class="text-decoration-underline text-teal-500 pe-none">
                        Webhooks
                    </router-link>
                    <router-link to="/console/emonitor" class="nav-link  text-blue-800 px-3 user-select-none" active-class="text-decoration-underline text-teal-500 pe-none">
                        Pixel for e-mail
                    </router-link>
                    <router-link to="/products" class="nav-link  text-blue-800 px-3 user-select-none" active-class="text-decoration-underline text-teal-500 pe-none">
                        Products
                    </router-link>
                    <router-link to="/templates" class="nav-link  text-blue-800 px-3 user-select-none" active-class="text-decoration-underline text-teal-500 pe-none">
                        Templates
                    </router-link> -->
                </div>
                <div v-if="email && is_authorized" class="dropdown">
                    <button type="button" class="btn btn-sm fs-6 btn-outline-brand dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                        Account
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li v-for="(item, index) in menu" :key="index">
                            <router-link :to="item.href" class="nav-link py-1" aria-current="page" href="#">
                                <BootstrapIcon class="d-inline-block me-3 align-self-center" :color="$route.name === item.name ? 'fill-teal-500' : 'fill-gray-700'" :name="item.icon" :size="20" />
                                <span class="text-gray-600">{{item.label}}</span>
                            </router-link>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                            <a @click="logout()" class="dropdown-item align-middle">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="fill-red-500 text-red-500" :name="'power'" :size="18" />
                                <span class="align-middle d-inline-block text-red-500">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Cookies from 'js-cookie'
import BootstrapIcon from '@/common/directive/BootstrapIcon'

import {
    COOKIE_SIDEBAR_OPEN,
    COOKIE_SECURE,
    DOMAIN
} from '@/common/constants'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            base_menu: [
                {
                    icon: 'person-circle',
                    href: '/console/account',
                    name: 'console_account_settings',
                    label: 'Settings'
                },
                {
                    icon: 'diagram-3',
                    href: '/console/organization',
                    name: 'console_account_organization',
                    label: 'Organization'
                },
                {
                    icon: 'speedometer2',
                    href: '/console/subscriptions',
                    name: 'console_account_subscriptions',
                    label: 'Subscriptions'
                },
                {
                    icon: 'chat-left-text',
                    href: '/console/helpdesk',
                    name: 'console_helpdesk_index',
                    label: 'Help Desk'
                },
                {
                    icon: 'safe',
                    href: '/console/audit',
                    name: 'console_account_audit',
                    label: 'Audit Log',
                    feature: 'audit_log'
                }
            ]
        }
    },
    computed: {
        email: function () {
            return this.$store.state.user.email
        },
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        features: function () {
            return {
                audit_log: this.$store.state.feature.audit_log || false
            }
        },
        menu: function () {
            const self = this
            return this.base_menu.filter(function (item) {
                return !item.feature || self.features[item.feature]
            })
        }
    },
    mounted: async function () {
        const sidebar_open = Cookies.get(COOKIE_SIDEBAR_OPEN)
        if (sidebar_open === '0') {
            document.getElementById('tg-console').classList.remove('sidebar-open')
        }
    },
    methods: {
        logout: async function () {
            await this.$store.dispatch('LOGOUT')
            this.$router.replace('/login')
        },
        toggle_sidebar: function () {
            document.getElementById('tg-console').classList.toggle('sidebar-open')
            const sidebar_open = document.getElementById('tg-console').classList.contains('sidebar-open')

            Cookies.set(COOKIE_SIDEBAR_OPEN, sidebar_open ? '1' : '0', {
                expires: 30,
                secure: COOKIE_SECURE,
                domain: DOMAIN,
                path: '/',
                sameSite: 'strict'
            })
        }
    }
}
</script>