<template>
    <nav id="console-sidebar">
        <router-link class="mx-auto sidebar-brand" to="/">
            <img class="cs-logo" src="/img/identity/tgt_ro.png" alt="logo" height="30">
            <img class="cs-logo-sm" src="/img/identity/tg_ro.png" alt="logo" height="30">
        </router-link>

        <div v-if="organization && organization.removals && organization.removals.length > 0" class="account-removal-notification">
            <span class="bg-coral-500 text-white px-3 py-2 d-block text-center">{{$t('console.scheduled_for_removal', { DATE: date_format(organization.removals[0].remove_at) })}}</span>
        </div>

        <div v-if="trials.length > 0" class="ms-4 mt-3">
            <div class="pulse gold d-inline-block me-3 ms-1"></div>
            <router-link to="/console/subscriptions" class="text-gray-600">{{$t('console.trial_subscription')}}</router-link>
        </div>

        <div class="sidebar-menu h-100 overflow-scroll">
            <div v-for="(section, index) in menu" :key="index" class="mb-4">
                <p class="text-gray-500 small mb-1">{{section.name}}</p>
                <nav v-if="section.items.length > 0" class="nav flex-column">
                    <div v-for="(item, index) in section.items" :key="index">
                        <router-link :to="item.href" class="nav-link py-1" aria-current="page" href="#">
                            <BootstrapIcon class="d-inline-block me-3 align-self-center" :color="$route.name === item.name ? 'fill-teal-500' : 'fill-gray-700'" :name="item.icon" :size="20" />
                            <span>{{item.label}}</span>
                        </router-link>
                    </div>
                </nav>
            </div>
        </div>
    </nav>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
        }
    },
    computed: {
        base_menu: function () {
            return [
                {
                    name: this.$t('menu.insights'),
                    items: [
                        {
                            icon: 'activity',
                            href: '/console/store/activity',
                            name: 'console_store_activity',
                            label: this.$t('menu.activity'),
                            feature: 'activity_timeline'
                        },
                        {
                            icon: 'graph-up',
                            href: '/console/store',
                            name: 'console_store_in_dashboard',
                            label: this.$t('menu.dashboard')
                        },
                        {
                            icon: 'card-checklist',
                            href: '/console/store/orders',
                            name: 'console_store_orders',
                            label: this.$t('menu.orders')
                        },
                        {
                            icon: 'journal-text',
                            href: '/console/store/reports',
                            name: 'console_store_reports',
                            label: this.$t('menu.reports'),
                            feature: 'balance_export'
                        }
                        // {
                        //     icon: 'person-lines-fill',
                        //     href: '/console/store/customers',
                        //     name: 'console_store_customers',
                        //     label: this.$t('menu.customers')
                        // }
                    ]
                },
                // {
                //     name: this.$t('menu.business'),
                //     items: [
                //         {
                //             icon: 'receipt',
                //             href: '/console/business/cash_register',
                //             name: 'console_cash_register',
                //             label: this.$t('menu.cash_register')
                //         },
                //         {
                //             icon: 'piggy-bank',
                //             href: '/console/business/transactions',
                //             name: 'console_internal_transcations',
                //             label: this.$t('menu.internal_transactions')
                //         }
                //     ]
                // },
                {
                    name: this.$t('menu.management'),
                    items: [
                        {
                            icon: 'cart2',
                            href: '/console/store/products',
                            name: 'console_store_products',
                            label: this.$t('menu.products')
                        },
                        {
                            icon: 'bookmarks',
                            href: '/console/store/categories',
                            name: 'console_store_categories',
                            label: this.$t('menu.categories')
                        },
                        {
                            icon: 'shop',
                            href: '/console/store/workplaces',
                            name: 'console_store_workplaces',
                            label: this.$t('menu.workplaces')
                        },
                        {
                            icon: 'people',
                            href: '/console/store/members',
                            name: 'console_store_members',
                            label: this.$t('menu.team')
                        },
                        {
                            icon: 'gear',
                            href: '/console/store/settings',
                            name: 'console_store_settings',
                            label: this.$t('menu.settings')
                        }
                    ]
                }
            ]
        },
        locale: function () {
            return this.$i18n.locale
        },
        organization: function () {
            return this.$store.state.organization
        },
        trials: function () {
            return this.$store.state.trials
        },
        features: function () {
            return {
                balance_export: this.$store.state.feature.balance_export || false,
                activity_timeline: this.$store.state.feature.activity_timeline || false
            }
        },
        menu: function () {
            const self = this
            return this.base_menu.map(s => { return { ...s } }).map(function (section) {
                section.items = section.items.filter(function (item) {
                    return !item.feature || self.features[item.feature]
                })
                return section
            })
        }
    },
    methods: {
        date_format: function (date) {
            return DateFormat(new Date(date), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS }).toUpperCase()
        }
    }
}
</script>
