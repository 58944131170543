<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="card-checklist" :size="20" />
                    <span class="align-middle">Orders</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1">You have <span class="fs-5">{{paginator.count}}</span> orders.</div>
                    <button v-if="paginator.count > 0 && (user.role === 'admin' || user.permissions.api.includes('GET_/v1/orders/export')) && has_customer_settings" @click="request_export_orders()" type="button" class="btn btn-sm btn-primary bg-gradient px-4">{{$t('console.export_orders')}}</button>
                </div>
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'orders'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <div v-if="paginator.count > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3 table-responsive">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-600">
                            <tr class="">
                                <th scope="col" class="border-0 pb-3">
                                    Order ID / Status
                                </th>
                                <th scope="col" class="border-0 pb-3">Activity</th>
                                <th scope="col" class="border-0 pb-3">Location</th>
                                <th scope="col" class="border-0 pb-3">Items</th>
                                <th scope="col" class="border-0 pb-3">Details</th>
                            </tr>
                            <tr>
                                <th>
                                    <input v-model="filter.order_id" type="text" class="form-control" placeholder="Filter by order ID">
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700 border-top-0">
                            <tr v-for="(order, index) in orders" :key="index" class="align-middle">
                                <td class="small pe-3">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <BootstrapIcon v-if="order.status === 'order_pending'" title="Pending" color="fill-coral-500 text-coral-500" class="me-3" name="circle-fill" :size="20" />
                                            <BootstrapIcon v-else-if="order.status === 'order_preparing'" title="Processing" color="fill-teal-500 text-green-500" class="me-3" name="circle-fill" :size="20" />
                                            <BootstrapIcon v-else-if="order.status === 'order_completed'" title="Completed" color="fill-cyan-500 text-cyan-500" class="me-3" name="circle-fill" :size="20" />
                                            <BootstrapIcon v-else-if="order.status === STATUS_DISCARDED" title="Discarded" color="fill-orange-300 text-orange-300" class="me-3" name="circle-fill" :size="20" />
                                            <BootstrapIcon v-else-if="order.status === STATUS_DELIVERED" title="Delivered" color="fill-brown-500 text-brown-500" class="me-3" name="circle-fill" :size="20" />
                                        </div>
                                        <div>
                                            <HighlightText :search="filter.order_id" :value="order.id" />
                                            <div>Created at {{$filters.PrettyDate(order.created_at)}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-nowrap pe-3">
                                    <div>
                                        <BootstrapIcon class="me-2" color="fill-gray-600 text-gray-600" name="cart-plus" :size="20" />
                                        <span>{{order.requisition.username}}</span>
                                    </div>
                                    <div>
                                        <BootstrapIcon v-if="[STATUS_COMPLETED, STATUS_DELIVERED].includes(order.status)" color="fill-cyan-500 text-cyan-500" class="me-2" name="cart-check" :size="20" />
                                        <BootstrapIcon v-if="order.status === 'order_preparing'" color="fill-teal-500 text-teal-500" class="me-2" name="cart" :size="20" />
                                        <BootstrapIcon v-if="[STATUS_DISCARDED].includes(order.status)" color="fill-orange-300 text-orange-300" class="me-2" name="cart-x" :size="20" />
                                        <span v-if="order.provide_member_id">{{order.delivery.username}}</span>
                                    </div>
                                    <div>
                                        <BootstrapIcon v-if="order.status === STATUS_DELIVERED" color="fill-brown-500 text-brown-500" class="me-2" name="box-seam" :size="20" />
                                        <span v-if="order.dispatch_member_id">{{order.dispatch.username}}</span>
                                    </div>
                                </td>
                                <td class="text-nowrap">
                                    <span>{{order.location.name}}</span>
                                </td>
                                <td class="text-nowrap">
                                    <div class="rounded overflow-hidden border">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <thead>
                                                <tr>
                                                    <td class="text-gray-600 text-start" style="width: 99%;">Item</td>
                                                    <td class="text-gray-600">Requested</td>
                                                    <td class="text-gray-600">Prepared</td>
                                                </tr>
                                            </thead>
                                            <tbody class="border-top-0">
                                                <tr v-for="(item, index) in order.items" :key="index">
                                                    <td>{{item.name}}</td>
                                                    <td class="text-end">{{item._pivot_quantity}}</td>
                                                    <td class="text-end">{{item._pivot_new_quantity}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td>
                                    <!-- Customer Details -->
                                    <div v-if="order.customer_id" class="rounded overflow-hidden border mb-2">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <tbody>
                                                <tr v-if="order.customer.name && order.customer.name.length > 0">
                                                    <td class="text-nowrap text-gray-600">Name</td>
                                                    <td style="width: 99%;">{{order.customer.name}}</td>
                                                </tr>
                                                <tr v-if="order.customer.phone && order.customer.phone.length > 0">
                                                    <td class="text-nowrap text-gray-600">Phone</td>
                                                    <td style="width: 99%;">{{order.customer.phone}}</td>
                                                </tr>
                                                <tr v-if="order.customer.email && order.customer.email.length > 0">
                                                    <td class="text-nowrap text-gray-600">Email</td>
                                                    <td style="width: 99%;">{{order.customer.email}}</td>
                                                </tr>
                                                <tr v-if="order.customer.address && order.customer.address.length > 0">
                                                    <td class="text-nowrap text-gray-600">Address</td>
                                                    <td style="width: 99%;">{{order.customer.address}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-if="order.data && Object.keys(order.data).length > 0" class="rounded overflow-hidden border mb-2">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <tbody>
                                                <tr v-for="(value, key) in order.data" :key="key">
                                                    <td class="text-nowrap text-gray-600">{{$t('console.'+key)}}</td>
                                                    <td style="width: 99%;">{{value}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                    <div v-if="order.status === STATUS_DISCARDED" class="rounded overflow-hidden border">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="text-nowrap text-gray-600">Discard reason</td>
                                                    <td style="width: 99%;">{{order.reason}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="No orders created so far. Order will show on this page immediately after creation." />
            </div>
        </div>
        <Paginator v-if="paginator.count>0" :tag="'orders'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalExportOrders />
    </div>
</template>

<script>
import Paginator from '@/common/directive/Paginator.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import HighlightText from '@/common/directive/HighlightText.vue'
import ModalExportOrders from '@/common/modal/console/modal-export_orders.vue'

export default {
    components: {
        Paginator,
        BootstrapIcon,
        EmptyResponse,
        HighlightText,
        ModalExportOrders
    },
    data: function () {
        return {
            STATUS_DISCARDED: 'order_discarded',
            STATUS_DELIVERED: 'order_delivered',
            STATUS_COMPLETED: 'order_completed',
            paginate_timeout: 0,
            filter: {
                order_id: ''
            }
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        },
        settings: function () {
            return this.$store.state.settings
        },
        orders: () => InventoryOrderStore.state.orders,
        paginator: () => InventoryOrderStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        has_customer_settings: function () {
            return (this.settings.customer_details && this.settings.customer_details.display) || (this.settings.customer_phone && this.settings.customer_phone.display)
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/orders/set_page')
        this.$bus.$on('Paginator/orders/set_page', (value) => {
            this.set_page(value)
        })

        await this.paginate()
    },
    methods: {
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.order_id && self.filter.order_id.length >= 1) {
                    query.order_id = self.filter.order_id
                }
                await InventoryOrderStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 50)
        },
        set_page: async function (page) {
            InventoryOrderStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        request_export_orders: function () {
            this.$bus.$emit('Modal/show/export-orders')
        }
    }
}
</script>
