<template>
    <div>
        <canvas :id="'bar-chart-'+id" height="100" />
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import uuidv4 from '@/common/mixin/uuidv4.js'
import 'chartjs-adapter-date-fns'

export default {
    mixins: [uuidv4],
    props: {
        values: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            id: this.uuidv4(),
            line: ''
        }
    },
    watch: {
        'values.data': function (old_val, new_val) {
            this.load_chart()
        }
    },
    mounted: function () {
        this.load_chart()
    },
    methods: {
        load_chart: function () {
            const ctx = document.getElementById('bar-chart-'+this.id).getContext('2d')
            const cfg = {
                type: 'bar',
                data: this.values,
                options: {
                    plugins: {
                        legend: {
                            display: true
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day'
                            },
                            grid: {
                                display: false,
                                borderWidth: 0
                            }
                        },
                        y: {
                            grid: {
                                display: true,
                                borderWidth: 0
                            }
                        }
                    }
                }
            }
            this.line = new Chart(ctx, cfg)
        }
    }
}
</script>
