<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-delete-category">
        <template v-slot:header>Delete Category?</template>
        <template v-slot:body>
            <p v-if="category">Category: <strong>{{category.name}}</strong></p>
            <p>Notice: the category will be permanently deleted and you will not be able to recover it. You will however be able to create a new category with the same name.</p>
            <p>All the items assigned to this category will be unassigned.</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">Delete</button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryCategoryStore from '@/common/store/inventory_category.js'

export default {
    props: {
        category: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            agree: false
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-delete-category')
        },
        submit: async function () {
            const response = await InventoryCategoryStore.dispatch('DELETE_CATEGORY', { category_id: this.category.id })
            if (response.status === 0) {
                this.agree = false
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The category has been deleted', level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-delete-category')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        }
    }
}
</script>
