<template>
    <Modal :close_button="true" :size="'modal-md'" mid="manage-inventory-location">
        <template v-slot:header>
            <span v-if="location && location.id">Edit workplace</span>
            <span v-else>Create workplace</span>
        </template>
        <template v-slot:body>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Name<span class="ms-1 text-red-500">*</span></label>
                    <input v-model="form.name" type="text" class="form-control form-control-lg" placeholder="e.g. My Amazing Shop" aria-label="Name">
                    <small class="d-block mt-1 mb-2">Write a short name of the workplace.<br>Name must have between 2 and 30 characters.</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Address</label>
                    <input v-model="form.address" type="text" class="form-control" placeholder="5th Avenue Street" aria-label="Address">
                    <small class="d-block mt-1 mb-2">The address of the workplace. If available, it will be included in customer receipts.</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Phone</label>
                    <input v-model="form.phone" type="text" class="form-control" placeholder="1234567890" aria-label="Phone">
                    <small class="d-block mt-1 mb-2">The phone number of this location. If available, it will be included in customer receipts.</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small><span class="text-red-500">*</span> Fields with asterisk are mandatory.</small>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!valid_form && !loading" type="button" class="btn btn-primary bg-gradient px-4" @click="submit()">
                <span v-if="location && location.id">Update</span>
                <span v-else>Create</span>
            </button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryLocationStore from '@/common/store/inventory_location.js'

export default {
    props: {
        location: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            loading: false,
            form: {
                name: '',
                address: '',
                phone: ''
            }
        }
    },
    computed: {
        valid_form: function () {
            return this.form.name.length >= 2 &&
                this.form.name.length <= 30
        }
    },
    watch: {
        location: function () {
            if (this.location && this.location.id) {
                this.form.name = this.location.name
                this.form.address = this.location.address
                this.form.phone = this.location.phone
            } else {
                this.form.name = ''
                this.form.address = ''
                this.form.phone = ''
            }
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/manage-inventory-location')
        },
        submit: async function () {
            this.loading = true

            const payload = {
                name: this.form.name,
                address: this.form.address,
                phone: this.form.phone
            }

            let response = null
            if (this.location && this.location.id) {
                response = await InventoryLocationStore.dispatch('UPDATE', {
                    id: this.location.id,
                    params: payload
                })
            } else {
                response = await InventoryLocationStore.dispatch('CREATE', payload)
            }

            if (response.status === 200) {
                this.$bus.$emit('Modal/hide/manage-inventory-location')
                this.form.name = ''
                this.form.address = ''
                this.form.phone = ''
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
            } else if (response.status === 409) {
                if (response.data.status === 1) {
                    this.$bus.$emit('Notification/show', { title: 'Workplace limit reached!', msg: 'You have reached your workplace quota limit.<br>You may upgrade your subscription, purchase another subscription or delete existing workplaces.', level: 'danger' })
                } else {
                    this.$bus.$emit('Notification/show', { title: 'Workplace already exists!', msg: 'A workplace with similar name already exists. Please use a different name or edit the existing workplace.', level: 'danger' })
                }
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
