<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="cart-check" :size="20" />
                    <span v-if="item_id" class="align-middle">{{$t('products.edit_product')}}</span>
                    <span v-else class="align-middle">{{$t('products.create_product')}}</span>
                </h1>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12 col-xl-10 col-xxl-8">
                <div class="row mb-4">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.name')}} <span class="ms-1 text-red-500">*</span></label>
                            <input v-model="form.name" type="text" class="form-control form-control-lg" placeholder="e.g. Drink 250ml" aria-label="Name">
                            <small class="d-block mt-1 mb-2">{{$t('products.name_description')}}</small>
                        </div>
                    </div>
                </div>

                <!-- PRODUCT STATE -->
                <div class="row mb-4">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.state')}}</label>
                            <div class="form-check">
                                <input v-model.number="form.state" :value="PRODUCT_STATE_ACTIVE_INAPP" class="form-check-input" type="radio" name="item_state" id="item-state-active-in-app">
                                <label class="form-check-label" for="item-state-active-in-app">
                                    {{$t('products.active_in_app')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model.number="form.state" :value="PRODUCT_STATE_ACTIVE_INSHOP" class="form-check-input" type="radio" name="item_state" id="item-state-active-in-shop">
                                <label class="form-check-label" for="item-state-active-in-shop">
                                    {{$t('products.active_in_shop')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model.number="form.state" :value="PRODUCT_STATE_ACTIVE_FULL" class="form-check-input" type="radio" name="item_state" id="item-state-active-in-app-and-shop">
                                <label class="form-check-label" for="item-state-active-in-app-and-shop">
                                    {{$t('products.active_in_app_and_shop')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model.number="form.state" :value="PRODUCT_STATE_INACTIVE" class="form-check-input" type="radio" name="item_state" id="item-state-inactive">
                                <label class="form-check-label" for="item-state-inactive">
                                    {{$t('products.inactive')}}
                                </label>
                                <small class="d-block mt-1 mb-2">{{$t('products.state_description')}}</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-6">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.sku')}}</label>
                            <input v-model="form.sku" type="text" class="form-control" placeholder="AA-BBB-CC-DD-EE" aria-label="SKU">
                            <small class="d-block mt-1 mb-2">{{$t('products.sku_description')}}</small>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.barcode')}}</label>
                            <input v-model="form.barcode" type="text" class="form-control" placeholder="1234567891" aria-label="Barcode">
                            <small class="d-block mt-1 mb-2">{{$t('products.barcode_description')}}</small>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.unit')}}</label>
                            <div class="dropdown">
                                <button type="button" class="btn btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>{{$t('units.unit_'+form.unit)}}</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-start">
                                    <li @click="form.unit = value" v-for="(value, key) in UNITS" :key="key" class="p-2 pe-pointer">
                                        {{$t('units.unit_'+value)}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.category')}}</label>
                            <div class="dropdown">
                                <button type="button" class="btn btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span v-if="form.category && form.category.id">{{form.category.name}}</span>
                                    <span v-else>{{$t('products.uncategorized')}}</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-start">
                                    <li @click="form.category = {}" class="p-2 pe-pointer">{{$t('products.uncategorized')}}</li>
                                    <li class="separator"></li>
                                    <li @click="form.category = category" v-for="(category, index) in categories" :key="index" class="p-2 pe-pointer">
                                        {{category.name}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PRODUCT IMAGES -->
                <div class="row mb-3">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="fw-bold">{{$t('console.product_images')}}</label>
                            <p>{{$t('console.product_images_description', { COUNT: PRODUCT_IMAGE_LIMIT, SIZE: IMAGE_MAX_FILESIZE/1024/1000 })}}</p>
                            <div v-if="form.images.length > 0">
                                <div v-for="(image, index) in form.images" :key="index" class="border rounded overflow-hidden d-inline-block me-2 position-relative" style="max-width: 100px; min-width: 100px;">
                                    <div class="cover-image" :style="'background-image: url(' + SPACES_PATH + image.url + ')'"></div>
                                    <div @click="delete_image(item_id, image.id)" class="position-absolute top-0 start-0 w-100 h-100 pe-pointer">
                                        <BootstrapIcon class="d-inline-block m-2 position-absolute bottom-0 end-0 bg-white p-1 rounded shadow-sm" color="text-gray-800" name="trash" :size="30" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.images.length < PRODUCT_IMAGE_LIMIT" class="mt-3">
                                <FileUpload :metadata="{ item_id: item_id }" :tag="'product_image'" :text="$t('controls.upload')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row pt-2 mb-3">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.additional_product_fields')}}</label>
                            <small class="d-block mb-2">{{$t('products.additional_product_fields_description')}}</small>
                            <div v-for="(field, index) in form.data.fields" :key="index" class="d-flex mb-3">
                                <div class="d-flex align-items-center justify-content-center bg-gray-500 rounded" style="width: 30px;">
                                    <strong class="text-white">{{index+1}}</strong>
                                </div>
                                <div class="border-start flex-fill ps-2">
                                    <div class="row">
                                        <!-- FIELD NAME -->
                                        <div class="col">
                                            <input v-model="field.name" type="text" class="form-control" placeholder="Field Name" aria-label="Field Name">
                                        </div>

                                        <!-- FIELD INPUT TYPE DROPDOWN -->
                                        <div class="col">
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span v-if="field.type === 'text'" v-html="$t('products.max_60_characters')"></span>
                                                    <span v-else-if="field.type === 'textarea'" v-html="$t('products.max_500_characters')"></span>
                                                    <span v-else-if="field.type === 'radio'" v-html="$t('products.single_select')"></span>
                                                    <span v-else-if="field.type === 'checkbox'" v-html="$t('products.multiple_select')"></span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-start">
                                                    <li @click="set_field_type(field, 'text')" class="p-2 pe-pointer" v-html="$t('products.max_60_characters')"></li>
                                                    <li @click="set_field_type(field, 'textarea')" class="p-2 pe-pointer" v-html="$t('products.max_500_characters')"></li>
                                                    <li @click="set_field_type(field, 'radio')" class="p-2 pe-pointer" v-html="$t('products.single_select')"></li>
                                                    <li @click="set_field_type(field, 'checkbox')" class="p-2 pe-pointer" v-html="$t('products.multiple_select')"></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- FIELD REQUIRED STATUS -->
                                        <div class="col d-flex justify-content-between align-items-center">
                                            <div class="form-check form-switch px-5 flex-fill">
                                                <input v-model.number="field.required" class="form-check-input" type="checkbox" :id="'field-required-'+index">
                                                <label class="form-check-label pe-pointer noselect" :for="'field-required-'+index">{{field.required ? $t('products.mandatory') : $t('products.optional')}}</label>
                                            </div>
                                            <button @click="form.data.fields.splice(index, 1)" class="btn btn-outline-danger" type="button">{{$t('controls.remove')}}</button>
                                        </div>
                                    </div>
                                    <!-- Options configuration for field type RADIO/CHECKBOX -->
                                    <div v-if="['radio', 'checkbox'].includes(field.type)" class="row">
                                        <div class="mt-2 mb-2">
                                            <span class="fw-bolder me-2">{{$t('products.options')}}</span>
                                            <span v-if="field.options.length === 0">- {{$t('products.no_options_configured')}} -</span>
                                            <span v-else>
                                                <span @click="field.options.splice(optionIndex, 1)" v-for="(option, optionIndex) in field.options" :key="optionIndex" class="py-1 px-2 me-3 bg-gray-200 text-dark">
                                                    <span class="d-inline-block align-middle">{{option}}</span>
                                                    <BootstrapIcon class="d-inline-block align-middle ms-2" color="text-gray-800" name="x" :size="20" />
                                                </span>
                                            </span>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                <input v-model="field.option_name" class="form-control" type="text" name="field_option_name" :placeholder="$t('products.option_name')">
                                            </div>
                                            <div class="col">
                                                <button :disabled="field.option_name.length === 0 || field.options.includes(field.option_name)" @click="add_field_option(field)" type="button" class="btn btn-secondary bg-gradient">{{$t('products.add_option')}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="mb-3">
                                <button @click="add_field()" type="button" class="btn btn-secondary bg-gradient">{{$t('products.add_field')}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col pt-3">
                        <h5>{{$t('products.shop_only_properties')}}</h5>
                    </div>
                </div>

                <!-- PRICE & CART LIMIT -->
                <div class="row mb-3">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.price')}}</label>
                            <input v-model.number="form.price" type="text" class="form-control" placeholder="19.99" aria-label="SKU">
                            <small class="d-block mt-1 mb-2">{{$t('products.price_description')}}</small>
                        </div>
                    </div>
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.order_max_quantity')}}</label>
                            <input v-model.number="form.cart_limit" type="text" class="form-control" placeholder="10" aria-label="SKU">
                            <small class="d-block mt-1 mb-2">{{$t('products.order_max_quantity_description')}}</small>
                        </div>
                    </div>
                </div>

                <!-- DESCRIPTION & NUTRITIONAL VALUES -->
                <div class="row mb-3">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.field_description')}}</label>
                            <span :class="{'text-red-500': !is_valid_description}" class="small float-end mt-1">{{form.description ? form.description.length : 0}}/2048</span>
                            <textarea v-model="form.description" rows="10" class="form-control" aria-label="description"></textarea>
                            <small class="d-block mt-1 mb-2">{{$t('products.field_description_extra')}}</small>
                        </div>
                    </div>
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3">
                            <label class="form-label fw-bold">{{$t('products.nutritional_values')}}</label>
                            <span :class="{'text-red-500': !is_valid_nutritional}" class="small float-end mt-1">{{form.nutritional ? form.nutritional.length : 0}}/2048</span>
                            <textarea v-model="form.nutritional" rows="10" class="form-control" aria-label="description"></textarea>
                            <small class="d-block mt-1 mb-2">{{$t('products.nutritional_values_description')}}</small>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col">
                        <small><span class="text-red-500">*</span> {{$t('console.fields_are_mandatory')}}</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="rounded shadow-sm bg-white px-3 py-3 d-flex gap-4 justify-content-start">
                            <button :disabled="!valid_form || loading || !changed" type="button" class="btn btn-primary bg-gradient px-4" @click="submit()">
                                <span v-if="item_id">{{$t('controls.update')}}</span>
                                <span v-else>{{$t('products.create_product')}}</span>
                            </button>
                            <button v-if="changed" type="button" class="btn px-4" @click="$router.go(-1)">{{$t('controls.cancel')}}</button>
                            <button v-else type="button" class="btn px-4" @click="$router.go(-1)">{{$t('controls.previous_page')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    PRODUCT_STATE_ACTIVE_INAPP,
    PRODUCT_STATE_INACTIVE,
    PRODUCT_STATE_ACTIVE_INSHOP,
    PRODUCT_STATE_ACTIVE_FULL,
    PRODUCT_STATE_ARCHIVED,
    UNITS,
    REGEX_UUID,
    SPACES_PATH,
    IMAGE_MAX_FILESIZE,
    PRODUCT_IMAGE_LIMIT
} from '@/common/constants.js'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryItemStore from '@/common/store/inventory_item.js'
import InventoryCategoryStore from '@/common/store/inventory_category.js'
import FileUpload from '@/common/directive/FileUpload.vue'

export default {
    components: {
        FileUpload,
        BootstrapIcon
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            IMAGE_MAX_FILESIZE,
            PRODUCT_IMAGE_LIMIT,
            SPACES_PATH,
            PRODUCT_STATE_ACTIVE_INAPP,
            PRODUCT_STATE_INACTIVE,
            PRODUCT_STATE_ACTIVE_INSHOP,
            PRODUCT_STATE_ACTIVE_FULL,
            PRODUCT_STATE_ARCHIVED,
            UNITS,
            paginate_timeout: 0,
            loading: false,
            changed: false,
            item_id: null,
            form: {
                images: [],
                name: '',
                description: '',
                sku: '',
                barcode: '',
                unit: 0,
                category: {},
                state: 0,
                data: {
                    fields: []
                },
                price: 0,
                nutritional: '',
                cart_limit: -1
            }
        }
    },
    computed: {
        categories: () => InventoryCategoryStore.state.categories,
        paginator: () => InventoryCategoryStore.state.paginator,
        valid_form: function () {
            return this.form.name.length > 3 &&
                this.form.name.length < 255 &&
                this.is_valid_description &&
                this.is_valid_nutritional
        },
        is_valid_description: function () {
            return !this.form.description || this.form.description.length <= 2048
        },
        is_valid_nutritional: function () {
            return !this.form.nutritional || this.form.nutritional.length <= 2048
        }
    },
    watch: {
        form: {
            handler () {
                this.changed = true
            },
            deep: true
        }
    },
    created: async function () {
        const self = this
        await this.paginate()

        // Seems to be an EDIT action so fetch the properties of the item
        if (this.$route.query.product_id && REGEX_UUID.test(this.$route.query.product_id)) {
            await this.get_product()
        }

        this.$bus.$off('FileUpload/completed/product_image')
        this.$bus.$on('FileUpload/completed/product_image', (value) => {
            self.form.images.push(value.data.image)
        })
    },
    mounted: async function () {
        setTimeout(function () {
            window.scroll({ top: 0 })
        })
    },
    methods: {
        add_field: function () {
            this.form.data.fields.push({
                name: '',
                type: 'text',
                required: false,
                option_name: ''
            })
        },
        add_field_option: function (field) {
            field.options.push(field.option_name)
            field.option_name = ''
        },
        set_field_type: function (field, type) {
            field.type = type
            switch (type) {
            case 'text':
            case 'textarea':
                delete field.options
                break
            case 'radio':
            case 'checkbox':
                field.options = []
            }
        },
        get_product: async function () {
            const self = this
            const res = await InventoryItemStore.dispatch('GET', this.$route.query.product_id)

            if (res.status === 200) {
                this.item_id = res.data.item.id
                this.form.images = res.data.item.images
                this.form.unit = res.data.item.unit
                this.form.sku = res.data.item.sku
                this.form.barcode = res.data.item.barcode
                this.form.name = res.data.item.name
                this.form.state = res.data.item.state
                this.form.price = res.data.item.price
                this.form.description = res.data.item.description
                this.form.nutritional = res.data.item.nutritional
                this.form.cart_limit = res.data.item.cart_limit
                if (res.data.item.data && typeof res.data.item.data === 'object' && Object.keys(res.data.item.data.fields).length > 0) {
                    this.form.data = JSON.parse(JSON.stringify(res.data.item.data))
                    this.form.data.fields.map(function (field) {
                        field.option_name = ''
                        return field
                    })
                } else {
                    this.form.data = { fields: [] }
                }
                if (res.data.item.category_id && res.data.item.category.id) {
                    this.form.category = res.data.item.category
                }
                setTimeout(function () {
                    self.changed = false
                })
            } else if (res.status === 404) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.404_not_found'), msg: this.$t('notifications.entity_does_not_exist'), level: 'warning' })
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            // self.$bus.$emit('ListingLoader/show')
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                await InventoryCategoryStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
                // self.$bus.$emit('ListingLoader/hide')
            }, 50)
        },
        submit: async function () {
            this.loading = true

            const payload = {
                unit: this.form.unit,
                name: this.form.name,
                state: this.form.state,
                data: this.form.data,
                description: this.form.description,
                nutritional: this.form.nutritional,
                price: this.form.price,
                cart_limit: this.form.cart_limit
            }

            if (this.form.sku) {
                payload.sku = this.form.sku
            }
            if (this.form.barcode) {
                payload.barcode = this.form.barcode
            }

            if (this.form.category && typeof this.form.category.id) {
                payload.category_id = this.form.category.id
            }

            let res = null
            if (this.item_id) {
                res = await InventoryItemStore.dispatch('UPDATE', {
                    id: this.item_id,
                    params: payload
                })
            } else {
                res = await InventoryItemStore.dispatch('CREATE', payload)
            }

            if (res.status === 200) {
                this.changed = false

                // New product created - Update item_id in scope
                if (!this.item_id && res.data.id) {
                    this.item_id = res.data.id
                }

                this.$bus.$emit('Notification/show', { title: this.$t('notifications.operation_success'), msg: this.$t('notifications.operation_success_description'), level: 'success' })
            } else if (res.status === 409) {
                this.$bus.$emit('Notification/show', { title: 'Product limit reached!', msg: 'You have reached your product quota limit.<br>You may upgrade your subscription, purchase another subscription or delete existing products.', level: 'danger' })
            } else if (res.status === 422) {
                if (res.data.status === 1) {
                    this.$bus.$emit('Notification/show', { title: 'Product already exists!', msg: 'A product with similar name already exists. Please use a different name or edit the existing product.', level: 'danger' })
                } else {
                    this.$bus.$emit('Notification/show', { title: 'Invalid Product Configuration!', msg: 'Please review the fields and complete all required information.', level: 'danger' })
                }
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        },
        delete_image: async function (item_id, image_id) {
            if (this.loading) {
                return
            }

            this.loading = true
            const res = await InventoryItemStore.dispatch('DELETE_IMAGE', {
                item_id,
                image_id
            })
            if (res.status === 200) {
                const index = this.form.images.findIndex((obj) => obj.id === image_id)
                this.form.images.splice(index, 1)
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.operation_success'), msg: this.$t('notifications.operation_success_description'), level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            this.loading = false
        }
    }
}
</script>
