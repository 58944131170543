<template>
    <div class="container">
        <div class="row mb-5">
            <div class="col">
                <h1 class="text-center h4">Order Successful!</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            loaded: false
        }
    },
    created: async function () {
        await this.order_success()
    },
    methods: {
        order_success: async function () {
            await this.$store.dispatch('ORDER_SUCCESS', { session_id: this.$route.query.session_id })
            this.loaded = true
        }
    }
}
</script>
