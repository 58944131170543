import {
    USERS_PATH,
    USERS_PUBLIC_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'

const store = {
    state: {
        get_info_ts: 0
    },
    mutations: {
        SET_GET_INFO_TS (state, value) {
            state.get_info_ts = value
        }
    },
    actions: {
        async WITHDRAW (context, data) {
            let result = {}
            try {
                result = await axios.post(`${USERS_PATH}/withdraw/${data.id}`, data)
            } catch (error) {
                return error.response
            }
            return result
        },
        async ENROLL (context, data) {
            let result = {}
            try {
                result = await axios.post(`${USERS_PATH}/enroll`, data)
            } catch (error) {
                return error.response
            }
            return result
        },
        async PERMISSIONS (context, data) {
            let result = {}
            try {
                result = await axios.put(`${USERS_PATH}/${data.id}/permissions`, data.params)
            } catch (error) {
                return error.response
            }
            return result
        },
        async LOOKUP (context, data) {
            let result = {}
            try {
                result = await axios.post(`${USERS_PUBLIC_PATH}/lookup`, data)
            } catch (error) {
                return error.response.data
            }
            return result.data
        },
        async QRCODE (context, data) {
            let result = {}
            try {
                result = await axios.get(`${USERS_PATH}/qrcode`)
            } catch (error) {
                return error.response.data
            }
            return result.data
        },
        async DELETE_ACCOUNT (context) {
            let result = {}
            try {
                result = await axios.delete(USERS_PATH)
            } catch (error) {
                return error.response
            }
            return result
        },
        async KEEP_ACCOUNT (context) {
            let result = {}
            try {
                result = await axios.post(`${USERS_PATH}/keep`)
            } catch (error) {
                return error.response
            }
            return result
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
