<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="graph-up" :size="20" />
                    <span class="align-middle">Insights Dashboard</span>
                </h1>
            </div>
        </div>

        <div v-if="statistics_loaded" class="row mb-4">
            <div class="col">
                <div class="d-flex flex-wrap gap-3 justify-content-between align-items-center align-items-stretch">
                    <div v-if="statistics.total_orders >= 0" class="d-flex align-items-center bg-white border border-gray-300 bg-gradient flex-fill rounded shadow-sm px-3 py-2">
                        <div>
                            <p class="small m-0 align-middle">Lifetime orders <Tooltip class="float-end ms-3" :value="'Number represents the total of all the orders placed.'" icon="info-circle" /></p>
                            <h4 class="m-0">{{$filters.NumberFormatter(statistics.total_orders)}}</h4>
                        </div>
                    </div>
                    <div v-if="statistics.total_items >= 0" class="d-flex align-items-center bg-white border border-gray-300 bg-gradient flex-fill rounded shadow-sm px-3 py-2">
                        <div>
                            <p class="small m-0">Lifetime supplies <Tooltip class="float-end ms-3" :value="'Number represents the total of all unique products placed in lifetime orders.'" icon="info-circle" /></p>
                            <h4 class="m-0">{{$filters.NumberFormatter(statistics.total_items)}}</h4>
                        </div>
                    </div>
                    <div v-if="statistics && statistics.top_items && statistics.top_items.length > 0" class="d-flex align-items-center bg-white border border-gray-300 bg-gradient flex-fill rounded shadow-sm px-3 py-2">
                        <div>
                            <p class="small m-0">Most wanted product</p>
                            <h4 class="m-0">{{$filters.NumberFormatter(statistics.top_items[0].count)}}</h4>
                            <p class="m-0">{{statistics.top_items[0].item.name}}</p>
                        </div>
                    </div>
                    <div v-if="statistics && statistics.top_requisition && statistics.top_requisition.length > 0" class="d-flex align-items-center bg-white border border-gray-300 bg-gradient flex-fill rounded shadow-sm px-3 py-2">
                        <div>
                            <p class="small m-0">Most orders placed</p>
                            <h4 class="m-0">{{$filters.NumberFormatter(statistics.top_requisition[0].count)}}</h4>
                            <p class="m-0">{{statistics.top_requisition[0].requisition.username}}</p>
                        </div>
                    </div>
                    <div v-if="statistics && statistics.top_delivery && statistics.top_delivery.length > 0" class="d-flex align-items-center bg-white border border-gray-300 bg-gradient flex-fill rounded shadow-sm px-3 py-2">
                        <div>
                            <p class="small m-0">Most orders delivered</p>
                            <h4 class="m-0">{{$filters.NumberFormatter(statistics.top_delivery[0].count)}}</h4>
                            <p class="m-0">{{statistics.top_delivery[0].delivery.username}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col">
                <div class="px-3 py-3 bg-white shadow-sm rounded">
                    <p class="h5 m-0">Interval selection</p>
                    <div class="mb-2">
                        <span class="small">Quick selection:</span>
                        <span @click="quick_selection(7)" class="text-blue-500 pe-pointer small mx-2">Last 7 days</span>
                        <span @click="quick_selection(30)" class="text-blue-500 pe-pointer small mx-2">Last 30 days</span>
                        <span @click="quick_selection(60)" class="text-blue-500 pe-pointer small mx-2">Last 60 days</span>
                        <span @click="quick_selection(90)" class="text-blue-500 pe-pointer small mx-2">Last 90 days</span>
                        <span @click="quick_selection(365)" class="text-blue-500 pe-pointer small mx-2">Last year</span>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 my-1">
                            <div class="input-group">
                                <span class="input-group-text">Start date</span>
                                <input disabled id="datepicker-start" type="text" class="form-control">
                                <button id="datepicker-trigger-start" class="btn btn-outline-secondary" type="button">
                                    <BootstrapIcon class="d-inline-block float-end" color="text-teal-500" name="calendar3" :size="16" />
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 my-1">
                            <div class="input-group">
                                <span class="input-group-text">End date</span>
                                <input disabled id="datepicker-end" type="text" class="form-control">
                                <button id="datepicker-trigger-end" @click="toggle_picker(picker_end)" class="btn btn-outline-secondary" type="button">
                                    <BootstrapIcon class="d-inline-block float-end" color="text-teal-500" name="calendar3" :size="16" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6">
                <div class="bg-white rounded shadow-sm p-3">
                    <div>
                        <h5 class="m-0">Orders overview</h5>
                        <p class="text-gray-600 small">Number of orders placed</p>
                    </div>
                    <BarChart v-if="statistics_loaded && d_orders.datasets[0].data.length > 0" :values="d_orders" />
                    <EmptyChart v-else description="No orders placed in the selected interval.." />
                </div>
            </div>
            <div class="col-6">
                <div class="bg-white rounded shadow-sm p-3">
                    <div>
                        <h5 class="m-0">Products Overview</h5>
                        <p class="text-gray-600 small">Number of products ordered</p>
                    </div>
                    <LineChart v-if="statistics_loaded  && d_items.datasets[0].data.length > 0" :values="d_items" />
                    <EmptyChart v-else description="No items provisions in the selected interval.." />
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-4">
                <div class="bg-white rounded shadow-sm p-3">
                    <div>
                        <h5 class="m-0">Top 10 products</h5>
                        <p class="text-gray-600 small">The most wanted products</p>
                    </div>
                    <ChartBarHorizontal v-if="statistics_loaded && top_items.datasets[0].data.length > 0" :values="top_items" />
                    <EmptyChart v-else description="No data found.." />
                </div>
            </div>
            <div class="col-4">
                <div class="bg-white rounded shadow-sm p-3">
                    <div>
                        <h5 class="m-0">Top orders placed</h5>
                        <p class="text-gray-600 small">Members that have placed the most orders</p>
                    </div>
                    <ChartBarHorizontal v-if="statistics_loaded && top_requisition.datasets[0].data.length > 0" :values="top_requisition" />
                    <EmptyChart v-else description="No data found.." />
                </div>
            </div>
            <div class="col-4">
                <div class="bg-white rounded shadow-sm p-3">
                    <div>
                        <h5 class="m-0">Top orders finished</h5>
                        <p class="text-gray-600 small">Members that have delivered the most orders</p>
                    </div>
                    <ChartBarHorizontal v-if="statistics_loaded && top_delivery.datasets[0].data.length > 0" :values="top_delivery" />
                    <EmptyChart v-else description="No data found.." />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Pikaday from 'pikaday'
import Tooltip from '@/common/directive/Tooltip.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import StatisticsStore from '@/common/store/statistics.js'
import BarChart from '@/common/directive/BarChart.vue'
import LineChart from '@/common/directive/LineChart.vue'
import EmptyChart from '@/common/directive/EmptyChart.vue'
import ChartBarHorizontal from '@/common/directive/ChartBarHorizontal.vue'
import { MONTH_SHORT, COLORS } from '@/common/constants.js'

export default {
    components: {
        Tooltip,
        BarChart,
        LineChart,
        EmptyChart,
        BootstrapIcon,
        ChartBarHorizontal
    },
    data: function () {
        const start_date = new Date()
        const end_date = new Date()
        start_date.setDate(start_date.getDate()-30)

        return {
            picker_start: null,
            picker_end: null,
            picker_start_date: start_date,
            picker_end_date: end_date,
            d_orders: [],
            d_items: [],
            top_items: [],
            top_delivery: [],
            top_requisition: [],
            statistics_loaded: false,
            statistics_timeout: 0
        }
    },
    computed: {
        MONTH_SHORT: () => MONTH_SHORT,
        statistics: function () {
            return StatisticsStore.state.inventory_statistics
        }
    },
    watch: {
        picker_start_date: async function () {
            this.statistics_loaded = false
            await this.get_statistics()
        },
        picker_end_date: async function () {
            this.statistics_loaded = false
            await this.get_statistics()
        },
        statistics: function () {
            const POOL = Object.values(COLORS)

            let input1 = []
            let input2 = []
            let input3 = []
            let input4 = []
            let input5 = []
            let input6 = []

            if (this.statistics.d_orders.length > 0) {
                input1 = this.statistics.d_orders
            }
            if (this.statistics.d_items.length > 0) {
                input2 = this.statistics.d_items
            }
            if (this.statistics.top_items.length > 0) {
                input3 = this.statistics.top_items
            }
            if (this.statistics.top_requisition.length > 0) {
                input4 = this.statistics.top_requisition
            }
            if (this.statistics.top_delivery.length > 0) {
                input5 = this.statistics.top_delivery
            }
            if (this.statistics.locations_orders.length > 0) {
                input6 = this.statistics.locations_orders
            }

            const color1 = POOL.shift()
            const chart1 = {
                datasets: [
                    {
                        label: 'Total orders',
                        data: [],
                        fill: true,
                        borderColor: color1,
                        backgroundColor: color1.replace('1)', '0.5)'),
                        tension: 0.2,
                        pointRadius: 4
                    }
                ],
                labels: []
            }
            const chart2 = {
                datasets: [
                    {
                        label: 'Total products in orders',
                        data: [],
                        fill: true,
                        borderColor: COLORS.jade,
                        backgroundColor: COLORS.jade.replace('1)', '0.5)'),
                        tension: 0.2,
                        pointRadius: 4,
                        order: 1
                    }
                ],
                labels: []
            }
            const chart3 = {
                datasets: [
                    {
                        label: 'Quantity ordered',
                        data: [],
                        fill: true,
                        borderColor: COLORS.coral,
                        backgroundColor: COLORS.coral.replace('1)', '0.7)'),
                        tension: 0.2,
                        pointRadius: 4
                    }
                ],
                labels: []
            }
            const chart4 = {
                datasets: [
                    {
                        label: 'Orders placed',
                        data: [],
                        fill: true,
                        borderColor: COLORS.brown,
                        backgroundColor: COLORS.brown.replace('1)', '0.7)'),
                        tension: 0.2,
                        pointRadius: 4
                    }
                ],
                labels: []
            }
            const chart5 = {
                datasets: [
                    {
                        label: 'Orders delivered',
                        data: [],
                        fill: true,
                        borderColor: COLORS.pear,
                        backgroundColor: COLORS.pear.replace('1)', '0.7)'),
                        tension: 0.2,
                        pointRadius: 4
                    }
                ],
                labels: []
            }

            for (let i=0; i<input1.length; ++i) {
                chart1.datasets[0].data.push({
                    x: new Date(input1[i].year, input1[i].month-1, input1[i].day, 0, 0, 0).getTime(),
                    y: parseInt(input1[i].count, 10)
                })
            }

            for (let i=0; i<input2.length; ++i) {
                chart2.datasets[0].data.push({
                    x: new Date(input2[i].year, input2[i].month-1, input2[i].day, 0, 0, 0).getTime(),
                    y: parseInt(input2[i].count, 10)
                })
            }

            for (let i=0; i<input3.length; ++i) {
                chart3.datasets[0].data.push(parseInt(input3[i].count, 10))
                chart3.labels.push(input3[i].item.name)
            }

            for (let i=0; i<input4.length; ++i) {
                chart4.datasets[0].data.push(parseInt(input4[i].count, 10))
                chart4.labels.push(input4[i].requisition.username)
            }

            for (let i=0; i<input5.length; ++i) {
                chart5.datasets[0].data.push(parseInt(input5[i].count, 10))
                chart5.labels.push(input5[i].delivery.username)
            }

            input6.forEach(function (item) {
                const color = POOL.shift()
                const dataset = {
                    label: item.name,
                    data: [],
                    fill: true,
                    borderColor: color,
                    backgroundColor: color.replace('1)', '0.5)'),
                    tension: 0.2,
                    pointRadius: 4
                }
                if (item.orders.length > 0) {
                    for (let i=0; i<item.orders.length; ++i) {
                        dataset.data.push({
                            x: new Date(item.orders[i].year, item.orders[i].month-1, item.orders[i].day, 0, 0, 0).getTime(),
                            y: parseInt(item.orders[i].count, 10)
                        })
                    }
                    chart1.datasets.push(dataset)
                }
            })

            this.d_orders = chart1
            this.d_items = chart2
            this.top_items = chart3
            this.top_requisition = chart4
            this.top_delivery = chart5

            this.statistics_loaded = true
        }
    },
    created: async function () {
        await this.get_statistics()
    },
    mounted: async function () {
        const self = this
        const date = new Date()
        const min_date = new Date(2021, 9, 0)
        const max_date = new Date(date.getFullYear(), date.getMonth()+1, 0)

        this.picker_start = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-start'),
            trigger: document.getElementById('datepicker-trigger-start'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_start_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_start_date = date
                self.picker_end.setMinDate(date)
            }
        })
        this.picker_end = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-end'),
            trigger: document.getElementById('datepicker-trigger-end'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_end_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_end_date = date
                self.picker_start.setMaxDate(date)
            }
        })
    },
    methods: {
        quick_selection: function (days) {
            const start_date = new Date()
            const end_date = new Date()
            start_date.setDate(start_date.getDate() - days)
            this.picker_start_date = start_date
            this.picker_end_date = end_date
            this.picker_start.setDate(start_date)
            this.picker_end.setDate(end_date)
        },
        get_statistics: async function () {
            const q = {
                sy: this.picker_start_date.getFullYear(),
                sm: this.picker_start_date.getMonth(),
                sd: this.picker_start_date.getDate(),
                ey: this.picker_end_date.getFullYear(),
                em: this.picker_end_date.getMonth(),
                ed: this.picker_end_date.getDate()
            }
            clearTimeout(this.statistics_timeout)
            this.statistics_timeout = setTimeout(async function () {
                await StatisticsStore.dispatch('GET_INVENTORY_STATISTICS', q)
            }, 500)
        },
        toggle_picker (picker) {
            picker.isVisible() ? picker.hide() : picker.show()
        }
    }
}
</script>
