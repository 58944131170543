import {
    TICKETS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        tickets: [],
        paginator: {
            limit: 10,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_TICKETS (state, value) {
            state.tickets = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${TICKETS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_TICKETS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async RETRIEVE (context, data) {
            let result
            try {
                result = await axios.get(`${TICKETS_PATH}/${data.ticket_id}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async CREATE (context, data) {
            let response = {}
            try {
                response = await axios.post(`${TICKETS_PATH}`, data)
            } catch (error) {
                return error.response.data
            }
            return response.data
        },
        async REPLY (context, data) {
            let response = {}
            try {
                response = await axios.post(`${TICKETS_PATH}/${data.id}`, data.params)
            } catch (error) {
                return error.response.data
            }
            return response.data
        },
        async UPDATE (context, data) {
            let response = {}
            try {
                response = await axios.put(`${TICKETS_PATH}/${data.id}`, data.params)
            } catch (error) {
                return error.response.data
            }
            return response.data
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
