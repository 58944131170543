import {
    ORGANIZATIONS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'

const store = {
    state: { },
    mutations: { },
    actions: {
        async INFO (context, data) {
            let result = {}
            try {
                result = await axios.get(ORGANIZATIONS_PATH)
            } catch (error) {
                return error.response
            }
            return result
        },
        async ORGANIZATION_BILLING (context, data) {
            let result = {}
            try {
                result = await axios.put(`${ORGANIZATIONS_PATH}/billing`, data)
            } catch (error) {
                return error.response
            }
            return result
        },
        async ORGANIZATION_SETTINGS (context, data) {
            let result = {}
            try {
                result = await axios.put(`${ORGANIZATIONS_PATH}/settings`, data)
            } catch (error) {
                return error.response.data
            }
            return result.data
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
