<template>
    <div>
        <input ref="logo" type="file" class="d-none" @change="validate_file()">
        <button class="btn btn-outline-secondary" @click="$refs.logo.click()"><BootstrapIcon color="text-gray-700 me-2" name="cloud-upload" :size="18" />{{text}}</button>
    </div>
</template>

<script>
import axios from 'axios'
import BootstrapIcon from '@/common/directive/BootstrapIcon'

import {
    ITEMS_PATH,
    IMAGE_EXTENSION,
    IMAGE_MAX_FILESIZE,
    IMAGE_TARGET_PX
} from '@/common/constants'

export default {
    props: {
        tag: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: 'Upload'
        },
        metadata: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
        }
    },
    methods: {
        is_valid_image: function (fileName) {
            const file_extension = fileName.split('.').pop()

            if (IMAGE_EXTENSION.includes(file_extension)) {
                return true
            }

            return false
        },
        upload_file: async function (form_data) {
            return axios.post(ITEMS_PATH + '/' + this.metadata.item_id + '/image', form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        },
        is_valid_size: async function (file) {
            return new Promise(function (resolve, reject) {
                const _URL = window.URL || window.webkitURL
                const img = new Image()
                let width = 0
                let height = 0
                img.src = _URL.createObjectURL(file)
                img.onload = function () {
                    width = this.width
                    height = this.height
                    resolve({ width, height })
                }
                img.onerror = function () {
                    resolve({ width: 0, height: 0 })
                }
            })
        },
        validate_file: async function () {
            const self = this
            const file = this.$refs.logo.files[0]
            const reader = new FileReader()

            // must have accepted file type
            if (!this.is_valid_image(file.name)) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.invalid_file_type'), msg: this.$t('notifications.please_convert_image', { IMAGE_EXTENSION: IMAGE_EXTENSION.join(', ') }), level: 'danger' })
                return
            }

            // must not be higher IMAGE_MAX_FILESIZE
            if (file.size > IMAGE_MAX_FILESIZE) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.image_size_too_big'), msg: this.$t('notifications.image_size_exceeds', { IMAGE_SIZE: IMAGE_MAX_FILESIZE/1024/1000 }), level: 'danger' })
                return
            }

            reader.onload = function (e) {
                const img = document.createElement('img')
                img.onload = function (event) {
                    // resize image
                    let canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')

                    const aspectRatio = img.width / img.height
                    let sourceWidth, sourceHeight, offsetX, offsetY

                    if (aspectRatio >= 1) {
                        sourceWidth = img.height
                        sourceHeight = img.height
                        offsetX = (img.width - img.height) / 2
                        offsetY = 0
                    } else {
                        sourceWidth = img.width
                        sourceHeight = img.width
                        offsetX = 0
                        offsetY = (img.height - img.width) / 2
                    }

                    canvas.width = sourceWidth
                    canvas.height = sourceHeight
                    ctx.drawImage(img, offsetX, offsetY, sourceWidth, sourceHeight, 0, 0, sourceWidth, sourceHeight)

                    // Check if resizing is necessary
                    if (sourceWidth > IMAGE_TARGET_PX || sourceHeight > IMAGE_TARGET_PX) {
                        const resizedCanvas = document.createElement('canvas')
                        const resizedCtx = resizedCanvas.getContext('2d')
                        const scaleFactor = Math.min(IMAGE_TARGET_PX / sourceWidth, IMAGE_TARGET_PX / sourceHeight)

                        resizedCanvas.width = sourceWidth * scaleFactor
                        resizedCanvas.height = sourceHeight * scaleFactor
                        resizedCtx.drawImage(canvas, 0, 0, sourceWidth, sourceHeight, 0, 0, sourceWidth * scaleFactor, sourceHeight * scaleFactor)

                        canvas = resizedCanvas
                    }

                    canvas.toBlob(async (blob) => {
                        const formData = new FormData()
                        formData.append('file', blob, file.name)

                        const response = await self.upload_file(formData)

                        if (response.status === 200 && response.data.status === 0) {
                            // emit the changes to the subscribed controllers
                            self.$bus.$emit('Notification/show', { title: self.$t('notifications.operation_success'), msg: self.$t('notifications.image_has_been_uploaded'), level: 'success' })
                            self.$bus.$emit('FileUpload/completed/'+self.tag, {
                                data: response.data
                            })
                        } else {
                            self.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
                        }
                    }, file.type)
                }
                img.src = e.target.result
            }
            reader.readAsDataURL(file)
        }
    }
}
</script>