import {
    AUDIT_LOGS_PATH,
    TIMELINE_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        logs: [],
        paginator: {
            limit: 10,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_LOGS (state, value) {
            state.logs = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${AUDIT_LOGS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_LOGS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async TIMELINE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${TIMELINE_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            return result
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
