<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="people" :size="20" />
                    <span class="align-middle">Customers</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1">
                        <span>You have <span class="fs-5">{{paginator.count}}</span> customers.</span>
                    </div>
                    <div>
                        <input v-model="filter" type="text" class="form-control" placeholder="Filter customers">
                    </div>
                </div>
            </div>
        </div>

        <div v-if="paginator.count > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr class="">
                                <th scope="col" class="border-0 pb-3">Name</th>
                                <th scope="col" class="border-0 pb-3">E-mail</th>
                                <th scope="col" class="border-0 pb-3">Address</th>
                                <th scope="col" class="border-0 pb-3">Phone</th>
                                <th scope="col" class="border-0 pb-3">Orders</th>
                                <th scope="col" class="border-0 pb-3">Created</th>
                                <!-- <th scope="col" class="border-0 pb-3">Agreements</th> -->
                                <th scope="col" class="border-0 pb-3 text-end">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700  border-top-0">
                            <tr v-for="(customer, index) in customers" :key="index" class="align-middle">
                                <td>
                                    <div class="fw-bolder">
                                        <HighlightText :search="filter" :value="customer.name" />
                                    </div>
                                </td>
                                <td>
                                    <HighlightText :search="filter" :value="customer.email" />
                                </td>
                                <td>
                                    <HighlightText :search="filter" :value="customer.address" />
                                </td>
                                <td>
                                    <HighlightText :search="filter" :value="customer.phone" />
                                </td>
                                <td>
                                    <div class="rounded overflow-hidden border">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <thead>
                                                <tr class="align-middle">
                                                    <td class="text-gray-600 text-start">ID</td>
                                                    <td class="text-gray-600 text-start">Products</td>
                                                </tr>
                                            </thead>
                                            <tbody class="border-top-0">
                                                <tr v-for="(order, index) in customer.orders" :key="index">
                                                    <td>
                                                        <p class="text-gray-700 m-0 text-start">{{order.id}}</p>
                                                        <p class="text-gray-700 m-0 text-start">Created at {{$filters.PrettyDate(order.created_at)}}</p>
                                                    </td>
                                                    <td><p v-for="(product, indexP) in order.items" :key="indexP" class="text-gray-700 m-0 text-start">{{product.name}}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-nowrap small">{{$filters.PrettyDate(customer.created_at)}}</div>
                                </td>
                                <td class="text-nowrap text-end">
                                    <button type="button" @click="request_confirm_anonymize_customer(customer)" class="btn btn-sm btn-warning">
                                        Anonymize
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="No customers found. They will show up here after they place their first order." />
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'customers'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalConfirmAnonymizeCustomer :member="selected_customer" />
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import HighlightText from '@/common/directive/HighlightText.vue'
import CustomerStore from '@/common/store/customer.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import Paginator from '@/common/directive/Paginator.vue'
import ModalConfirmAnonymizeCustomer from '@/common/modal/console/modal-confirm_delete_member.vue'

export default {
    components: {
        Paginator,
        BootstrapIcon,
        HighlightText,
        // ModalManageKey,
        // ModalConfirmDeleteKey,
        // BootstrapIcon,
        EmptyResponse,
        ModalConfirmAnonymizeCustomer
    },
    data: function () {
        return {
            paginate_timeout: 0,
            selected_customer: {},
            filter: ''
        }
    },
    computed: {
        quota: function () {
            return this.$store.state.quota
        },
        customers: () => CustomerStore.state.customers,
        paginator: () => CustomerStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/customers/set_page')
        this.$bus.$on('Paginator/customers/set_page', (value) => {
            this.set_page(value)
        })
        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            CustomerStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.length >= 1) {
                    query.filter = self.filter
                }
                await CustomerStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 200)
        },
        request_confirm_anonymize_customer: function (customer) {
            this.selected_customer = customer
            this.$bus.$emit('Modal/show/confirm-anonymize-customer')
        }
    }
}
</script>
