<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">Help Desk</h1>
                <h2 class="h6">Ticket ID: {{ticket.id}}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <router-link to="/console/helpdesk" class="d-inline-block my-2 py-2"><BootstrapIcon color="text-blue-500 d-inline-block align-middle" name="arrow-left-short" :size="22" /> All Tickets</router-link>
                <div class="bg-white shadow-sm rounded p-3 mb-5 d-flex justify-content-between align-items-center">
                    <div>
                        <div>Subject: <span class="fs-5 fw-bold">{{ticket.subject}}</span></div>
                        <div>
                            <span v-if="ticket.status === TICKET_STATUS_RESOLVED" class="badge bg-success">RESOLVED</span>
                            <span v-else-if="ticket.status === TICKET_STATUS_OPEN" class="badge bg-warning">OPEN</span>
                            <span v-else-if="ticket.status === TICKET_STATUS_PENDING_SUPPORT" class="badge bg-secondary">PENDING SUPPORT</span>
                            <span v-else-if="ticket.status === TICKET_STATUS_PENDING_CUSTOMER" class="badge bg-info">PENDING CUSTOMER</span>
                        </div>
                    </div>
                    <div v-if="ticket.status !== TICKET_STATUS_RESOLVED" >
                        <Tooltip class="d-inline-block px-3" :value="'If you no longer require assistance you may close this ticket.'" icon="info-circle" />
                        <button @click="resolve_ticket()" type="button" class="btn btn-outline-info btn-sm d-inline-block">Close Ticket</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white rounded shadow-sm p-3">
                    <!-- Ticket is Open -->
                    <div v-if="ticket.status !== TICKET_STATUS_RESOLVED"  class="mb-3">
                        <div class="d-flex justify-content-between mb-3">
                            <div>
                                <label for="exampleFormControlTextarea1" class="form-label fs-5 fw-bold">Reply</label>
                                <div><strong :class="{'text-red-500': !valid_form}">{{form.description.length}}</strong> <small>/5000 characters</small></div>
                            </div>
                            <button :disabled="!valid_form || loading" @click="submit_reply()" class="btn btn-outline-secondary">Submit</button>
                        </div>
                        <textarea v-model="form.description" class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                    </div>
                    <!-- Ticket is resolved -->
                    <div v-else>
                        <p>Ticket is resolved and no additional replies may be added.</p>
                    </div>
                    <hr>
                    <div v-for="(message, index) in ticket.messages" :key="index" class="mt-4">
                        <div class="d-flex justify-content-between">
                            <p class="fw-bold">{{message.user.email}}</p>
                            <p class="text-gray-600">{{$filters.PrettyDate(message.created_at)}}</p>
                        </div>
                        <div>
                            <div class="my-2">
                                <pre><code>{{message.message}}</code></pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import TicketStore from '@/common/store/ticket.js'
import {
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RESOLVED,
    TICKET_STATUS_PENDING_SUPPORT,
    TICKET_STATUS_PENDING_CUSTOMER
} from '@/common/constants.js'
import Tooltip from '@/common/directive/Tooltip'

export default {
    components: {
        Tooltip,
        // EmptyResponse
        BootstrapIcon
    },
    data: function () {
        return {
            loading: false,
            paginate_timeout: 0,
            ticket: {},
            form: {
                description: ''
            }
        }
    },
    computed: {
        TICKET_STATUS_OPEN: () => TICKET_STATUS_OPEN,
        TICKET_STATUS_RESOLVED: () => TICKET_STATUS_RESOLVED,
        TICKET_STATUS_PENDING_SUPPORT: () => TICKET_STATUS_PENDING_SUPPORT,
        TICKET_STATUS_PENDING_CUSTOMER: () => TICKET_STATUS_PENDING_CUSTOMER,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        user: function () {
            return this.$store.state.user
        },
        tickets: function () {
            return TicketStore.state.tickets
        },
        paginator: () => TicketStore.state.paginator,
        valid_form: function () {
            return this.form.description.length > 30
        }
    },
    created: async function () {
        if (!this.is_authorized || !this.$route.params.ticket_id) {
            this.$router.replace('/login')
        }

        await this.retrieve()
    },
    methods: {
        retrieve: async function () {
            const query = {
                ticket_id: this.$route.params.ticket_id
            }
            const response = await TicketStore.dispatch('RETRIEVE', query)

            if (response.data.status === 0) {
                this.ticket = response.data.ticket
            }
        },
        resolve_ticket: async function () {
            this.loading = true
            const response = await TicketStore.dispatch('UPDATE', {
                id: this.ticket.id
            })

            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The status has been successfully changed!', level: 'success' })
                this.$router.push('/console/helpdesk')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        },
        submit_reply: async function () {
            this.loading = true
            const response = await TicketStore.dispatch('REPLY', {
                id: this.ticket.id,
                params: {
                    description: this.form.description
                }
            })

            if (response.status === 0) {
                this.form.description = ''
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The reply has been successfully added!', level: 'success' })
                await this.retrieve()
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
