import {
    SESSIONS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        sessions: [],
        paginator: {
            limit: 10,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_SESSIONS (state, value) {
            state.sessions = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        DELETE_SESSION (state, session_id) {
            state.sessions = [
                ...state.sessions.filter((session) => {
                    if (session.id === session_id) {
                        return false
                    }
                    return true
                })
            ]
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${SESSIONS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_SESSIONS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async DELETE_SESSION (context, data) {
            let response = {}
            try {
                response = await axios.delete(`${SESSIONS_PATH}/${data.session_id}`)
                context.commit('DELETE_SESSION', data.session_id)
            } catch (error) {
                return error.response.data
            }
            return response.data
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
