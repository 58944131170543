<template>
    <Modal :close_button="true" :size="'modal-md'" mid="checkout-completed">
        <template v-slot:header>Congratulations!</template>
        <template v-slot:body>
            <div class="text-center">
                <BootstrapIcon v-if="plan.plan_id === STORE_PLAN_ID_TRIAL" class="me-3 align-middle" color="fill-yellow-600 text-yellow-600" name="x-diamond-fill" :size="30" />
                <BootstrapIcon v-else-if="plan.plan_id === STORE_PLAN_ID_ULTIMATE" class="me-3 align-middle" color="fill-teal-500 text-teal-500" name="x-diamond-fill" :size="30" />
                <BootstrapIcon v-else-if="plan.plan_id === STORE_PLAN_ID_PRO" class="me-3 align-middle" color="fill-coral-500 text-coral-500" name="x-diamond-fill" :size="30" />
                <BootstrapIcon v-else-if="plan.plan_id === STORE_PLAN_ID_BASIC" class="me-3 align-middle" color="fill-purple-500 text-purple-500" name="x-diamond-fill" :size="30" />
            </div>
            <p class="text-center fs-5 mt-3">You have successfully subscribed to <br><strong class="text-gray-600">{{SUBSCRIPTION_PLAN_NAME[plan.plan_id]}}</strong></p>
            <p class="text-center m-0">The subscription will show on your invoice as <br><strong>{{product.name}}</strong></p>
        </template>
        <template v-slot:footer>
            <button @click="to_subscriptions()" type="button" class="btn bg-teal-500 text-white bg-gradient px-4 mx-auto">Go to Subscriptions</button>
        </template>
    </Modal>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import {
    SUBSCRIPTION_PLAN_NAME,
    BILLING_CYCLE,
    BILLING_CYCLE_NA,
    BILLING_CYCLE_MONTHLY,
    BILLING_CYCLE_YEARLY,
    STORE_PLAN_ID_TRIAL,
    STORE_PLAN_ID_ULTIMATE,
    STORE_PLAN_ID_PRO,
    STORE_PLAN_ID_BASIC
} from '@/common/constants.js'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        plan: {
            type: Object,
            default: () => {}
        },
        product: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            SUBSCRIPTION_PLAN_NAME,
            BILLING_CYCLE,
            BILLING_CYCLE_NA,
            BILLING_CYCLE_MONTHLY,
            BILLING_CYCLE_YEARLY,
            STORE_PLAN_ID_TRIAL,
            STORE_PLAN_ID_ULTIMATE,
            STORE_PLAN_ID_PRO,
            STORE_PLAN_ID_BASIC
        }
    },
    methods: {
        to_subscriptions: async function () {
            this.$bus.$emit('Modal/hide/checkout-completed')
            this.$router.push('/console/subscriptions')
        }
    }
}
</script>
