<template>
    <nav class="navbar navbar-expand-lg navbar-light py-3" style="z-index: 2">
        <div class="container">
            <router-link class="text-start navbar-brand" to="/">
                <img src="/img/identity/tgt_ro.png" alt="logo" height="34">
            </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 me-lg-2">
                    <li class="nav-item">
                        <router-link active-class="text-decoration-underline" class="nav-link text-gray-700 p-2 py-lg-3 fw-bold" to="/how_it_works">{{$t('navbar.how_it_works')}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link active-class="text-decoration-underline" class="nav-link text-gray-700 p-2 py-lg-3 fw-bold" to="/pricing">{{$t('navbar.pricing')}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link active-class="text-decoration-underline" class="nav-link text-gray-700 p-2 py-lg-3 fw-bold" to="/contact">{{$t('navbar.contact')}}</router-link>
                    </li>
                    <li class="nav-item py-md-2">
                        <a class="btn btn-outline-brand py-2 px-4 mx-2" title="Tapgiant Store" :href="'https://store.' + TLD">{{$t('navbar.order_management')}}</a>
                    </li>
                </ul>
                <div v-if="email && is_authorized" class="dropdown d-inline-block">
                    <button type="button" class="btn btn-brand bg-gradient text-white nav-item dropdown-toggle py-2 px-4" data-bs-toggle="dropdown" aria-expanded="false">
                        {{$t('navbar.account')}}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-start dropdown-menu-lg-end">
                        <li>
                            <router-link to="/console/account" class="dropdown-item" type="button">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="text-teal-500" :name="'person-circle'" :size="18" />
                                <span class="align-middle d-inline-block">{{$t('navbar.settings')}}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/console/store" class="dropdown-item" type="button">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="text-gray-600" :name="'gear'" :size="18" />
                                <span class="align-middle d-inline-block">{{$t('navbar.control_panel')}}</span>
                            </router-link>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                            <a @click="logout()" class="dropdown-item align-middle">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="fill-red-500 text-red-500" :name="'power'" :size="18" />
                                <span class="align-middle d-inline-block text-red-500">{{$t('navbar.logout')}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <router-link v-else class="btn btn-brand bg-gradient text-white nav-item py-2 px-4 mx-2" to="/login">{{$t('navbar.account')}}</router-link>
            </div>
        </div>
    </nav>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import { TLD } from '@/common/constants.js'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            TLD
        }
    },
    computed: {
        email: function () {
            return this.$store.state.user.email
        },
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    methods: {
        logout: async function () {
            await this.$store.dispatch('LOGOUT')
            this.$router.replace('/')
        }
    }
}
</script>
