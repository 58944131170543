<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-delete-account">
        <template v-slot:header>Delete Account?</template>
        <template v-slot:body>
            <p>WARNING: all your data and settings will be permanently erased and will not be recoverable.</p>
            <ul>
                <li>all orders</li>
                <li>all member accounts</li>
                <li>all products & categories</li>
                <li>all reports & statistics</li>
                <li>all subscriptions & payment history</li>
            </ul>
            <div class="alert alert-danger mt-3" role="alert">
                To ensure that this action is not submitted by mistake and to protect our customers data, the account deletion will be scheduled for <strong>{{removal_date}}</strong>.
            </div>
            <div @click="agree = !agree" class="pe-pointer noselect">
                <BootstrapIcon v-if="agree" class="me-2 text-red-500 fill-red-500" name="check-square" :size="18" />
                <BootstrapIcon v-else class="me-2 text-red-500 fill-red-500" name="square" :size="18" />
                <span class="text-red-500 align-middle">I understand that this is a destructive action which cannot be reverted and that I will lose all the above data.</span>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!agree || loading" type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">Delete Account</button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import Store from '@/common/store/user'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            loading: false,
            agree: false,
            removal_date: null,
            removal_days: 10
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    created: function () {
        const self = this
        const date = new Date()
        date.setDate(date.getDate() + this.removal_days)

        this.$bus.$on('Modal/shown/confirm-delete-account', function () {
            self.agree = false
            self.removal_date = DateFormat(date, 'dd MMM yyyy', { locale: self.locale === 'ro' ? ro : enUS }).toUpperCase()
        })
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-delete-account')
        },
        submit: async function () {
            this.loading = true
            const response = await Store.dispatch('DELETE_ACCOUNT')
            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.$bus.$emit('Notification/show', { title: 'Removal Scheduled', msg: `Your account has been successfully scheduled for removal on ${this.removal_date}.`, level: 'success' })
                    await this.$store.dispatch('GET_INFO')
                    this.$bus.$emit('Organization/info')
                    this.$bus.$emit('Modal/hide/confirm-delete-account')
                }
            } else if (response.status === 409) {
                this.$bus.$emit('Notification/show', { title: 'Removal Already Scheduled', msg: 'It looks like there is another removal already scheduled for your account.', level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            this.loading = false
        }
    }
}
</script>
