<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="activity" :size="20" />
                    <span class="align-middle">{{$t('menu.activity')}}</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3 bg-white rounded shadow-sm px-3 py-3">
                    <span class="me-2">{{$t('console.timeframe')}}:</span>
                    <span @click="timeframe_h = 24" :class="{'bg-gray-300': timeframe_h === 24}" class="text-blue-500 pe-pointer small mx-2 rounded px-3 py-2 noselect fw-bold">{{$t('console.last_x_hours', { HOURS: 24 })}}</span>
                    <span @click="timeframe_h = 48" :class="{'bg-gray-300': timeframe_h === 48}" class="text-blue-500 pe-pointer small mx-2 rounded px-3 py-2 noselect fw-bold">{{$t('console.last_x_hours', { HOURS: 48 })}}</span>
                    <span @click="timeframe_h = 72" :class="{'bg-gray-300': timeframe_h === 72}" class="text-blue-500 pe-pointer small mx-2 rounded px-3 py-2 noselect fw-bold">{{$t('console.last_x_hours', { HOURS: 72 })}}</span>
                </div>
            </div>
        </div>
        <div v-if="activity.length > 0" class="row">
            <div class="col">
                <div class="timeline">
                    <div v-for="(item, index) in activity" :key="index" class="tl-item">
                        <div :class="item.dot_class" class="tl-dot"></div>
                        <div v-if="item.locale_id" class="tl-content">
                            <div v-html="$t(item.locale_id, item.locale_vars)" class=""></div>
                            <div v-if="item.reason" :class="item.dot_class" class="border bg-gray-100 rounded shadow-sm px-3 py-2 my-2">
                                <strong class="me-2">{{$t('console.reason')}}:</strong>
                                <span>{{item.reason}}</span>
                            </div>
                            <div class="tl-date text-muted mt-1">{{date_format(item.date)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyActivity :description="$t('console.no_activity_for_timeframe')" />
            </div>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import AuditLogStore from '@/common/store/auditlog.js'
import EmptyActivity from '@/common/directive/EmptyActivity.vue'
import { formatDistance } from 'date-fns'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        EmptyActivity,
        BootstrapIcon
    },
    data: function () {
        return {
            timeframe_h: 24,
            activity: []
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    watch: {
        timeframe_h: async function () {
            await this.get_timeline()
        }
    },
    created: async function () {
        await this.get_timeline()
    },
    methods: {
        get_timeline: async function () {
            const query = {
                timeframe_h: this.timeframe_h
            }
            const response = await AuditLogStore.dispatch('TIMELINE', {
                ...query
            })
            if (response.status === 200) {
                this.activity = response.data.items
            }
        },
        date_format: function (date) {
            return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: this.locale === 'ro' ? ro : enUS }).toUpperCase()
        }
    }
}
</script>
