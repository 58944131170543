<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="people" :size="20" />
                    <span class="align-middle">Cash Register</span>
                </h1>
            </div>
        </div>
    </div>
</template>
