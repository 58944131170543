<template>
    <Modal :close_button="true" :size="'modal-md'" mid="manage-store-member">
        <template v-slot:header>
            <span v-if="member && member.id">Edit member</span>
            <span v-else>Add member</span>
        </template>
        <template v-slot:body>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Username<span class="ms-1 text-red-500">*</span></label>
                    <input v-model="form.username" type="text" class="form-control form-control-lg" placeholder="e.g. johndoe" aria-label="Username">
                    <small class="d-block mt-1 mb-2">The username will be used for authentication and must be provided to the team member.</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Role<span class="ms-1 text-red-500">*</span></label>
                    <div class="dropdown">
                        <button type="button" class="btn btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                            {{form.role}}
                        </button>
                        <ul class="dropdown-menu dropdown-menu-start">
                            <li @click="form.role = 'sales'" class="p-2 pe-pointer">SALES</li>
                            <li @click="form.role = 'delivery'" class="p-2 pe-pointer">DELIVERY</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold">Allow access to selected Workplace(s)</label>
                    <div v-for="(name, id) in locations" :key="id" class="form-check">
                        <input v-model="form.restricted_locations" class="form-check-input" type="checkbox" :value="id" :id="'restrict-l-'+id">
                        <label class="form-check-label" :for="'restrict-l-'+id">
                            {{name}}
                        </label>
                    </div>
                    <small class="d-block mt-1 mb-2">Leave unchecked if no restrictions required.<br>If no workplace selected, member has access to all workplaces.</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <small><span class="text-red-500">*</span> Fields with asterisk are mandatory.</small>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="!valid_form && !loading" type="button" class="btn btn-success bg-gradient px-4" @click="submit()">
                <span v-if="member && member.id">Update</span>
                <span v-else>Create</span>
            </button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryMemberStore from '@/common/store/inventory_member.js'
import InventoryLocationStore from '@/common/store/inventory_location.js'

export default {
    props: {
        member: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            loading: false,
            locations: {},
            form: {
                username: '',
                role: 'sales',
                restricted_locations: []
            }
        }
    },
    computed: {
        valid_form: function () {
            return this.form.username.length >= 4 &&
                this.form.username.length < 255
        }
    },
    watch: {
        member: function () {
            if (this.member && this.member.id) {
                this.form.username = this.member.username
                this.form.role = this.member.role
                this.form.restricted_locations = Object.keys(this.member.locations)
            } else {
                this.form.username = ''
                this.form.role = 'sales'
                this.form.restricted_locations = []
            }
        }
    },
    created: async function () {
        const self = this
        this.$bus.$off('Modal/shown/manage-store-member')
        this.$bus.$on('Modal/shown/manage-store-member', async function () {
            await self.get_locations()
        })
    },
    methods: {
        add_restricted_location: function (id) {
            this.form.restricted_locations.push(id)
        },
        get_locations: async function () {
            const response = await InventoryLocationStore.dispatch('GET_ASSOC')
            if (response.data.status === 0) {
                this.locations = response.data.items
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        cancel: async function () {
            this.$bus.$emit('Modal/hide/manage-store-member')
        },
        submit: async function () {
            this.loading = true

            const payload = {
                username: this.form.username,
                role: this.form.role,
                restricted_locations: this.form.restricted_locations
            }

            let response = null
            if (this.member && this.member.id) {
                response = await InventoryMemberStore.dispatch('UPDATE', {
                    id: this.member.id,
                    params: payload
                })
            } else {
                response = await InventoryMemberStore.dispatch('CREATE', payload)
            }

            if (response.status === 200) {
                this.$bus.$emit('Modal/hide/manage-store-member')
                this.form.username = ''
                this.form.role = 'sales'
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been successfully applied!', level: 'success' })
            } else if (response.status === 409) {
                this.$bus.$emit('Notification/show', { title: 'Member limit reached!', msg: 'You have reached your member quota limit.<br>You may upgrade your subscription, purchase another subscription or delete existing members.', level: 'danger' })
            } else if (response.status === 422) {
                this.$bus.$emit('Notification/show', { title: 'Member already exists!', msg: 'A member with similar username already exists. Please use a different username or edit the existing member.', level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loading = false
        }
    }
}
</script>
