<template>
    <div class="container">
        <img class="position-absolute top-0 end-0 w-75" src="/img/svg/nav-corner-2.svg" style="z-index: 0;">

        <div class="row mb-5 py-5 position-relative">
            <div class="col-12 col-md-6">
                <div class="d-flex h-100 align-items-center">
                    <div>
                        <h1 class="text-center text-gray-700 text-lg-start fw-bolder mb-2 pt-5 px-4 px-lg-0 h2">{{$t('homepage.title')}}</h1>
                        <h2 class="text-center text-lg-start fw-light h5 mt-3 px-4 px-lg-0">{{$t('homepage.subtitle')}}</h2>
                        <!-- Get started 1 -->
                        <div class="text-center text-lg-start pb-5">
                            <router-link v-if="is_authorized" to="/console/store" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white mt-4">{{$t('homepage.open_console')}}</router-link>
                            <router-link v-else to="/login" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white mt-4">{{$t('homepage.get_started')}}</router-link>
                            <p v-if="!is_authorized" class="mt-2 mb-0 small text-gray-500">{{$t('pricing.no_credit_card_required')}}</p>
                            <p v-if="!is_authorized" class="mb-0 small text-gray-500">{{$t('pricing.free_days_trial', { DAYS: 14 })}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <img class="w-50" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-dashboard.png'" />
                <img class="w-50" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-summary.png'" />
            </div>
        </div>

        <!-- For me? -->
        <div class="row pt-5 mb-5 position-relative">
            <div class="col-12 pt-5">
                <h3 class="h2 text-gray-800 fw-bolder text-center mb-5">{{$t('homepage.how_does_help_me')}}</h3>
            </div>
            <div class="col-12 col-lg-4 mb-4">
                <div class="bg-gray-100 bg-gradient p-4 text-center h-100">
                    <div class="d-flex flex-column align-items-center h-100">
                        <BootstrapIcon class="align-middle d-inline-block mb-4" color="fill-orange-500 text-orange-500" name="shop" :size="48" />
                        <h5 class="text-gray-700 h-25">{{$t('homepage.reason_one_title')}}</h5>
                        <p class="mt-3 flex-grow-1">{{$t('homepage.reason_one_description')}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb-4">
                <div class="bg-gray-100 bg-gradient p-4 text-center h-100">
                    <div class="d-flex flex-column align-items-center h-100">
                        <BootstrapIcon class="align-middle d-inline-block mb-4" color="fill-salmon-500 text-salmon-500" name="cart-check" :size="48" />
                        <h5 class="text-gray-700 h-25">{{$t('homepage.reason_two_title')}}</h5>
                        <p class="mt-3 flex-grow-1">{{$t('homepage.reason_two_description')}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb-4">
                <div class="bg-gray-100 bg-gradient p-4 text-center h-100">
                    <div class="d-flex flex-column align-items-center h-100">
                        <BootstrapIcon class="align-middle d-inline-block mb-4" color="fill-yellow-500 text-yellow-500" name="people" :size="48" />
                        <h5 class="text-gray-700 h-25">{{$t('homepage.reason_three_title')}}</h5>
                        <p class="mt-3 flex-grow-1">{{$t('homepage.reason_three_description')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tablet requisition -->
        <div class="row pt-5 mb-5 position-relative">
            <div class="col-12 col-lg-6">
                <img class="w-100" :src="'/img/bank/'+locale+'_tapgiant-tablet-requisition.png'" />
            </div>
            <div class="col-12 col-lg-6">
                <div class="d-flex h-100 align-items-center mb-5">
                    <div class="w-100 text-center text-lg-start">
                        <h2 v-html="$t('homepage.placing_orders_easy')" class="b-5 fw-bolder text-teal-600 mb-3"></h2>
                        <h3>{{$t('homepage.no_expensive_equipment_required')}}</h3>
                        <p class="text-gray-500 ps-0 pe-lg-5">{{$t('homepage.compatible_with_all_devices')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile delivery -->
        <div class="row pt-5 mb-5 position-relative">
            <div class="col-12 col-lg-6">
                <div class="d-flex h-100 align-items-center mb-5">
                    <div class="w-100 text-center text-lg-end">
                        <h2 class="b-5 fw-bolder text-gray-800 mb-3"> <span class="text-gradient-red-600">{{$t('homepage.digitalize_your_business')}}</span><br>{{$t('homepage.stop_wasting_paper')}}</h2>
                        <h3>{{$t('homepage.manage_orders_from_device')}}</h3>
                        <p class="text-gray-500 pe-0 ps-lg-5">{{$t('homepage.still_print_formats')}}</p>
                        <p class="text-gray-500 mb-0">{{$t('homepage.receipt_for_customers')}}</p>
                        <p class="text-gray-500 mb-0">{{$t('homepage.receipt_for_delivery')}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <img class="w-50" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-dashboard.png'" />
                <img class="w-50" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-summary.png'" />
            </div>
        </div>

        <!-- Activity -->
        <div class="row pt-5 mb-5">
            <div class="col-12 col-lg-6">
                <BrowserFrame :address="WEBSITE_URL+'/console/activity'" class="no-min-width">
                    <template v-slot:content>
                        <img draggable="false" class="w-100" src="/img/bank/en_tapgiant-console-activity.png" alt="Tapgiant Console Activity">
                    </template>
                </BrowserFrame>
            </div>
            <div class="col-12 col-lg-6">
                <div class="d-flex h-100 align-items-center mb-5">
                    <div class="w-100 text-center text-lg-start">
                        <h2 v-html="$t('homepage.always_up_to_date')" class="b-5 fw-bolder text-cyan-600 mb-3"></h2>
                        <h3>{{$t('homepage.know_whats_going_on')}}</h3>
                        <p v-html="$t('homepage.stop_back_and_forths')" class="text-gray-500 pe-0 pe-lg-5"></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Console -->
        <div class="row pt-5 mb-5 position-relative">
            <div class="col-12 col-lg-6">
                <div class="d-flex h-100 align-items-center mb-5">
                    <div class="w-100 text-center text-lg-end">
                        <h2 v-html="$t('homepage.we_aggregate_data_for_you')" class="b-5 fw-bolder text-purple-400 mb-3"></h2>
                        <h3 v-html="$t('homepage.key_insights_administration_console')"></h3>
                        <p class="text-gray-500 ps-0 ps-lg-5">{{$t('homepage.additioanl_insights')}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 position-relative">
                <BrowserFrame :address="WEBSITE_URL+'/console'">
                    <template v-slot:content>
                        <img draggable="false" class="w-100" src="/img/bank/en_tapgiant-console-dashboard.png" alt="Tapgiant Console Dashboard">
                    </template>
                </BrowserFrame>
            </div>
        </div>

        <!-- Get started 2 -->
        <div class="row justify-content-center text-center my-5 pt-5">
            <div class="col">
                <p class="text-gray-500 mt-4">{{$t('homepage.ready_to_streamline')}}</p>
                <router-link v-if="is_authorized" to="/console/store" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white">{{$t('homepage.open_console')}}</router-link>
                <router-link v-else to="/login" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white mt-4">{{$t('homepage.get_started')}}</router-link>
                <p v-if="!is_authorized" class="mt-2 mb-0 small text-gray-500">{{$t('pricing.no_credit_card_required')}}</p>
                <p v-if="!is_authorized" class="mb-0 small text-gray-500">{{$t('pricing.free_days_trial', { DAYS: 14 })}}</p>
            </div>
        </div>

        <!-- Key Features -->
        <div class="row justify-content-center text-center pt-5">
            <div class="col-12 pb-3">
                <h2 class="b-5 fw-bolder text-center text-gray-800 mb-5">{{$t('homepage.we_got_you_covered')}}</h2>
            </div>
            <div v-for="(feature, index) in KEY_FEATURES_STORE" :key="index" class="col-12 col-md-6 col-lg-4 mb-5 mt-2">
                <div class="d-flex h-100 align-items-start pt-4 pb-3 px-3">
                    <div class="bg-teal-100 rounded p-3">
                        <BootstrapIcon class="align-middle d-inline-block" color="fill-gray-700 text-gray-700" :name="feature[1]" :size="28" />
                    </div>
                    <div class="flex-fill text-start ps-4">
                        <p class="fw-bold fs-5">{{feature[0]}}</p>
                        <p v-html="feature[2]" class="mb-0 text-gray-600 fw-light"></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- FAQ -->
        <div class="row my-5">
            <div class="col-12">
                <h2 class="b-5 fw-bolder text-center text-gray-800 mb-3">{{$t('homepage.got_questions')}}</h2>
            </div>
        </div>
        <div class="row">
            <div v-for="(item, index) in FAQ" :key="index" class="col-12 col-lg-6 mb-5">
                <h5><strong class="text-gray-800">{{item[0]}}</strong></h5>
                <p v-html="item[1]" class="m-0 text-gray-600 fw-light"></p>
            </div>
        </div>

        <!-- Get started 3 -->
        <div class="row justify-content-center text-center mt-5">
            <div class="col">
                <router-link v-if="is_authorized" to="/console/store" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white mt-4">{{$t('homepage.open_console')}}</router-link>
                <router-link v-else to="/login" class="btn btn-lg btn-brand bg-gradient px-5 py-3 fw-bold text-white mt-4">{{$t('homepage.get_started')}}</router-link>
                <p v-if="!is_authorized" class="mt-2 mb-0 small text-gray-500">{{$t('pricing.no_credit_card_required')}}</p>
                <p v-if="!is_authorized" class="mb-0 small text-gray-500">{{$t('pricing.free_days_trial', { DAYS: 14 })}}</p>
            </div>
        </div>

        <!-- Quote -->
        <!-- <figure class="text-center my-5 py-5 rounded bg-white shadow-sm">
            <blockquote class="blockquote">
                <strong class="fs-4">&#8220;</strong>
                <p class="px-5">One of my buddies was managing several small stores. Every single day he was receiving orders through messaging apps and printing them on paper. He had no history of orders and had no control over the warehouse exits, pending or finished orders.<br>TapGiant Store streamlined the process, added extra visibility and reduced the entire effort of his crew.</p>
                <strong class="fs-4">&#8221;</strong>
            </blockquote>
            <figcaption class="blockquote-footer">
                <strong>Catalin</strong>, CEO & Founder of TapGiant
            </figcaption>
        </figure> -->
    </div>
</template>

<script>
import {
    WEBSITE_URL
} from '@/common/constants.js'
import BrowserFrame from '@/common/directive/BrowserFrame.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'

export default {
    components: {
        BrowserFrame,
        BootstrapIcon
    },
    data: function () {
        return {
            WEBSITE_URL,
            title_index: 0
        }
    },
    computed: {
        KEY_FEATURES_STORE: function () {
            return [
                [this.$t('homepage.feature.blazing_fast'), 'speedometer', this.$t('homepage.feature.blazing_fast_description')],
                [this.$t('homepage.feature.business_insights'), 'bar-chart', this.$t('homepage.feature.business_insights_description')],
                [this.$t('homepage.feature.compatible'), 'phone', this.$t('homepage.feature.compatible_description')],
                [this.$t('homepage.feature.customer_order'), 'person-check', this.$t('homepage.feature.customer_order_description')],
                [this.$t('homepage.feature.order_history'), 'clock-history', this.$t('homepage.feature.order_history_description')],
                [this.$t('homepage.feature.balance_export'), 'table', this.$t('homepage.feature.balance_export_description')],
                [this.$t('homepage.feature.audit_log'), 'receipt', this.$t('homepage.feature.audit_log_description')],
                [this.$t('homepage.feature.privacy'), 'shield-check', this.$t('homepage.feature.privacy_description')],
                [this.$t('homepage.feature.compliant'), 'award', this.$t('homepage.feature.compliant_description')],
                [this.$t('homepage.feature.helpdesk'), 'patch-question', this.$t('homepage.feature.helpdesk_description')]
            ]
        },
        FAQ: function () {
            return [
                [this.$t('homepage.faqs.why_would_i_use'), this.$t('homepage.faqs.why_would_i_use_description')],
                [this.$t('homepage.faqs.setup_timeframe'), this.$t('homepage.faqs.setup_timeframe_description')],
                [this.$t('homepage.faqs.privacy_concerns'), this.$t('homepage.faqs.privacy_concerns_description')],
                [this.$t('homepage.faqs.cancel_subscription'), this.$t('homepage.faqs.cancel_subscription_description')],
                [this.$t('homepage.faqs.reach_quota_cap'), this.$t('homepage.faqs.reach_quota_cap_description')],
                [this.$t('homepage.faqs.reach_quota_ultimate'), this.$t('homepage.faqs.reach_quota_ultimate_description')],
                [this.$t('homepage.faqs.reach_quota_members'), this.$t('homepage.faqs.reach_quota_members_description')],
                [this.$t('homepage.faqs.special_hardware'), this.$t('homepage.faqs.special_hardware_description')],
                [this.$t('homepage.faqs.technical_knowledge'), this.$t('homepage.faqs.technical_knowledge_description')],
                [this.$t('homepage.faqs.data_stored_location'), this.$t('homepage.faqs.data_stored_location_description')],
                [this.$t('homepage.faqs.how_safe_is_data'), this.$t('homepage.faqs.how_safe_is_data_description')],
                [this.$t('homepage.faqs.customer_service_sla'), this.$t('homepage.faqs.customer_service_sla_description')]
            ]
        },
        locale: function () {
            return this.$i18n.locale
        },
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    watch: {
    },
    created: async function () {
        const self = this
        this.$bus.$on('Carousel/slide', function (slide) {
            self.title_index = slide
        })
    },
    mounted: async function () {
    },
    methods: {
    }
}
</script>
