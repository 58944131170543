<template>
    <div id="tg-console" class="sidebar-open bg-gray-100">
        <div>
            <SidebarConsole />
        </div>
        <main v-if="is_authorized" class="l-console">
            <NavbarConsole />
            <div class="mt-4 mx-3">
                <router-view></router-view>
            </div>
        </main>

        <NotificationHandler />
    </div>
</template>

<script>
import NavbarConsole from '@/components/NavbarConsole.vue'
import SidebarConsole from '@/components/SidebarConsole.vue'
import NotificationHandler from '@/components/NotificationHandler.vue'

export default {
    components: {
        NavbarConsole,
        SidebarConsole,
        NotificationHandler
    },
    computed: {
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }
    }
}
</script>
