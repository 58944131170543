<template>
    <Modal :close_button="true" :size="'modal-lg'" mid="export-orders">
        <template v-slot:header>{{$t('console.export_orders')}}</template>
        <template v-slot:body>
            <div>
                <p class="h5 m-0">{{$t('console.timeframe')}}</p>
                <div class="mb-2">
                    <span class="small">{{$t('console.quick_selection')}}:</span>
                    <span v-for="(mo, index) in MONTH_SHORT" :key="index" @click="quick_selection_m(index)" class="text-blue-500 pe-pointer small mx-2 d-inline-block">{{mo}}</span>
                    <span @click="quick_selection_y(2021)" class="text-blue-500 pe-pointer small mx-2">2021</span>
                    <span @click="quick_selection_y(2022)" class="text-blue-500 pe-pointer small mx-2">2022</span>
                    <span @click="quick_selection_y(2023)" class="text-blue-500 pe-pointer small mx-2">2023</span>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 my-1">
                        <div class="input-group">
                            <span class="input-group-text">{{$t('console.start_date')}}</span>
                            <input disabled id="datepicker-start" type="text" class="form-control">
                            <button id="datepicker-trigger-start" class="btn btn-outline-secondary" type="button">
                                <BootstrapIcon class="d-inline-block float-end" color="fill-teal-500 text-teal-500" name="calendar3" :size="16" />
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-1">
                        <div class="input-group">
                            <span class="input-group-text">{{$t('console.end_date')}}</span>
                            <input disabled id="datepicker-end" type="text" class="form-control">
                            <button id="datepicker-trigger-end" @click="toggle_picker(picker_end)" class="btn btn-outline-secondary" type="button">
                                <BootstrapIcon class="d-inline-block float-end" color="fill-teal-500 text-teal-500" name="calendar3" :size="16" />
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row">
                    <div class="col">
                        <p class="mb-0">{{$t('console.select_interval_field')}}</p>
                        <div class="form-check form-check-inline pt-2">
                            <input v-model="filters.date_field" :value="DATE_FIELD_CREATED_AT" checked class="form-check-input" type="radio" name="form_date_field" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">{{$t('console.created_date')}}</label>
                        </div>
                        <div class="form-check form-check-inline pt-2">
                            <input v-model="filters.date_field" :value="DATE_FIELD_PICKUP_DATE" class="form-check-input" type="radio" name="form_date_field" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">{{$t('console.pickup_date')}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <hr class="my-2">
                <div class="row">
                    <div class="col">
                        <p class="mb-0">{{$t('console.select_workplace')}}</p>
                        <div v-for="(name, id) in locations" :key="id" @click="toggle_location(id)" class="form-check form-check-inline noselect py-2 ps-0">
                            <BootstrapIcon class="d-inline-block me-2" color="text-gray-600 fill-gray-600" :name="filters.locations.includes(id) ? 'check-square' : 'square'" :size="16" />
                            <label class="align-middle">{{name}}</label>
                        </div>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row">
                    <div class="col">
                        <p class="mb-0">{{$t('console.include_products_from_orders')}}</p>
                        <div class="d-flex gap-3 flex-wrap flex-fill pt-2">
                            <div @click="filters.status.order_pending = !filters.status.order_pending" class="noselect py-2">
                                <BootstrapIcon class="d-inline-block me-2" color="fill-coral-500 text-coral-500" :name="filters.status.order_pending ? 'check-square-fill' : 'square'" :size="18" />
                                <label class="align-middle">{{$t('console.order_status.pending')}}</label>
                            </div>
                            <div @click="filters.status.order_preparing = !filters.status.order_preparing" class="noselect py-2">
                                <BootstrapIcon class="d-inline-block me-2" color="fill-teal-500 text-green-500" :name="filters.status.order_preparing ? 'check-square-fill' : 'square'" :size="18" />
                                <label class="align-middle">{{$t('console.order_status.preparing')}}</label>
                            </div>
                            <div @click="filters.status.order_completed = !filters.status.order_completed" class="noselect py-2">
                                <BootstrapIcon class="d-inline-block me-2" color="fill-cyan-500 text-cyan-500" :name="filters.status.order_completed ? 'check-square-fill' : 'square'" :size="18" />
                                <label class="align-middle">{{$t('console.order_status.m_completed')}}</label>
                            </div>
                            <div @click="filters.status.order_delivered = !filters.status.order_delivered" class="noselect py-2">
                                <BootstrapIcon class="d-inline-block me-2" color="fill-brown-500 text-brown-500" :name="filters.status.order_delivered ? 'check-square-fill' : 'square'" :size="18" />
                                <label class="align-middle">{{$t('console.order_status.m_handed')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="alert alert-dark mt-3 mb-0 py-2" role="alert">
                            {{$t('console.selection_includes_x_orders', { COUNT: export_count })}}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="filters.locations.length === 0" class="alert alert-warning mt-3 mb-0" role="alert">
                {{$t('console.select_workplace_for_export')}}
            </div>
            <div v-else-if="!has_order_status" class="alert alert-warning mt-3 mb-0" role="alert">
                {{$t('console.select_order_status_for_export')}}
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="export_loading || filters.locations.length === 0 || !has_order_status || export_count === 0" type="button" class="btn btn-success bg-gradient px-4" @click="export_orders('xlsx')">{{$t('console.download_excel')}}</button>
            <button type="button" class="btn px-4" @click="cancel()">{{$t('controls.cancel')}}</button>
        </template>
    </Modal>
</template>

<script>
import * as Pikaday from 'pikaday'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import LocationStore from '@/common/store/inventory_location.js'
import OrderStore from '@/common/store/inventory_order.js'
import {
    MONTH_SHORT
} from '@/common/constants.js'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        const start_date = new Date()
        const end_date = new Date()
        start_date.setDate(1)
        start_date.setHours(0)
        start_date.setMinutes(0)

        return {
            DATE_FIELD_CREATED_AT: 'created_at',
            DATE_FIELD_PICKUP_DATE: 'pickup_date',
            export_timeout: 0,
            export_loaded: false,
            export_loading: false,
            export_count: 0,
            selection_year: (new Date()).getFullYear(),
            selection_month: (new Date()).getMonth(),
            picker_start: null,
            picker_end: null,
            picker_start_date: start_date,
            picker_end_date: end_date,
            MONTH_SHORT,
            filters: {
                locations: [],
                status: {
                    order_pending: true,
                    order_preparing: true,
                    order_completed: true,
                    order_delivered: false
                },
                date_field: 'created_at'
            }
        }
    },
    computed: {
        locations: function () {
            const locations = LocationStore.state.locations
            const set = {}

            locations.forEach(function (location) {
                set[location.id] = location.name
            })

            return set
        },
        has_order_status: function () {
            const self = this
            return Object.keys(this.filters.status).filter(function (key) {
                return self.filters.status[key]
            }).length > 0
        }
    },
    watch: {
        picker_start_date: async function () {
            this.export_loaded = false
            await this.export_orders()
        },
        picker_end_date: async function () {
            this.export_loaded = false
            await this.export_orders()
        },
        filters: {
            deep: true,
            handler: async function () {
                const self = this
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async function () {
                    self.export_loaded = false
                    await self.export_orders()
                }, 200)
            }
        }
    },
    mounted: async function () {
        const self = this
        const date = new Date()
        const min_date = new Date(2021, 9, 0)
        const max_date = new Date(date.getFullYear()+1, 12, 0)

        this.picker_start = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-start'),
            trigger: document.getElementById('datepicker-trigger-start'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_start_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_start_date = date
                self.picker_end.setMinDate(date)
            }
        })
        this.picker_end = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-end'),
            trigger: document.getElementById('datepicker-trigger-end'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_end_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_end_date = date
                self.picker_start.setMaxDate(date)
            }
        })
    },
    created: async function () {
        const self = this

        this.$bus.$off('Modal/shown/export-orders')
        this.$bus.$on('Modal/shown/export-orders', async function () {
            await LocationStore.dispatch('PAGINATE', {})
            self.filters.locations = Object.keys(self.locations)
        })
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/export-orders')
        },
        export_orders: async function (format) {
            const self = this
            const q = {
                locations: this.filters.locations,
                date_field: this.filters.date_field,
                sy: this.picker_start_date.getFullYear(),
                sm: this.picker_start_date.getMonth(),
                sd: this.picker_start_date.getDate(),
                ey: this.picker_end_date.getFullYear(),
                em: this.picker_end_date.getMonth(),
                ed: this.picker_end_date.getDate(),
                format: format || 'count',
                status: Object.keys(this.filters.status).filter(function (key) {
                    return self.filters.status[key]
                })
            }
            clearTimeout(this.export_timeout)
            if (q.status.length === 0 || q.locations.length === 0) {
                return
            }
            this.export_loading = true
            this.export_timeout = setTimeout(async function () {
                if (self.filters.locations.length === 0) {
                    self.export_loading = false
                    return
                }
                const res = await OrderStore.dispatch('EXPORT_ORDERS', q)
                if (res.status === 200) {
                    if (q.format === 'xlsx') {
                        const a = document.createElement('a')
                        a.href = window.URL.createObjectURL(res.data)
                        a.download = `${self.filename()}.xlsx`
                        a.dispatchEvent(new MouseEvent('click'))
                    }
                    if (q.format === 'count') {
                        self.export_count = res.data.count
                    }
                }
                self.export_loading = false
                self.export_loaded = true
            }, 500)
        },
        filename: function () {
            let name = ''

            name += 'tapgiant-orders'
            name += ' - '
            name += MONTH_SHORT[this.picker_start_date.getMonth()]
            name += '/'
            name += this.picker_start_date.getFullYear()

            return name
        },
        toggle_location: function (id) {
            if (this.filters.locations.includes(id)) {
                this.filters.locations.splice(this.filters.locations.indexOf(id), 1)
            } else {
                this.filters.locations.push(id)
            }
        },
        quick_selection_m: function (month) {
            this.selection_month = month

            const start_date = new Date(this.selection_year, this.selection_month, 1, 0, 0, 1, 0)
            const end_date = new Date(this.selection_year, this.selection_month+1, 0, 23, 59, 0, 0)

            this.picker_start_date = start_date
            this.picker_end_date = end_date
            this.picker_start.setDate(start_date)
            this.picker_end.setDate(end_date)
        },
        quick_selection_y: function (year) {
            this.selection_year = year

            const start_date = new Date(this.selection_year, this.selection_month, 1, 0, 0, 1, 0)
            const end_date = new Date(this.selection_year, this.selection_month+1, 0, 23, 59, 0, 0)

            this.picker_start_date = start_date
            this.picker_end_date = end_date
            this.picker_start.setDate(start_date)
            this.picker_end.setDate(end_date)
        },
        toggle_picker (picker) {
            picker.isVisible() ? picker.hide() : picker.show()
        }
    }
}
</script>
