import { createStore } from 'vuex'
import Cookies from 'js-cookie'
import axios from 'axios'

import {
    USERS_PATH,
    USERS_PUBLIC_PATH,
    FILES_PATH,
    WWW_SUBSCRIPTIONS_PATH,
    COOKIE_TOKEN,
    COOKIE_SECURE,
    COOKIE_TOKEN_VALIDITY_S,
    COOKIE_LOCALE,
    COOKIE_LOCALE_VALIDITY_S,
    COOKIE_CONSENT_VALIDITY_S,
    DOMAIN
} from '@/common/constants'

export default function make_store (EventBus) {
    // let is_refreshing = false
    // let failed_queue = []

    window.plausible = window.plausible || function () {
        (window.plausible.q = window.plausible.q || []).push(arguments)
    }

    const store = createStore({
        state () {
            return {
                token: '',
                user: {},
                subscriptions: [],
                quota: {},
                feature: {},
                trials: [],
                organization: {},
                get_info_ts: 0,
                is_authorized: false,
                statistics: {},
                pricing: {},
                locale: Cookies.get(COOKIE_LOCALE) || 'en',
                settings: {}
            }
        },
        mutations: {
            TRACK_GOAL (state, value) {
                window.plausible(value.goal, value.props)
            },
            SET_LOCALE (state, value) {
                state.locale = value
                Cookies.set(COOKIE_LOCALE, value, {
                    expires: COOKIE_LOCALE_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            SET_COOKIE_CONSENT (state, value) {
                Cookies.set(value.cookie_name, value.cookie_value, {
                    expires: COOKIE_CONSENT_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            SET_STATISTICS (state, value) {
                state.statistics = value
            },
            SET_ORGANIZATION (state, value) {
                state.organization = value
            },
            SET_PRICING (state, value) {
                state.pricing = value
            },
            SET_GET_INFO_TS (state, value) {
                state.get_info_ts = value
            },
            INIT_TOKEN (state, value) {
                state.token = value
                state.is_authorized = true
                Cookies.set(COOKIE_TOKEN, value, {
                    expires: COOKIE_TOKEN_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            SET_SUBSCRIPTIONS (state, value) {
                state.subscriptions = value
            },
            SET_QUOTA (state, value) {
                state.quota = value
            },
            SET_FEATURE (state, value) {
                state.feature = value
            },
            SET_TRIALS (state, value) {
                state.trials = value
            },
            SET_USER (state, value) {
                state.user = value
            },
            UNSET_USER (state) {
                state.user = {}
            },
            SET_SETTINGS (state, value) {
                state.settings = value
            },
            UNSET_SETTINGS (state) {
                state.settings = {}
            },
            UNSET_SUBSCRIPTIONS (state) {
                state.subscription = []
            },
            UNSET_QUOTA (state) {
                state.quota = {}
            },
            UNSET_FEATURE (state) {
                state.feature = {}
            },
            UNSET_TRIALS (state) {
                state.trials = []
            },
            UNSET_ORGANIZATION (state) {
                state.organization = {}
            },
            UNSET_TOKEN (state) {
                state.token = ''
                state.is_authorized = false
                Cookies.remove(COOKIE_TOKEN, { path: '/', domain: DOMAIN })
            }
        },
        getters: {
        },
        actions: {
            async LOGIN (context, token) {
                const headers = {}
                let response = {}
                try {
                    response = await axios.post(`${USERS_PUBLIC_PATH}/login`, { token }, { headers })
                    context.commit('INIT_TOKEN', response.data.token)
                } catch (error) {
                    response = error.response
                }
                return response.data
            },
            async LOGOUT (context) {
                let response = {}
                try {
                    response = await axios.post(`${USERS_PATH}/logout`, {})
                    context.commit('UNSET_USER')
                    context.commit('UNSET_TOKEN')
                    context.commit('UNSET_SUBSCRIPTIONS')
                    context.commit('UNSET_ORGANIZATION')
                    context.commit('UNSET_TRIALS')
                    context.commit('UNSET_QUOTA')
                    context.commit('UNSET_FEATURE')
                    context.commit('UNSET_SETTINGS')
                } catch (error) {
                }
                return response.data
            },
            async GET_INFO (context) {
                if (!context.state.token) {
                    return { status: 8 }
                }
                let response = {}
                try {
                    response = await axios.get(USERS_PATH)
                    if (response.data.status === 0) {
                        context.commit('SET_GET_INFO_TS', Date.now())
                        context.commit('SET_USER', response.data.user)
                        context.commit('SET_SUBSCRIPTIONS', response.data.subscriptions)
                        context.commit('SET_QUOTA', response.data.quota)
                        context.commit('SET_FEATURE', response.data.feature)
                        context.commit('SET_ORGANIZATION', response.data.organization)
                        context.commit('SET_TRIALS', response.data.trials)
                        context.commit('SET_SETTINGS', response.data.settings)
                    } else {
                        context.commit('UNSET_USER')
                        context.commit('UNSET_SUBSCRIPTIONS')
                        context.commit('UNSET_TOKEN')
                        context.commit('UNSET_ORGANIZATION')
                        context.commit('UNSET_TRIALS')
                        context.commit('UNSET_QUOTA')
                        context.commit('UNSET_FEATURE')
                        context.commit('UNSET_SETTINGS')
                    }
                } catch (error) {
                    context.commit('UNSET_USER')
                    context.commit('UNSET_SUBSCRIPTIONS')
                    context.commit('UNSET_TOKEN')
                    context.commit('UNSET_ORGANIZATION')
                    context.commit('UNSET_TRIALS')
                    context.commit('UNSET_QUOTA')
                    context.commit('UNSET_FEATURE')
                    context.commit('UNSET_SETTINGS')
                    return error.response.data
                }
                return response.data
            },
            async CONTACT (context, data) {
                let result = {}
                try {
                    result = await axios.post(`${USERS_PUBLIC_PATH}/contact`, data)
                } catch (error) {
                    return error.response.data
                }
                return result.data
            },
            // async UPDATE_ACCOUNT (context, data) {
            //     let result = {}
            //     try {
            //         result = await axios.put(`${USERS_PATH}`, data)
            //         await context.dispatch('GET_INFO')
            //     } catch (error) {
            //         return error.response.data
            //     }
            //     return result.data
            // },
            async GET_PRICING (context) {
                let result
                try {
                    result = await axios.get(`${WWW_SUBSCRIPTIONS_PATH}/pricing`)
                    context.commit('SET_PRICING', result.data)
                } catch (error) {
                    return error.response.data
                }
                return result
            },
            async GDPR_EXPORT (context, data) {
                let result
                try {
                    result = await axios.get(`${USERS_PATH}/export`)
                } catch (error) {
                    return error.response
                }
                return result.data
            },
            async DELETE_FILE (context, data) {
                let response = {}
                try {
                    response = await axios.delete(`${FILES_PATH}/${data.file_id}`)
                    await context.dispatch('GET_INFO')
                } catch (error) {
                    return error.response.data
                }
                return response.data
            }
        }
    })

    // const process_queue = (error, token = null) => {
    //     failed_queue.forEach(prom => {
    //         if (error) {
    //             prom.reject(error)
    //         } else {
    //             prom.resolve(token)
    //         }
    //     })

    //     failed_queue = []
    // }

    // axios.defaults.baseURL = API

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        return new Promise((resolve, reject) => {
            if (config.url.endsWith(`${USERS_PATH}/lookup`) || config.url.endsWith(`${USERS_PATH}/login`)) {
                return resolve(config)
            }

            // account management jwt
            if (store.state.is_authorized && typeof store.state.token === 'string' && store.state.token.split('.').length === 3) {
                config.headers['x-token'] = store.state.token
            }

            resolve(config)
        })
    //     let interval = null
    //     return new Promise((resolve, reject) => {
    //         if (config.url.endsWith(`${USERS_PATH}/register`) || config.url.endsWith(`${USERS_PATH}/login`)) {
    //             return resolve(config)
    //         }
    //         if (store.state.is_authorized && typeof store.state.token === 'string' && store.state.token.split('.').length === 3) {
    //             config.headers['x-token'] = store.state.token
    //             resolve(config)
    //         } else {
    //             interval = setInterval(() => {
    //                 if (store.state.is_authorized && typeof store.state.token === 'string' && store.state.token.split('.').length === 3) {
    //                     clearInterval(interval)
    //                     config.headers['x-token'] = store.state.token
    //                     resolve(config)
    //                 }
    //             }, 200)
    //         }
    //     })
    }, function (error) {
        return Promise.reject(error)
    })

    // Add a response interceptor
    // axios.interceptors.response.use(function (response) {
    //     if (response.config.url.startsWith('api/v1/listings?') ||
    //         response.config.url.startsWith('api/v1/listings/favorites') ||
    //         response.config.url.startsWith('api/v1/users') ||
    //         response.config.url.startsWith('api/v1/searches')) {
    //         EventBus.$emit('PullLoader/disable')
    //     }
    //     return response
    // }, function (error) {
    // //     const original_request = error.config

    // //     // if (error.response.status === 401 && error.config.url.endsWith(TOKENS_PATH)) {
    //     // if (error.response.status === 401) {
    //     //     EventBus.$emit('App/refresh')
    //     // }

    //     if (error.response.status === 401 && !original_request._retry) {
    //         if (is_refreshing) {
    //             return new Promise(function (resolve, reject) {
    //                 failed_queue.push({ resolve, reject })
    //             }).then(token => {
    //                 original_request.headers['x-token'] = token
    //                 return axios(original_request)
    //             }).catch(err => {
    //                 return err
    //             })
    //         }

    //         original_request._retry = true
    //         is_refreshing = true
    //         return new Promise(function (resolve, reject) {
    //             store.dispatch('REGISTER', {}).then((res) => {
    //                 original_request.headers['x-token'] = res.token
    //                 process_queue(null, res.token)
    //                 resolve(axios(original_request))
    //             }).catch((err) => {
    //                 process_queue(err, null)
    //                 reject(err)
    //             }).then(() => {
    //                 is_refreshing = false
    //             })
    //         })
    //     }
    //     return Promise.reject(error)
    // })

    return store
}
