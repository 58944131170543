<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="patch-question" :size="20" />
                    <span class="align-middle">Help Desk</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded p-2 mb-3 d-flex justify-content-between align-items-center">
                    <div>You have {{paginator.count}} ticket(s)</div>
                    <button @click="request_create_ticket()" type="button" class="btn btn-sm btn-primary bg-gradient shadow-sm px-4">Create Ticket</button>
                </div>
            </div>
        </div>

        <!-- Tickets -->
        <div v-if="tickets.length > 0">
            <div class="bg-white rounded shadow-sm">
                <table class="table mb-0">
                    <thead>
                        <tr class="align-middle">
                            <td><p class="text-gray-600 m-0 text-center">Subject</p></td>
                            <td><p class="text-gray-600 m-0 text-center">Status</p></td>
                            <td><p class="text-gray-600 m-0 text-center">Updated at</p></td>
                            <td><p class="text-gray-600 m-0 text-center">Created at</p></td>
                            <td><p class="text-gray-600 m-0 text-center">Actions</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ticket, index) in tickets" :key="index" class="text-center">
                            <td class="align-middle"><p class="text-gray-700 m-0 text-start text-truncate" style="max-width: 300px">{{ticket.subject}}</p></td>
                            <td class="align-middle">
                                <span v-if="ticket.status === TICKET_STATUS_RESOLVED" class="badge bg-success">RESOLVED</span>
                                <span v-else-if="ticket.status === TICKET_STATUS_OPEN" class="badge bg-warning">OPEN</span>
                                <span v-else-if="ticket.status === TICKET_STATUS_PENDING_SUPPORT" class="badge bg-secondary">PENDING SUPPORT</span>
                                <span v-else-if="ticket.status === TICKET_STATUS_PENDING_CUSTOMER" class="badge bg-info">PENDING CUSTOMER</span>
                            </td>
                            <td class="align-middle"><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(ticket.updated_at)}}</p></td>
                            <td class="align-middle"><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(ticket.created_at)}}</p></td>
                            <td class="text-center align-middle">
                                <router-link :to="'/console/helpdesk/'+ticket.id" class="btn btn-sm btn-outline-secondary">View Ticket</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-4">
                <Paginator v-if="paginator.count>0" :tag="'tickets'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-1" />
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="You have no tickets created so far." />
            </div>
        </div>

        <ModalCreateTicket />
    </div>
</template>

<script>
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import Paginator from '@/common/directive/Paginator.vue'
import ModalCreateTicket from '@/common/modal/modal-create_ticket.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import TicketStore from '@/common/store/ticket.js'
import {
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RESOLVED,
    TICKET_STATUS_PENDING_SUPPORT,
    TICKET_STATUS_PENDING_CUSTOMER
} from '@/common/constants.js'
// import Tooltip from '@/common/directive/Tooltip'

export default {
    components: {
        Paginator,
        ModalCreateTicket,
        // Tooltip,
        EmptyResponse,
        BootstrapIcon
    },
    data: function () {
        return {
            paginate_timeout: 0,
            page: 1
        }
    },
    computed: {
        TICKET_STATUS_OPEN: () => TICKET_STATUS_OPEN,
        TICKET_STATUS_RESOLVED: () => TICKET_STATUS_RESOLVED,
        TICKET_STATUS_PENDING_SUPPORT: () => TICKET_STATUS_PENDING_SUPPORT,
        TICKET_STATUS_PENDING_CUSTOMER: () => TICKET_STATUS_PENDING_CUSTOMER,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        user: function () {
            return this.$store.state.user
        },
        tickets: function () {
            return TicketStore.state.tickets
        },
        paginator: () => TicketStore.state.paginator
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/tickets/set_page')
        this.$bus.$on('Paginator/tickets/set_page', (value) => {
            this.set_page(value)
        })

        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            this.page = page
            window.scroll({ top: 0 })
            await this.paginate()
        },
        request_create_ticket: function () {
            this.$bus.$emit('Modal/show/create-ticket')
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {
                    page: self.page
                }
                await TicketStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 50)
        }
    }
}
</script>
