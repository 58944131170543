<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-delete-location">
        <template v-slot:header>Confirm removal</template>
        <template v-slot:body>
            <p v-if="location">Workplace: <strong>{{location.name}}</strong></p>
            <p><span class="badge bg-danger">ATTENTION</span> The workplace will be permanently deleted and you will not be able to recover it. You will however be able to create a new workplace with the same name.</p>
            <p>The sessions and orders for this workplace will be removed.</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">Delete</button>
            <button type="button" class="btn px-4" @click="cancel()">Cancel</button>
        </template>
    </Modal>
</template>

<script>
import InventoryLocationStore from '@/common/store/inventory_location.js'

export default {
    props: {
        location: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            agree: false
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-delete-location')
        },
        submit: async function () {
            const response = await InventoryLocationStore.dispatch('DELETE_LOCATION', { location_id: this.location.id })
            if (response.status === 0) {
                this.agree = false
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The location has been deleted', level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-delete-location')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        }
    }
}
</script>
