<template>
    <div class="empty-response">
        <img alt="No data available" src="/img/svg/undraw_completed_tasks.svg" loading=lazy width="160" class="m-auto d-block">
        <p v-html="description"></p>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            default: ''
        }
    }
}
</script>
