<template>
    <span v-html="characters"></span>
</template>

<script>
export default {
    props: {
        search: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            characters: ''
        }
    },
    watch: {
        search: function () {
            this.replace()
        },
        value: function () {
            this.replace()
        }
    },
    created: function () {
        this.replace()
    },
    methods: {
        replace: function () {
            this.characters = this.value
            if (this.value && this.search.length > 0) {
                const esc = this.search.replace(/([-/\\^$*+?.()|[\]{}])/g, '\\$&')
                const reg = new RegExp(esc, 'ig')
                this.characters = this.value.replace(reg, '<span class="bg-yellow-200">$&</span>')
            }
        }
    }
}
</script>
