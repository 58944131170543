<template>
    <span ref="tooltip" data-toggle="tooltip" data-placement="top">
        <BootstrapIcon :color="color" :name="icon" :size="20" />
    </span>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'
import * as Tooltip from 'bootstrap/js/dist/tooltip'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'info-circle'
        },
        color: {
            type: String,
            default: 'text-gray-700'
        }
    },
    data: function () {
        return {
            handle: null
        }
    },
    watch: {
        value: function () {
            this.handle.dispose()
            this.handle = new Tooltip(this.$refs.tooltip, {
                title: this.value,
                html: true
            })
        }
    },
    mounted: function () {
        this.handle = new Tooltip(this.$refs.tooltip, {
            title: this.value,
            html: true
        })
    }
}
</script>
