<template>
    <div class="container">
        <img class="position-absolute top-0 end-0 w-75" src="/img/svg/nav-corner-2.svg" style="z-index: 0;">

        <div class="row mb-5 position-relative">
            <div class="col">
                <h1 class="text-center fw-bolder text-gradient-gray-900">{{$t('pricing.pricing_plans')}}</h1>
                <h2 class="text-center h5">{{$t('pricing.pricing_description')}}<br>{{$t('pricing.pricing_subtitle')}}</h2>
                <p v-if="is_authorized" class="text-center mb-0" v-html="$t('pricing.manage_from_subscription_page')"></p>
                <p v-if="is_authorized" class="text-center"><router-link to="/console/subscriptions">{{$t('pricing.my_subscriptions')}}</router-link></p>
            </div>
        </div>

        <div v-if="loaded && has_pricing" class="row position-relative">
            <div class="col pb-5">
                <div class="d-flex justify-content-center align-items-end">
                    <div @click="billing_interval = false" class="mx-2 fs-5 pe-pointer">{{$t('pricing.bill_monthly')}}</div>
                    <div class="form-check form-switch">
                        <input v-model="billing_interval" class="form-check-input form-check-input-lg me-0" type="checkbox" checked>
                    </div>
                    <div @click="billing_interval = true" class="mx-2 fs-5 text-center pe-pointer">
                        <span class="fw-bolder badge rounded-pill bg-red-400 bg-gradient shadow-sm"><small>{{$t('pricing.save_discount', { DISCOUNT: '15' })}}</small></span><br>
                        <span>{{$t('pricing.bill_annually')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loaded && has_pricing" class="row text-center">
            <div v-for="(plan, plan_id) in pricing.plans" :key="plan_id" class="col-12 col-md-4 mb-4">
                <div :class="{'border border-coral-500': plan.most_popular}" class="shadow-sm rounded bg-white py-4 h-100 position-relative">
                    <span class="position-absolute top-0 start-0 mt-3 ms-3">
                        <BootstrapIcon v-if="plan_id === STORE_PLAN_ID_ULTIMATE" class="me-2 align-middle" color="fill-teal-500 text-teal-500" name="x-diamond-fill" :size="22" />
                        <BootstrapIcon v-else-if="plan_id === STORE_PLAN_ID_PRO" class="me-2 align-middle" color="fill-coral-500 text-coral-500" name="x-diamond-fill" :size="22" />
                        <BootstrapIcon v-else-if="plan_id === STORE_PLAN_ID_BASIC" class="me-2 align-middle" color="fill-purple-500 text-purple-500" name="x-diamond-fill" :size="22" />
                    </span>
                    <span v-if="plan.most_popular" class="position-absolute translate-middle shadow-sm bg-gradient top-0 d-inline-block small badge rounded-pill bg-coral-500 text-white text-uppercase">{{$t('pricing.most_popular')}}</span>
                    <h3 class="text-gray-700 h4 mb-3">{{plan.name}}</h3>
                    <h4 class="text-blue-900 h3 mb-2">{{pricing.currency_symbol}}{{plan[billing_interval_price]}}</h4>
                    <p class="m-0 text-gray-600">{{$t('pricing.per_month')}}</p>
                    <p class="text-gray-600" v-if="billing_interval">{{$t('pricing.billed_annually')}}</p>
                    <div class="px-5">
                        <hr>
                    </div>
                    <p class="fw-bolder text-gray-700">{{$t('pricing.quotas')}}</p>
                    <p class="my-2 fs-6"><span class="fs-5 fw-bold">{{Math.floor(plan.quota.data_retention)}}</span> {{$t('pricing.days_data_retention')}}</p>
                    <p class="my-2 fs-6"><span class="fs-5 fw-bold">{{plan.quota.workplace_limit}}</span> {{$t('pricing.workplaces')}}</p>
                    <p class="my-2 fs-6"><span class="fs-5 fw-bold">{{plan.quota.member_limit}}</span> {{$t('pricing.members')}} *</p>
                    <p class="my-2 fs-6"><span class="fs-5 fw-bold">{{plan.quota.item_limit}}</span> {{$t('pricing.products')}}</p>
                    <div class="px-5">
                        <hr>
                    </div>
                    <p class="fw-bolder text-gray-700">{{$t('pricing.what_you_get')}}</p>
                    <div class="d-flex justify-content-center">
                        <div class="align-items-start">
                            <p class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.order_management')}}</span>
                            </p>
                            <p class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.store_provisioning')}}</span>
                            </p>
                            <p class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.customer_orders')}}</span>
                            </p>
                            <p class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.insights_dashboard')}}</span>
                            </p>
                            <p class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.gdpr_ccpa_compiant')}}</span>
                            </p>
                            <p v-if="plan.feature.balance_export" class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.balance_export')}}</span>
                            </p>
                            <p v-else class="my-1 fs-6">&nbsp;</p>
                            <p v-if="plan.feature.activity_timeline" class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.activity_timeline')}}</span>
                            </p>
                            <p v-else class="my-1 fs-6">&nbsp;</p>
                            <p v-if="plan.feature.audit_log" class="text-start my-1 fs-6">
                                <BootstrapIcon class="me-2 align-middle" color="fill-teal-600 text-teal-600" name="check-circle-fill" :size="16" />
                                <span class="align-middle">{{$t('pricing.audit_log')}}</span>
                            </p>
                            <p v-else class="my-1 fs-6">&nbsp;</p>
                        </div>
                    </div>
                    <div v-if="pricing.checkout_active === 1" class="mt-4">
                        <div v-if="is_authorized" class="mt-5 d-grid px-5">
                            <button v-if="pricing.gateway !== 'NONE'" @click="checkout(plan)" class="btn btn-dark btn-lg fs-6 px-5 py-3">{{$t('pricing.subscribe')}}</button>
                        </div>
                        <div v-else>
                            <router-link to="/login" class="btn btn-dark btn-lg fs-6 px-5 py-3">{{$t('pricing.try_for_free')}}</router-link>
                            <p class="m-0"><small>{{$t('pricing.no_credit_card_required')}}</small></p>
                            <p class="m-0"><small>{{$t('pricing.free_days_trial', { DAYS: 14 })}}</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loaded && !has_pricing" class="position-relative">
            <p class="text-center fs-5 bg-white rounded shadow-sm p-3">{{$t('pricing.country_not_supported')}}</p>
        </div>

        <div v-if="loaded && has_pricing" class="row">
            <div class="col">
                <div class="d-flex justify-content-between my-3">
                    <p v-if="loaded">* {{pricing.currency_symbol}}{{pricing.addons[STORE_PLAN_ID_MEMBER].price_month}}/month per additional member</p>
                    <p class="text-center">Looking for a bigger plan or custom solution? <router-link to="/contact">Contact us</router-link>.</p>
                </div>
            </div>
        </div>

        <div v-if="loaded && has_pricing" class="row">
            <div class="col-12 col-sm-6 text-center mt-5 text-gray-700">
                <h4 class="mb-4">Billing Questions</h4>
                <p class="fw-bolder">What payment methods do you accept?</p>
                <p>We accept Visa, Master Card, American Express, and PayPal.</p>
                <p class="fw-bolder">Will my credit card data be safe?</p>
                <p>We process payments using <a href="https://paddle.com" target="_blank">Paddle</a>, global <a href="https://paddle.com/use-cases/why-merchant-of-record" target="_blank">merchant of record</a>. Your credit card or PayPal information are never directly handled by or stored on Tapgiant servers.</p>
                <p>Paddle is PCI DSS SAQ A compliant, <a href="https://paddle.com/legal/gdpr" target="_blank">GDPR compliant</a> and <a href="https://paddle.com/legal/soc-2-compliance" target="_blank">SOC 2 compliant</a>.</p>
                <p class="fw-bolder">If I cancel my paid plan, do I get a refund?</p>
                <p>You can easily cancel your subscription at any time, but no refunds are provided for prorated periods. One exception to this is when you set up a paid subscription for the wrong account–see the next question.</p>
                <p class="fw-bolder">I upgraded the wrong account. What should I do?</p>
                <p>If you upgrade the wrong account by mistake (for example, your personal account instead of your company account), please contact us to issue a refund, so you can avoid getting charged twice.</p>
                <p class="fw-bolder">Will I receive invoices?</p>
                <p>Yes, each time Tapgiant charges your payment method, it will generate and email you an invoice. If you've specified your company details (name, address, VAT ID), they will appear on the invoices.</p>
            </div>
            <div class="col-12 col-sm-6 text-center mt-5 text-gray-700">
                <h4 class="mb-4">Premium Features</h4>
                <p class="fw-bolder">What if I reach my included members quota?</p>
                <p>All plans include a number of free members. Depending on the plan and on your needs, you may purchase additional member slots.</p>
                <p class="fw-bolder">What is Direct Customer Orders?</p>
                <p>Besides orchestrating the needs of your stores and shops, your customers can also place orders. You can store information about these orders using Tapgiant. Customer order includes: name, upfront payment, contact number, pickup date and others.</p>
                <p class="fw-bolder">Why do I need Balance export to Excel?</p>
                <p>If you provision your stores and shops solely through Tapgiant, you can download complete monthly provisions for your accountant. You'll know exactly what products have been provisioned in each of your stores and shops.</p>
                <p class="fw-bolder">Why do I need an Activity Timeline?</p>
                <p>Stop the back-and-forths between you and your employees. You can instantly view what orders have been placed, when and by who. You can also see if orders are prepared, delivered or still pending.</p>
            </div>
        </div>

        <ModalCheckoutCompleted :plan="checkout_data.plan" :product="checkout_data.product" />
    </div>
</template>

<script>
import {
    STORE_PLAN_ID_MEMBER,
    STORE_PLAN_ID_TRIAL,
    STORE_PLAN_ID_BASIC,
    STORE_PLAN_ID_PRO,
    STORE_PLAN_ID_ULTIMATE
} from '@/common/constants.js'

import BootstrapIcon from '@/common/directive/BootstrapIcon'
import ModalCheckoutCompleted from '@/common/modal/modal-checkout_completed.vue'

export default {
    components: {
        BootstrapIcon,
        ModalCheckoutCompleted
    },
    data: function () {
        return {
            STORE_PLAN_ID_MEMBER,
            STORE_PLAN_ID_TRIAL,
            STORE_PLAN_ID_BASIC,
            STORE_PLAN_ID_PRO,
            STORE_PLAN_ID_ULTIMATE,
            checkout_loading: false,
            loaded: false,
            billing_interval: true,
            checkout_data: {
                plan: {},
                product: {}
            },
            has_pricing: false
        }
    },
    computed: {
        email: function () {
            return this.$store.state.user.email
        },
        organization: function () {
            return this.$store.state.organization
        },
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        pricing: function () {
            return this.$store.state.pricing
        },
        subscriptions: function () {
            return this.$store.state.subscriptions
        },
        billing_interval_key: function () {
            return this.billing_interval ? 'year' : 'month'
        },
        billing_interval_price: function () {
            return this.billing_interval ? 'price_year' : 'price_month'
        }
    },
    created: async function () {
        await this.get_pricing()
    },
    methods: {
        get_pricing: async function () {
            const response = await this.$store.dispatch('GET_PRICING')
            this.loaded = true

            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.has_pricing = true
                }
            }
        },
        checkout: async function (plan) {
            if (this.pricing.gateway === 'PADDLE') {
                this.gateway_paddle(plan)
            } else if (this.pricing.gateway === 'PAYLIKE') {
                this.gateway_paylike(plan)
            }
        },
        gateway_paylike: function (plan) {
            // const self = this
            const paylike = window.Paylike({ key: this.pricing.vendor_id })
            const value = this.billing_interval === 'month' ? plan.price_month : plan.price_year * 12
            const currency = this.pricing.currency.toUpperCase()

            paylike.pay({
                test: this.pricing.environment === 'SANDBOX',
                locale: 'ro',
                // amount: { currency: 'EUR', exponent: 2, value: 1499 },
                custom: {
                    organization_id: this.organization.id
                },
                title: `${plan.name}`,
                description: '2x your favorite tool',
                text: 'TAPGIANT',
                fields: [
                    {
                        name: 'email',
                        label: 'E-mail', // same as `name` if not provided
                        type: 'email',
                        placeholder: 'user@example.com',
                        required: true,
                        value: this.email // provide a default value
                    }
                ],
                plan: [
                    {
                        amount: {
                            currency,
                            value,
                            exponent: 2
                        },
                        repeat: {
                            interval: {
                                unit: this.billing_interval_key
                            }
                        }
                    }
                ]
            }, () => {
            })
        },
        gateway_paddle: function (plan) {
            const self = this
            const q = {
                product: plan.product_id[this.billing_interval_key],
                successCallback: function (data) {
                    self.$store.commit('TRACK_GOAL', {
                        goal: `Subscribe-${plan.plan_id}`,
                        props: {
                            completed: data.checkout.completed,
                            cycle: self.billing_interval_key,
                            country: data.user.country
                        }
                    })
                    self.checkout_data = {
                        plan,
                        product: data.product,
                        checkout: data.checkout,
                        user: data.user
                    }
                    self.$bus.$emit('Modal/show/checkout-completed')
                },
                closeCallback: function (data) {
                    self.$store.commit('TRACK_GOAL', {
                        goal: `Subscribe-${plan.plan_id}`,
                        props: {
                            completed: data.checkout.completed,
                            cycle: self.billing_interval_key,
                            country: data.user.country
                        }
                    })
                }
            }
            if (this.is_authorized) {
                q.email = this.email
                q.passthrough = JSON.stringify({
                    organization_id: this.organization.id
                })
            }

            if (this.pricing.environment === 'SANDBOX') {
                window.Paddle.Environment.set('sandbox')
            }

            window.Paddle.Setup({
                vendor: this.pricing.vendor_id
                // eventCallback: function (data) {
                //     if (data.event === 'Checkout.PaymentComplete') {
                //         // Check to ensure the payment has not been flagged for manual fraud review
                //         if (!data.eventData.flagged) {
                //             var checkoutId = data.eventData.checkout.id
                //             window.Paddle.Order.details(checkoutId, function (data) {
                //                 // Order data, downloads, receipts etc... available within 'data' variable.
                //                 console.log(data)
                //             })
                //         } else {
                //             // Payment has not been fully processed at the moment, so order details cannot be retrieved
                //             console.log('Transaction pending review')
                //         }
                //     }
                // }
            })
            window.Paddle.Checkout.open(q)
        }
    }
}
</script>
