<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="truck" :size="20" />
                    <span class="align-middle">{{$t('console.products_report')}}</span>
                </h1>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col">
                <div class="px-3 py-3 bg-white shadow-sm rounded">
                    <p class="h5 m-0">{{$t('console.filter')}}</p>
                    <div class="mb-2 text-wrap">
                        <span class="small">{{$t('console.quick_selection')}}:</span>
                        <span v-for="(mo, index) in MONTH_SHORT" :key="index" @click="quick_selection_m(index)" class="text-blue-500 pe-pointer small mx-2">{{mo}}</span>
                        <span @click="quick_selection_y(2021)" class="text-blue-500 pe-pointer small mx-2">2021</span>
                        <span @click="quick_selection_y(2022)" class="text-blue-500 pe-pointer small mx-2">2022</span>
                        <span @click="quick_selection_y(2023)" class="text-blue-500 pe-pointer small mx-2">2023</span>
                        <span @click="quick_selection_y(2024)" class="text-blue-500 pe-pointer small mx-2">2024</span>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 my-1">
                            <div class="input-group">
                                <span class="input-group-text">{{$t('console.start_date')}}</span>
                                <input disabled id="datepicker-start" type="text" class="form-control">
                                <button id="datepicker-trigger-start" class="btn btn-outline-secondary" type="button">
                                    <BootstrapIcon class="d-inline-block float-end" color="fill-teal-500 text-teal-500" name="calendar3" :size="16" />
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 my-1">
                            <div class="input-group">
                                <span class="input-group-text">{{$t('console.end_date')}}</span>
                                <input disabled id="datepicker-end" type="text" class="form-control">
                                <button id="datepicker-trigger-end" @click="toggle_picker(picker_end)" class="btn btn-outline-secondary" type="button">
                                    <BootstrapIcon class="d-inline-block float-end" color="fill-teal-500 text-teal-500" name="calendar3" :size="16" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{$t('console.select_interval_field')}}</p>
                            <div class="form-check form-check-inline pt-2">
                                <input v-model="filters.date_field" :value="DATE_FIELD_CREATED_AT" checked class="form-check-input" type="radio" name="form_date_field" id="flexRadioDefault2">
                                <label class="form-check-label" for="flexRadioDefault2">{{$t('console.created_date')}}</label>
                            </div>
                            <div class="form-check form-check-inline pt-2">
                                <input v-model="filters.date_field" :value="DATE_FIELD_PICKUP_DATE" class="form-check-input" type="radio" name="form_date_field" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">{{$t('console.pickup_date')}}</label>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{$t('console.select_workplace')}}</p>
                            <div class="d-flex gap-3 flex-wrap flex-fill pt-2">
                                <div v-for="(name, id) in locations" :key="id" @click="toggle_location(id)" class="noselect py-2">
                                    <BootstrapIcon class="d-inline-block me-2" color="text-gray-600 fill-gray-600" :name="filters.locations.includes(id) ? 'check-square' : 'square'" :size="16" />
                                    <label class="align-middle">{{name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{$t('console.include_products_from_orders')}}</p>
                            <div class="d-flex gap-3 flex-wrap flex-fill pt-2">
                                <div @click="filters.status.order_pending = !filters.status.order_pending" class="noselect py-2">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-coral-500 text-coral-500" :name="filters.status.order_pending ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('console.order_status.pending')}}</label>
                                </div>
                                <div @click="filters.status.order_preparing = !filters.status.order_preparing" class="noselect py-2">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-teal-500 text-green-500" :name="filters.status.order_preparing ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('console.order_status.preparing')}}</label>
                                </div>
                                <div @click="filters.status.order_completed = !filters.status.order_completed" class="noselect py-2">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-cyan-500 text-cyan-500" :name="filters.status.order_completed ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('console.order_status.m_completed')}}</label>
                                </div>
                                <div @click="filters.status.order_delivered = !filters.status.order_delivered" class="noselect py-2">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-brown-500 text-brown-500" :name="filters.status.order_delivered ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('console.order_status.m_handed')}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="filters.locations.length === 0" class="empty-response">
            <img alt="Select workplace" src="/img/svg/undraw_select_workplace.svg" loading=lazy width="160" class="m-auto d-block">
            <p>{{$t('console.select_workplace_for_report')}}</p>
        </div>
        <div v-else-if="!has_order_status" class="empty-response">
            <img alt="Select workplace" src="/img/svg/undraw_scrum_board.svg" loading=lazy width="160" class="m-auto d-block">
            <p>{{$t('console.select_order_status_for_report')}}</p>
        </div>
        <div v-else-if="supplies_loaded && supplies.length > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3">
                    <button type="button" @click="download_xlsx()" class="btn btn-brand text-white bg-gradient mb-2">
                        <BootstrapIcon class="d-inline-block me-2" color="text-white fill-white" name="cloud-download" :size="16" />
                        <span class="align-middle">{{$t('console.download_excel')}}</span>
                    </button>
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr class="">
                                <th scope="col">
                                    <span class="align-middle">Name</span>
                                </th>
                                <th scope="col">Unit</th>
                                <th scope="col">Count</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Barcode</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700">
                            <tr v-for="(row, index) in supplies" :key="index" class="align-middle small">
                                <td>{{row.item.name}}</td>
                                <td>{{$t('units.unit_'+row.item.unit)}}</td>
                                <td>{{row.count}}</td>
                                <td>{{row.item.sku}}</td>
                                <td>{{row.item.barcode}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse :description="$t('console.no_orders_found_for_input_timeframe')" />
            </div>
        </div>
    </div>
</template>

<script>
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import * as Pikaday from 'pikaday'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import ItemStore from '@/common/store/inventory_item.js'
import LocationStore from '@/common/store/inventory_location.js'
import {
    MONTH_SHORT
} from '@/common/constants.js'

export default {
    components: {
        EmptyResponse,
        BootstrapIcon
    },
    data: function () {
        const start_date = new Date()
        const end_date = new Date()
        start_date.setDate(1)
        start_date.setHours(0)
        start_date.setMinutes(0)

        return {
            supplies: [],
            supplies_loaded: false,
            supplies_timeout: false,
            selection_year: (new Date()).getFullYear(),
            selection_month: (new Date()).getMonth(),
            picker_start: null,
            picker_end: null,
            picker_start_date: start_date,
            picker_end_date: end_date,
            filters: {
                locations: [],
                status: {
                    order_pending: false,
                    order_preparing: false,
                    order_completed: true,
                    order_delivered: true
                },
                date_field: 'created_at'
            },
            MONTH_SHORT,
            timeout: 0,
            STATUS_PENDING: 'order_pending',
            STATUS_PREPARING: 'order_preparing',
            STATUS_COMPLETED: 'order_completed',
            STATUS_DISCARDED: 'order_discarded',
            DATE_FIELD_CREATED_AT: 'created_at',
            DATE_FIELD_PICKUP_DATE: 'pickup_date'
        }
    },
    computed: {
        locations: function () {
            const locations = LocationStore.state.locations
            const set = {}

            locations.forEach(function (location) {
                set[location.id] = location.name
            })

            return set
        },
        has_order_status: function () {
            const self = this
            return Object.keys(this.filters.status).filter(function (key) {
                return self.filters.status[key]
            }).length > 0
        }
    },
    watch: {
        picker_start_date: async function () {
            this.supplies_loaded = false
            await this.get_supplies()
        },
        picker_end_date: async function () {
            this.supplies_loaded = false
            await this.get_supplies()
        },
        filters: {
            deep: true,
            handler: async function () {
                const self = this
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async function () {
                    self.supplies_loaded = false
                    await self.get_supplies()
                }, 200)
            }
        }
    },
    mounted: async function () {
        const self = this
        const date = new Date()
        const min_date = new Date(2021, 9, 0)
        const max_date = new Date(date.getFullYear(), date.getMonth()+3, 0)
        this.picker_start = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-start'),
            trigger: document.getElementById('datepicker-trigger-start'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_start_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_start_date = date
                self.picker_end.setMinDate(date)
            }
        })
        this.picker_end = new Pikaday({
            theme: 'tapgiant-theme',
            position: 'bottom right',
            field: document.getElementById('datepicker-end'),
            trigger: document.getElementById('datepicker-trigger-end'),
            minDate: min_date,
            maxDate: max_date,
            defaultDate: this.picker_end_date,
            setDefaultDate: true,
            onSelect: function (date) {
                self.picker_end_date = date
                self.picker_start.setMaxDate(date)
            }
        })
    },
    created: async function () {
        await LocationStore.dispatch('PAGINATE', {})
        this.filters.locations = Object.keys(this.locations)
    },
    methods: {
        toggle_location: function (id) {
            if (this.filters.locations.includes(id)) {
                this.filters.locations.splice(this.filters.locations.indexOf(id), 1)
            } else {
                this.filters.locations.push(id)
            }
        },
        filename: function () {
            let name = ''

            name += 'tapgiant-export'
            name += ' - '
            name += MONTH_SHORT[this.picker_start_date.getMonth()]
            name += '/'
            name += this.picker_start_date.getFullYear()

            return name
        },
        download_xlsx: async function () {
            await this.get_supplies('xlsx')
        },
        get_supplies: async function (format) {
            if (this.filters.locations.length === 0) {
                return
            }
            const self = this
            const q = {
                locations: this.filters.locations,
                date_field: this.filters.date_field,
                sy: this.picker_start_date.getFullYear(),
                sm: this.picker_start_date.getMonth(),
                sd: this.picker_start_date.getDate(),
                ey: this.picker_end_date.getFullYear(),
                em: this.picker_end_date.getMonth(),
                ed: this.picker_end_date.getDate(),
                format: format || 'json',
                status: Object.keys(this.filters.status).filter(function (key) {
                    return self.filters.status[key]
                })
            }
            clearTimeout(this.supplies_timeout)
            if (q.status.length === 0 || q.locations.length === 0) {
                return
            }
            this.supplies_timeout = setTimeout(async function () {
                const res = await ItemStore.dispatch('GET_SUPPLIES', q)
                if (res.status === 200) {
                    if (q.format === 'xlsx') {
                        const a = document.createElement('a')
                        a.href = window.URL.createObjectURL(res.data)
                        a.download = `${self.filename()}.xlsx`
                        a.dispatchEvent(new MouseEvent('click'))
                    }
                    if (q.format === 'json') {
                        self.supplies = res.data.supplies
                    }
                }
                self.supplies_loaded = true
            }, 500)
        },
        quick_selection_m: function (month) {
            this.selection_month = month

            const start_date = new Date(this.selection_year, this.selection_month, 1, 0, 0, 0, 0)
            const end_date = new Date(this.selection_year, this.selection_month+1, 0, 23, 59, 0, 0)

            this.picker_start_date = start_date
            this.picker_end_date = end_date
            this.picker_start.setDate(start_date)
            this.picker_end.setDate(end_date)
        },
        quick_selection_y: function (year) {
            this.selection_year = year

            const start_date = new Date(this.selection_year, this.selection_month, 1, 0, 0, 0, 0)
            const end_date = new Date(this.selection_year, this.selection_month+1, 0, 23, 59, 0, 0)

            this.picker_start_date = start_date
            this.picker_end_date = end_date
            this.picker_start.setDate(start_date)
            this.picker_end.setDate(end_date)
        },
        toggle_picker (picker) {
            picker.isVisible() ? picker.hide() : picker.show()
        }
    }
}
</script>
