<template>
    <div class="browser-frame bg-gray-200 rounded mt-5 mt-lg-0">
        <div class="browser-header">
            <div class="pe-0 pe-lg-5">
                <span class="browser-dot bg-red-200"></span>
                <span class="browser-dot bg-yellow-200"></span>
                <span class="browser-dot bg-green-200"></span>
            </div>
            <div class="flex-fill px-3 px-lg-5">
                <span class="browser-address">{{address}}</span>
            </div>
            <div class="ps-0 ps-lg-5">&nbsp;</div>
        </div>
        <div class="browser-content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        address: {
            type: String,
            default: ''
        }
    }
}
</script>
