import {
    LOCATIONS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        locations: [],
        paginator: {
            limit: 10,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_LOCATIONS (state, value) {
            state.locations = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async GET_ASSOC (context) {
            let result
            try {
                result = await axios.get(`${LOCATIONS_PATH}/assoc`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${LOCATIONS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_LOCATIONS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async CREATE (context, data) {
            let response = {}
            try {
                response = await axios.post(`${LOCATIONS_PATH}`, data)
                context.dispatch('PAGINATE', {})
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE (context, data) {
            let response = {}
            try {
                response = await axios.put(`${LOCATIONS_PATH}/${data.id}`, data.params)
                context.dispatch('PAGINATE', {})
            } catch (error) {
                return error.response
            }
            return response
        },
        async DELETE_LOCATION (context, data) {
            let response = {}
            try {
                response = await axios.delete(`${LOCATIONS_PATH}/${data.location_id}`)
                context.dispatch('PAGINATE', {})
            } catch (error) {
                return error.response.data
            }
            return response.data
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
