import {
    STATISTICS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        inventory_statistics: {},
        locations_statistics: {}
    },
    mutations: {
        SET_INVENTORY_STATISTICS (state, value) {
            state.inventory_statistics = value
        },
        SET_LOCATIONS_STATISTICS (state, value) {
            state.locations_statistics = value
        }
    },
    actions: {
        async GET_INVENTORY_STATISTICS (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${STATISTICS_PATH}?${qs}`)
                context.commit('SET_INVENTORY_STATISTICS', result.data)
            } catch (error) {
                return error.response
            }
        },
        async GET_LOCATIONS_STATISTICS (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${STATISTICS_PATH}/locations?${qs}`)
                context.commit('SET_LOCATIONS_STATISTICS', result.data)
            } catch (error) {
                return error.response
            }
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
