export default function (value) {
    if (value) {
        let h, m, s
        s = Math.floor(value / 1000)
        m = Math.floor(s / 60)
        s = s % 60
        h = Math.floor(m / 60)
        m = m % 60
        const d = Math.floor(h / 24)
        h = h % 24

        let res = ''

        if (d > 0) {
            res += `${d}D`
            if (h > 0) {
                res += `${h}H`
            }
        } else {
            if (h > 0) {
                res += `${h}H`
            }
            res += `${m}M`
        }

        return res
    }
    return ''
}
