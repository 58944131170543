<template>
    <span :id="id" class="cursor-pointer position-relative user-select-none text-blue-500 text-nowrap" @click="copy_to_clipboard()">
        <BootstrapIcon class="align-middle d-inline-block" color="fill-purple-500" :name="icon" :size="16" />
        <span v-if="text" class="ms-2 d-inline-block align-middle small">{{text}}</span>
    </span>
</template>

<script>
/**
 * Purpose of component is to show clipboard icon and allow users to easily copy value to clipboard
 * Usage: <Clipboard :value="lorem ipsum dolor" />
 *
 */
// import * as $ from 'jquery'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import * as Tooltip from 'bootstrap/js/dist/tooltip'
import uuidv4 from '@/common/mixin/uuidv4'

export default {
    mixins: [uuidv4],
    props: {
        value: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        }
    },
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            id: null,
            tooltip: null,
            icon: 'clipboard'
        }
    },
    created: function () {
        this.id = this.uuidv4()
    },
    mounted: function () {
        const self = this
        setTimeout(function () {
            if (document.getElementById(self.id)) {
                self.tooltip = new Tooltip(document.getElementById(self.id), {
                    title: 'Copy to Clipboard',
                    trigger: 'hover',
                    placement: 'top'
                })
            }
        }, 1000)
    },
    methods: {
        copy_to_clipboard: function () {
            const self = this
            this.icon = 'clipboard-check'

            const el = document.createElement('textarea')
            el.value = this.value
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            this.tooltip.dispose()
            this.tooltip = new Tooltip(document.getElementById(this.id), { title: 'Copied!', trigger: 'manual', placement: 'top' })
            this.tooltip.show()

            setTimeout(() => {
                self.icon = 'clipboard'
                self.tooltip.dispose()
                self.tooltip = new Tooltip(document.getElementById(this.id), { title: 'Copy to Clipboard', trigger: 'hover', placement: 'top' })
            }, 2500)
        }
    }
}
</script>