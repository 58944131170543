<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="bookmarks" :size="20" />
                    <span class="align-middle">Categories</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div>You have <span class="fs-5">{{paginator.count}}/</span>100 categories.</div>
                    <button v-if="paginator.count < 100 && (user.role === 'admin' || user.permissions.api.includes('POST_/v1/categories'))" @click="request_create_category()" type="button" class="btn btn-sm btn-primary bg-gradient shadow-sm px-4">Add category</button>
                </div>
            </div>
        </div>

        <div v-if="paginator.count > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr>
                                <th scope="col" class="border-0 pb-3">State</th>
                                <th scope="col" class="border-0 pb-3">Name</th>
                                <th scope="col" class="border-0 pb-3">Products</th>
                                <th scope="col" class="border-0 pb-3">Created (UTC)</th>
                                <th scope="col" class="border-0 pb-3 text-end">Actions</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>
                                    <div class="input-group" role="group">
                                        <input v-model="filter.name" type="text" class="form-control" placeholder="Filter by name">
                                        <button v-if="filter.name.length > 0" @click="filter.name = ''" type="button" class="btn btn-outline-secondary">X</button>
                                    </div>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700 border-top-0">
                            <tr v-for="(category, index) in categories" :key="index" class="align-middle">
                                <td class="text-center">
                                    <span v-if="category.state === CATEGORY_STATE_ACTIVE" class="badge rounded-pill bg-teal-500">{{$t('categories.active')}}</span>
                                    <span v-if="category.state === CATEGORY_STATE_INACTIVE" class="badge rounded-pill bg-gray-500">{{$t('categories.inactive')}}</span>
                                </td>
                                <td><HighlightText :search="filter.name" :value="category.name" /></td>
                                <td>{{category.items}}</td>
                                <td class="small text-nowrap">{{$filters.PrettyDate(category.created_at)}}</td>
                                <td class="text-nowrap text-end">
                                    <button v-if="user.role === 'admin' || user.permissions.api.includes('PUT_/v1/categories/:category_id')" type="button" @click="request_edit_category(category)" class="btn btn-sm btn-secondary bg-gradient shadow-sm mx-1">Edit</button>
                                    <button v-if="user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/categories/:category_id')" type="button" @click="request_confirm_delete_category(category)" class="btn btn-sm btn-danger bg-gradient shadow-sm mx-1">Delete</button>
                                    <button v-if="category.state === CATEGORY_STATE_ACTIVE && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/categories/:category_id'))" type="button" @click="toggle_state(category, CATEGORY_STATE_INACTIVE)" class="btn btn-sm btn-warning bg-gradient shadow-sm mx-1">{{$t('controls.disable')}}</button>
                                    <button v-if="category.state === CATEGORY_STATE_INACTIVE && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/categories/:category_id'))" type="button" @click="toggle_state(category, CATEGORY_STATE_ACTIVE)" class="btn btn-sm btn-success bg-gradient shadow-sm mx-1">{{$t('controls.enable')}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="No categories created so far. Use the create button." />
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'categories'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalManageStoreCategory :category="selected_category" />
        <ModalConfirmDeleteCategory :category="selected_category"  />
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryCategoryStore from '@/common/store/inventory_category.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import ModalManageStoreCategory from '@/common/modal/console/modal-manage_store_category.vue'
import ModalConfirmDeleteCategory from '@/common/modal/console/modal-confirm_delete_category.vue'
import HighlightText from '@/common/directive/HighlightText.vue'
import Paginator from '@/common/directive/Paginator.vue'
import {
    CATEGORY_STATE_ACTIVE,
    CATEGORY_STATE_INACTIVE
} from '@/common/constants.js'

export default {
    components: {
        // Tooltip,
        Paginator,
        HighlightText,
        BootstrapIcon,
        // ModalManageKey,
        // ModalConfirmDeleteKey,
        // BootstrapIcon,
        EmptyResponse,
        ModalManageStoreCategory,
        ModalConfirmDeleteCategory
    },
    data: function () {
        return {
            CATEGORY_STATE_ACTIVE,
            CATEGORY_STATE_INACTIVE,
            paginate_timeout: 0,
            selected_category: {},
            filter: {
                name: ''
            }
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        },
        categories: () => InventoryCategoryStore.state.categories,
        paginator: () => InventoryCategoryStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/categories/set_page')
        this.$bus.$on('Paginator/categories/set_page', (value) => {
            this.set_page(value)
        })
        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            InventoryCategoryStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.name && self.filter.name.length >= 1) {
                    query.name = self.filter.name
                }
                await InventoryCategoryStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 200)
        },
        request_create_category: function () {
            this.selected_category = {}
            this.$bus.$emit('Modal/show/manage-store-category')
        },
        request_edit_category: function (category) {
            this.selected_category = category
            this.$bus.$emit('Modal/show/manage-store-category')
        },
        request_confirm_delete_category: function (category) {
            this.selected_category = category
            this.$bus.$emit('Modal/show/confirm-delete-category')
        },
        toggle_state: async function (category, state) {
            const res = await InventoryCategoryStore.dispatch('UPDATE', {
                id: category.id,
                params: {
                    state
                }
            })

            if (res.status === 200) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been successfully applied!', level: 'success' })
                this.paginate()
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        }
    }
}
</script>
