<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="shop" :size="20" />
                    <span class="align-middle">Workplaces</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <span>You have <span class="fs-5">{{paginator.count}}/</span>{{quota.workplace_limit}} workplaces.</span>
                        <Tooltip class="ms-2" value="View complete quota details in the Subscriptions page." icon="info-circle" />
                    </div>
                    <button v-if="paginator.count < quota.workplace_limit && (user.role === 'admin' || user.permissions.api.includes('POST_/v1/locations'))" @click="request_create_location()" type="button" class="btn btn-sm btn-primary bg-gradient px-4">Add workplace</button>
                </div>
            </div>
        </div>
        <div v-if="paginator.count > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr>
                                <th scope="col" class="border-0 pb-3">Name</th>
                                <th scope="col" class="border-0 pb-3">Address</th>
                                <th scope="col" class="border-0 pb-3">Created</th>
                                <th scope="col" class="border-0 pb-3 text-end">Actions</th>
                            </tr>
                            <tr>
                                <th>
                                    <input v-model="filter.name" type="text" class="form-control" placeholder="Filter by name">
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700 border-top-0">
                            <tr v-for="(location, index) in locations" :key="index" class="align-middle">
                                <td><HighlightText :search="filter.name" :value="location.name" /></td>
                                <td>
                                    <p v-if="location.address" class="m-0">{{location.address}}</p>
                                    <p v-else class="m-0">-</p>
                                </td>
                                <td class="text-nowrap">{{$filters.PrettyDate(location.created_at)}}</td>
                                <td class="text-nowrap text-end">
                                    <button v-if="(user.role === 'admin' || user.permissions.api.includes('PUT_/v1/locations/:location_id'))" type="button" @click="request_edit_location(location)" class="btn btn-sm btn-secondary bg-gradient mx-1">Edit</button>
                                    <button v-if="(user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/locations/:location_id'))" type="button" @click="request_confirm_delete_location(location)" class="btn btn-sm btn-danger bg-gradient mx-1">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="No locations created so far. Use the create button." />
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'workplaces'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalManageStoreWorkplace :location="selected_location" />
        <ModalConfirmDeleteWorkplace :location="selected_location"  />
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import Tooltip from '@/common/directive/Tooltip'
import Paginator from '@/common/directive/Paginator.vue'
import InventoryLocationStore from '@/common/store/inventory_location.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import HighlightText from '@/common/directive/HighlightText.vue'
import ModalManageStoreWorkplace from '@/common/modal/console/modal-manage_store_workplace.vue'
import ModalConfirmDeleteWorkplace from '@/common/modal/console/modal-confirm_delete_workplace.vue'

export default {
    components: {
        Tooltip,
        Paginator,
        BootstrapIcon,
        HighlightText,
        // ModalManageKey,
        // ModalConfirmDeleteKey,
        // BootstrapIcon,
        EmptyResponse,
        ModalManageStoreWorkplace,
        ModalConfirmDeleteWorkplace
    },
    data: function () {
        return {
            paginate_timeout: 0,
            selected_location: {},
            filter: {
                name: ''
            }
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        },
        locations: () => InventoryLocationStore.state.locations,
        paginator: () => InventoryLocationStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        quota: function () {
            return this.$store.state.quota
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/workplaces/set_page')
        this.$bus.$on('Paginator/workplaces/set_page', (value) => {
            this.set_page(value)
        })
        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            InventoryLocationStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            // self.$bus.$emit('ListingLoader/show')
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.name && self.filter.name.length >= 1) {
                    query.name = self.filter.name
                }
                await InventoryLocationStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
                // self.$bus.$emit('ListingLoader/hide')
            }, 200)
        },
        request_create_location: function () {
            this.selected_location = {}
            this.$bus.$emit('Modal/show/manage-inventory-location')
        },
        request_edit_location: function (location) {
            this.selected_location = location
            this.$bus.$emit('Modal/show/manage-inventory-location')
        },
        request_confirm_delete_location: function (location) {
            this.selected_location = location
            this.$bus.$emit('Modal/show/confirm-delete-location')
        }
    }
}
</script>
