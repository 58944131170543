<template>
    <div class="container-fluid">
        <img class="position-absolute top-0 end-0 w-75" src="/img/svg/nav-corner-4.svg" style="z-index: 0">

        <div class="row mb-5 position-relative">
            <div class="col">
                <h4 class="fs-6 text-gray-600 text-center">SETUP AND START SUPPLYING YOUR SHOPS & STORES</h4>
                <h1 class="text-center fw-bolder text-gradient-green-700 mb-3">How it Works</h1>
                <h2 class="text-center h5">Open the administration Console and start the setup.<br>Follow the steps below and you'll be ready in no time.</h2>
                <div class="text-center pt-3">
                    <strong class="text-gray-700 text-nowrap py-1">Jump to:</strong>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#workplaces">Workplaces</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#categories">Categories</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#products">Products</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#team">Team Members</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#configuration">Configuration</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#workplace-login">Workplace Login</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#new-orders">New Orders</a>
                    <a class="mx-2 px-1 py-1 d-block d-sm-inline" href="/how_it_works#delivery">Delivery</a>
                </div>
            </div>
        </div>

        <!-- Workplaces -->
        <div class="container">
            <div class="row pt-5 position-relative">
                <div class="col-12 col-lg-6">
                    <div class="d-flex h-100 align-items-center justify-content-center">
                        <div>
                            <h2 id="workplaces">
                                <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="shop" :size="30" />
                                <span class="align-middle">Workplaces</span>
                            </h2>
                            <p class="fs-5 text-gray-600">Setup workplace.<br>This is the first step to supplying products.</p>
                            <p class="mt-4">1. Add new workplace by using the button as shown below:</p>
                            <span class="d-inline-block shadow-sm position-relative mb-4">
                                <BootstrapIcon class="position-absolute translate-middle top-0 end-0" color="fill-red-500 text-red-500" name="arrow-down" :size="35" />
                                <img class="rounded w-100" src="/img/how/workplaces-add.png" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="d-flex h-100 justify-content-center">
                        <div>
                            <p>2. Set the desired name for your workplace and the optional address, then hit Create.</p>
                            <span class="d-inline-block shadow-sm position-relative">
                                <BootstrapIcon class="mb-3 position-absolute translate-middle bottom-0 start-0" color="fill-red-500 text-red-500" name="arrow-return-right" :size="35" />
                                <img class="rounded w-100" style="max-height: 400px;" src="/img/how/workplaces-modal.png" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <p class="mt-5">3. Congratulations! You have created your first workplace. One step closer to placing orders.</p>
                    <span class="d-inline-block shadow-sm position-relative">
                        <img class="rounded w-100" style="max-height: 140px;" src="/img/how/workplaces-table.png" />
                    </span>
                </div>
            </div>
        </div>

        <!-- Categories -->
        <div class="row mt-5 py-5 position-relative bg-yellow-200">
            <div class="col">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="d-flex h-100 align-items-center justify-content-center">
                                <div>
                                    <h2 id="categories">
                                        <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="bookmarks" :size="30" />
                                        <span class="align-middle">Categories</span>
                                    </h2>
                                    <p class="fs-5 text-gray-600">Setup category.<br>Group your products and easily find them.</p>
                                    <p class="mt-4">1. Add new category by using the button as shown below:</p>
                                    <span class="d-inline-block shadow-sm position-relative mb-4">
                                        <BootstrapIcon class="position-absolute translate-middle top-0 end-0" color="fill-red-500 text-red-500" name="arrow-down" :size="35" />
                                        <img class="rounded w-100" src="/img/how/categories-add.png" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="d-flex h-100 justify-content-center">
                                <div>
                                    <p>2. Set the desired name for your category, then hit Create.</p>
                                    <span class="d-inline-block shadow-sm position-relative">
                                        <BootstrapIcon class="mb-3 position-absolute translate-middle bottom-0 start-0" color="fill-red-500 text-red-500" name="arrow-return-right" :size="35" />
                                        <img class="rounded w-100" src="/img/how/categories-modal.png" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <p class="mt-5">3. Congratulations! You have created a new category. The categories page also displays the number of products in each category.</p>
                            <span class="d-inline-block shadow-sm position-relative">
                                <img class="rounded w-100" src="/img/how/categories-table.png" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Products -->
        <div class="container">
            <div class="row mt-5 py-5 position-relative">
                <div class="col-12 col-lg-6">
                    <div class="d-flex h-100 align-items-center justify-content-center">
                        <div>
                            <h2 id="products">
                                <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="cart2" :size="30" />
                                <span class="align-middle">Products</span>
                            </h2>
                            <p class="fs-5 text-gray-600">Setup products.<br>This might take some time. Add all products that need to be supplied to your shops & stores.</p>
                            <p class="mt-4">1. Add new product by using the button as shown below:</p>
                            <span class="d-inline-block shadow-sm position-relative mb-4">
                                <BootstrapIcon class="position-absolute translate-middle top-0 end-0" color="fill-red-500 text-red-500" name="arrow-down" :size="35" />
                                <img class="rounded w-100" src="/img/how/products-add.png" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="d-flex h-100 justify-content-center">
                        <div>
                            <p>2. Set the desired name, SKU, Barcode, Unit, Category and other optional Fields for your product, then hit Create.</p>
                            <span class="d-inline-block shadow-sm position-relative">
                                <BootstrapIcon class="mb-3 position-absolute translate-middle bottom-0 start-0" color="fill-red-500 text-red-500" name="arrow-return-right" :size="35" />
                                <img class="rounded w-100" src="/img/how/products-modal.png" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <p class="mt-5">3. Some products might need additional details that your customer or sales member may attach.<br>Easily define these fields and flag them as required or optional.</p>
                    <span class="d-inline-block shadow-sm position-relative">
                        <img class="rounded w-100" src="/img/how/products-fields.png" />
                    </span>
                </div>
                <div class="col-12 text-center">
                    <p class="mt-5">4. Congratulations! You have created a new product. Continue adding all your products before going to the next step.</p>
                    <span class="d-inline-block shadow-sm position-relative">
                        <img class="rounded w-100" src="/img/how/products-table.png" />
                    </span>
                </div>
            </div>
        </div>

        <!-- Team Members -->
        <div class="row mt-5 py-5 position-relative bg-yellow-200">
            <div class="col">
                <div class="container">
                    <div class="row">
                        <div class="row mt-5 py-5 position-relative bg-yellow-200">
                            <div class="col-12 col-lg-6">
                                <div class="d-flex h-100 align-items-center justify-content-center">
                                    <div>
                                        <h2 id="team">
                                            <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="people" :size="30" />
                                            <span class="align-middle">Team Members</span>
                                        </h2>
                                        <p class="fs-5 text-gray-600">Create accounts for your team.<br>Sales team members will create orders. Delivery members will supply them.</p>
                                        <p class="mt-4">1. Add new member by using the button as shown below:</p>
                                        <span class="d-inline-block shadow-sm position-relative mb-4">
                                            <BootstrapIcon class="position-absolute translate-middle top-0 end-0" color="fill-red-500 text-red-500" name="arrow-down" :size="35" />
                                            <img class="rounded w-100" src="/img/how/members-add.png" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="d-flex h-100 justify-content-center">
                                    <div>
                                        <p>2. Set the username and assign their role, then hit Create.<br>Once you create the member, send them the username and PIN code.</p>
                                        <span class="d-inline-block shadow-sm position-relative">
                                            <BootstrapIcon class="mb-3 position-absolute translate-middle bottom-0 start-0" color="fill-red-500 text-red-500" name="arrow-return-right" :size="35" />
                                            <img class="rounded w-100" src="/img/how/members-modal.png" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <p class="mt-5">3. Congratulations! You have created an account for your team member. You may now add as many members as you need.</p>
                                <span class="d-inline-block shadow-sm position-relative">
                                    <img class="rounded w-100" src="/img/how/members-table.png" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Configuration -->
        <div class="container">
            <div class="row mt-5 py-5">
                <div class="col-12">
                    <div class="d-flex h-100 align-items-center justify-content-center">
                        <div>
                            <h2 id="configuration">
                                <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="gear" :size="30" />
                                <span class="align-middle">Store Configuration</span>
                            </h2>
                            <p class="fs-5 text-gray-600">Toggle direct customer orders, business information, and others.<br>Configure what data you need when placing orders.</p>
                            <p class="mt-4">Tapgiant Store enables you to accept direct customer orders.<br>You may request information such as: customer name, phone number, upfront payment, pickup date and time.</p>
                            <p class="mt-4">You can configure whether any of these details are optional or mandatory.</p>
                            <div class="row">
                                <div v-for="(item, index) in STORE_CONFIGURATION_OPTIONS" :key="index" class="col-12 col-lg-6">
                                    <div id="configuration-items" class="accordion">
                                        <div class="accordion-item border-0 mb-2">
                                            <h2 class="accordion-header" :id="'heading-'+index">
                                                <button class="accordion-button collapsed bg-gray-200 py-4" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+index" aria-expanded="true" :aria-controls="'#collapse-'+index">
                                                    <strong class="text-gray-600">{{item[0]}}</strong>
                                                </button>
                                            </h2>
                                            <div :id="'collapse-'+index" class="accordion-collapse collapse bg-gray-200" :aria-labelledby="'heading-'+index" data-bs-parent="#configuration-items">
                                                <div class="accordion-body">
                                                    <p v-html="item[1]" class="m-0"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Member Login -->
        <div class="row mt-5 py-5 position-relative bg-yellow-200">
            <div class="col">
                <div class="container">
                    <div class="row">
                        <div class="row mt-5 py-5 position-relative bg-yellow-200">
                            <div class="col-12 col-lg-6">
                                <div class="d-flex h-100 align-items-center justify-content-center">
                                    <div>
                                        <h2 id="workplace-login">
                                            <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="gear" :size="30" />
                                            <span class="align-middle">Member Login</span>
                                        </h2>
                                        <p class="fs-5 text-gray-600">At this point the configuration of your store(s) is finished! Cheers!</p>
                                        <p class="mt-4">You can now generate pin codes for your team members.</p>
                                        <p><span class="badge bg-danger">ATTENTION!</span> You need to login using a Tablet, Mobile or PC that is being used in the Workplace.</p>
                                        <p>1. Click the Navbar link <a class="btn btn-sm btn-outline-brand bg-gradient py-1 px-2 mx-2" title="Tapgiant Store" :href="'https://store.' + TLD">{{$t('navbar.order_management')}}</a> or manually enter <a :href="'https://store.' + TLD" _target="blank">https://store.{{TLD}}</a> in your browser.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="d-flex h-100 justify-content-center">
                                    <div>
                                        <p>2. Insert member username & PIN Code.</p>
                                        <span class="d-inline-block shadow-sm position-relative mb-4">
                                            <BootstrapIcon class="position-absolute translate-middle top-50 start-0" color="fill-red-500 text-red-500" name="arrow-return-right" :size="35" />
                                            <img class="rounded w-100" style="max-height: 400px;" src="/img/how/members-login.png" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- New Orders -->
        <div class="container">
            <div class="row mt-5 py-5 position-relative">
                <div class="col-12 col-lg-6">
                    <div class="d-flex h-100 align-items-center justify-content-center">
                        <div>
                            <h2 id="new-orders">
                                <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="gear" :size="30" />
                                <span class="align-middle">New Orders</span>
                            </h2>
                            <p class="fs-5 text-gray-600">Start placing orders! Depending on your Store Configuration, you can also accept orders from your customers.</p>
                            <p>Tapgiant Store enables you to:</p>
                            <ul>
                                <li>Add any number of products to an order</li>
                                <li>Add custom fields to any product</li>
                                <li>Require customer details such as: name, phone, upfront payment</li>
                                <li>View History of orders and print order receipt in A8 format</li>
                                <li>Add additional instructions to each order</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <p class="mt-4">1. Add new products in the cart, insert customer details if required, press submit!</p>
                    <span class="d-inline-block position-relative mb-4">
                        <img class="w-100" :src="'/img/bank/'+locale+'_tapgiant-tablet-requisition.png'" />
                    </span>
                    <p class="mt-4">You can also search by product name, filter products by category, check previous orders using the History tab.</p>
                </div>
            </div>
        </div>

        <!-- Delivery -->
        <div class="row mt-5 py-5 position-relative bg-yellow-200">
            <div class="col">
                <div class="container">
                    <div class="row">
                        <div class="row mt-5 py-5 position-relative bg-yellow-200">
                            <div class="col-12 col-lg-6">
                                <div class="d-flex h-100 align-items-center justify-content-center">
                                    <div>
                                        <h2 id="delivery">
                                            <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="gear" :size="30" />
                                            <span class="align-middle">Delivery</span>
                                        </h2>
                                        <p class="fs-5 text-gray-600">The Delivery Team Members can now start provisioning your Workplaces.</p>
                                        <p>1. Click the Navbar link <a class="btn btn-sm btn-outline-brand bg-gradient py-1 px-2 mx-2" title="Tapgiant Store" :href="'https://store.' + TLD">{{$t('navbar.order_management')}}</a> or manually enter <a :href="'https://store.' + TLD" _target="blank">https://store.{{TLD}}</a> in your browser.</p>
                                        <p>2. Login using your member username & PIN.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3">
                                <div class="d-flex h-100 justify-content-center">
                                    <div>
                                        <p>3. View all orders grouped by Workplace.</p>
                                        <span class="d-inline-block position-relative mb-4">
                                            <img class="rounded w-100" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-dashboard.png'" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3">
                                <div class="d-flex h-100 justify-content-center">
                                    <div>
                                        <p>4. View order details and process it.</p>
                                        <span class="d-inline-block position-relative mb-4">
                                            <img class="rounded w-100" :src="'/img/bank/'+locale+'_tapgiant-mobile-order-summary.png'" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <p class="mt-4">4. All the orders will show up in this page. Congratulations! Your order orchestration is now made simple.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import { STORE_CONFIGURATION_OPTIONS, TLD } from '@/common/constants.js'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            TLD,
            STORE_CONFIGURATION_OPTIONS
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    }
}
</script>
