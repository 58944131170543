<template>
    <div class="bg-white">
        <NavbarPublic v-if="!['account_login_step1', 'account_login_step2'].includes($route.name)" />
        <main class="overflow-hidden">
            <router-view />
        </main>
    </div>
</template>

<script>
import NavbarPublic from '@/components/NavbarPublic.vue'

export default {
    components: {
        NavbarPublic
    }
}
</script>
