<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-archive-item">
        <template v-slot:header>{{$t('products.confirm_archival')}}</template>
        <template v-slot:body>
            <p v-if="item">{{$t('products.product')}}: <strong>{{item.name}}</strong></p>
            <p class="text-red-500">{{$t('products.archival_description')}}</p>
            <p class="small mb-0">{{$t('products.archival_note')}}</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-danger bg-gradient px-4" @click="submit()">{{$t('controls.archive')}}</button>
            <button type="button" class="btn px-4" @click="cancel()">{{$t('controls.cancel')}}</button>
        </template>
    </Modal>
</template>

<script>
import InventoryItemStore from '@/common/store/inventory_item.js'

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            agree: false
        }
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-archive-item')
        },
        submit: async function () {
            const response = await InventoryItemStore.dispatch('DELETE_ITEM', { item_id: this.item.id })
            if (response.status === 0) {
                this.agree = false
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.operation_success'), msg: this.$t('products.the_product_has_been_archived'), level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-archive-item')
                this.$bus.$emit('Products/paginate')
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        }
    }
}
</script>
