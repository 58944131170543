<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="diagram-3" :size="20" />
                    <span class="align-middle">{{$t('menu.organization')}}</span>
                </h1>
            </div>
        </div>

        <!-- Users -->
        <div class="row mb-4">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr class="">
                                <th scope="col" class="border-0 pb-3">E-Mail</th>
                                <th scope="col" class="border-0 pb-3">{{$t('console.role')}}</th>
                                <th scope="col" class="border-0 pb-3">{{$t('console.added_on')}}</th>
                                <th scope="col" class="border-0 pb-3 text-end">{{$t('console.actions')}}</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700  border-top-0">
                            <tr v-for="(org_user, index) in users" :key="index" class="align-middle">
                                <td>
                                    <div class="fw-bolder">
                                        <div v-if="org_user.email">
                                            {{org_user.email}}
                                        </div>
                                        <div v-else>
                                            {{org_user.pemail}}
                                            <Tooltip class="d-inline-block ps-3" :value="$t('console.inactive_user')" icon="exclamation-triangle" color="text-yellow-500 fill-yellow-500" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{$t('console.role_'+org_user._pivot_role)}}
                                    </div>
                                </td>
                                <td>
                                    <div class="text-nowrap small">{{$filters.PrettyDate(org_user.created_at)}}</div>
                                </td>
                                <td class="text-nowrap text-end">
                                    <button v-if="org_user.email !== user.email && (user.role === 'admin' || user.permissions.api.includes('PUT_/v1/users/:user_id/permissions'))" type="button" @click="request_manage_user_enrollment(org_user)" class="btn btn-sm btn-secondary mx-1">
                                        {{$t('controls.permissions')}}
                                    </button>
                                    <button v-if="org_user.email !== user.email && (user.role === 'admin' || user.permissions.api.includes('POST_/v1/users/withdraw/:user_id'))" type="button" @click="request_confirm_withdraw_user(org_user)" class="btn btn-sm btn-danger mx-1">
                                        {{$t('controls.remove')}}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-end">
                        <button v-if="user.role === 'admin' || user.permissions.api.includes('POST_/v1/users/enroll')" @click="request_user_enrollment()" type="button" class="btn btn-sm btn-primary bg-gradient shadow-sm px-4">{{$t('console.enroll_user')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Billing -->
        <div v-if="user.role === 'admin' || user.permissions.api.includes('GET_/v1/organizations')" id="billing-section" class="row mb-4">
            <div class="col">
                <div class="bg-white rounded shadow-sm px-4 py-3">
                    <div class="mb-2">
                        <strong class="text-gray-600 d-inline-block">Billing Information</strong>
                        <div><small>Used for generating invoices & billing.</small></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="mb-3">
                                <label for="form-input-name" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control" id="form-input-name">
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mb-3">
                                <label for="form-input-address" class="form-label">Address</label>
                                <input v-model="form.address" type="text" class="form-control" id="form-input-address">
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mb-3">
                                <label for="form-input-zipcode" class="form-label">ZIP code</label>
                                <input v-model="form.zipcode" type="text" class="form-control" id="form-input-zipcode">
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mb-3">
                                <label for="form-input-phone" class="form-label">Phone Number</label>
                                <input v-model="form.phone" type="text" class="form-control" id="form-input-phone">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Country</label>
                                <select v-model="form.country" class="form-select">
                                    <option value="">Select</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BR">Brazil </option>
                                    <option value="CA">Canada</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="EE">Estonia</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="DE">Germany</option>
                                    <option value="GR">Greece</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="IN">India</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IT">Italy</option>
                                    <option value="JP">Japan</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MT">Malta</option>
                                    <option value="MX">Mexico </option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NO">Norway</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="RO">Romania</option>
                                    <option value="US">United States of America</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="ES">Spain</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="GB">United Kingdom</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-state" class="form-label">State</label>
                                <input v-model="form.state" type="text" class="form-control" id="form-input-state" placeholder="">
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-city" class="form-label">City</label>
                                <input v-model="form.city" type="text" class="form-control" id="form-input-city" placeholder="">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="my-2">
                        <strong class="text-gray-600 d-inline-block">Business Information</strong>
                        <div><small>Required for invoices & deducting VAT.</small></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-vat_number" class="form-label">VAT Number</label>
                                <input v-model="form.vat_number" type="text" class="form-control" id="form-input-vat_number">
                                <div v-if="error_code === 4012" class="text-red-500">
                                    <span>Invalid Bank Account provided.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-trade_registry" class="form-label">Trade Registry Number</label>
                                <input v-model="form.trade_registry" type="text" class="form-control" id="form-input-trade_registry">
                                <div v-if="error_code === 4013" class="text-red-500">
                                    <span>Invalid Bank Account provided.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="my-2">
                        <strong class="text-gray-600 d-inline-block">Bank Information</strong>
                        <div><small>Required for refunds and accounting. It will also be displayed in invoices.</small></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-bank_account" class="form-label">Bank Account</label>
                                <input v-model="form.bank_account" type="text" class="form-control" id="form-input-bank_account">
                                <div v-if="error_code === 4014" class="text-red-500">
                                    <span>Invalid Bank Account provided.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="form-input-bank_name" class="form-label">Bank Name</label>
                                <input v-model="form.bank_name" type="text" class="form-control" id="form-input-bank_name">
                                <div v-if="error_code === 4015" class="text-red-500">
                                    <span>Invalid Bank Name provided.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Bank Account Currency</label>
                                <select v-model="form.bank_currency" class="form-select">
                                    <option value="">-</option>
                                    <option value="EUR">EUR</option>
                                    <option value="USD">USD</option>
                                    <option value="RON">RON</option>
                                    <option value="GBP">GBP</option>
                                </select>
                                <div v-if="error_code === 4016" class="text-red-500">
                                    <span>Invalid currency provided.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end mt-4">
                        <button v-if="user.role === 'admin' || user.permissions.api.includes('PUT_/v1/organizations/billing')" @click="update_billing()" type="button" class="btn btn-outline-primary">{{$t('controls.update')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Invoices -->
        <div v-if="invoices.length > 0 && (user.role === 'admin' || user.permissions.api.includes('GET_/v1/invoices'))" class="row mb-4">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-4 py-3">
                    <div class="mb-2">
                        <strong class="text-gray-600 d-inline-block">{{$t('menu.invoices')}}</strong>
                    </div>
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr class="">
                                <th scope="col" class="border-0 pb-3">Purchase</th>
                                <th scope="col" class="border-0 pb-3">Order ID</th>
                                <th scope="col" class="border-0 pb-3">Date</th>
                                <th scope="col" class="border-0 pb-3">Amount</th>
                                <th scope="col" class="border-0 pb-3">Status</th>
                                <th scope="col" class="border-0 pb-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700  border-top-0">
                            <tr v-for="(invoice, index) in invoices" :key="index" class="align-middle">
                                <td>
                                    <BootstrapIcon v-if="invoice.tg_plan_id === STORE_PLAN_ID_ULTIMATE" class="me-3 align-middle" color="fill-teal-500 text-teal-500" name="x-diamond-fill" :size="20" />
                                    <BootstrapIcon v-else-if="invoice.tg_plan_id === STORE_PLAN_ID_PRO" class="me-3 align-middle" color="fill-coral-500 text-coral-500" name="x-diamond-fill" :size="20" />
                                    <BootstrapIcon v-else-if="invoice.tg_plan_id === STORE_PLAN_ID_BASIC" class="me-3 align-middle" color="fill-purple-500 text-purple-500" name="x-diamond-fill" :size="20" />
                                    <BootstrapIcon v-else-if="invoice.tg_plan_id === STORE_PLAN_ID_MEMBER" class="me-3 align-middle" color="fill-red-500 text-red-500" name="x-diamond-fill" :size="20" />
                                    <span class="align-middle fw-bolder">{{SUBSCRIPTION_PLAN_NAME[invoice.tg_plan_id]}}</span>
                                </td>
                                <td>{{invoice.order_id}}</td>
                                <td>{{$filters.PrettyDate(invoice.generate_at || invoice.created_at)}} UTC</td>
                                <td>{{invoice.currency}} {{invoice.amount}}</td>
                                <td>
                                    <span :class="{'bg-teal-500': ['completed', 'paid'].includes(invoice.status), 'bg-orange-400': invoice.status==='refunded', 'bg-brown-400': invoice.status==='partially_refunded', 'bg-red-400': ['disputed', 'unpaid'].includes(invoice.status)}" class="badge rounded-pill text-white fw-bolder">{{invoice.status}}</span>
                                </td>
                                <td>
                                    <a v-if="invoice.receipt_url" :href="invoice.receipt_url" target="_blank" class="btn btn-outline-primary py-1 px-3">View</a>
                                    <div v-else-if="business_data">
                                        <button v-if="user.role === 'admin' || user.permissions.api.includes('GET_/v1/download_invoice')" @click="download(invoice)" type="button" class="btn btn-outline-primary py-1 px-3">{{$t('controls.download')}}</button>
                                    </div>
                                    <div v-else>
                                        <a href="#billing-section" class="btn btn-outline-danger">Fill billing data</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="text-center pt-3">
                        <button @click="set_page(paginator.page-1)" :disabled="paginator.page === 1" type="button" class="btn btn-outline-secondary mx-2">{{$t('controls.previous_page')}}</button>
                        <button @click="set_page(paginator.page+1)" :disabled="paginator.count < 15" type="button" class="btn btn-outline-secondary mx-2">{{$t('controls.next_page')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="user.role === 'admin' || user.permissions.api.includes('GET_/v1/invoices')" class="row mb-4">
            <div class="col">
                <EmptyResponse description="You do not have any invoices. If you just made a purchase, please wait up to 10 minutes until your invoices will be available. If nothing shows up and you think something should appear here, contact us." />
            </div>
        </div>

        <!-- Logo Upload -->
        <!-- <div v-if="has_ultimate" class="row mb-4">
            <div class="col">
                <div class="bg-white rounded shadow-sm px-4 py-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="pe-5">
                            <strong class="text-gray-600">My Logos</strong>
                            <div>Assign logos to domains from <router-link to="/dashboard">API Keys</router-link> page.</div>
                            <div class="d-flex align-items-center mt-2">
                                <FileUpload v-if="organization.files.length < domain_limit" tag="organization_logo" text="Upload logo" />
                                <Tooltip class="d-inline-block px-3" :value="'JPEG or PNG image<br>Filesize <= '+IMAGE_MAX_FILESIZE/1000+'KB<br>1:1 Aspect Ratio<br>Side between 50px to 100px'" icon="info-circle" />
                            </div>
                        </div>
                        <div v-if="organization.files.length > 0" class="rounded overflow-hidden border">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr class="align-middle">
                                        <td><p class="text-gray-600 m-0 text-center">Logo</p></td>
                                        <td><p class="text-gray-600 m-0 text-center">Created at</p></td>
                                        <td><p class="text-gray-600 m-0 text-center">Actions</p></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(file, index) in organization.files" :key="index">
                                        <td class="align-middle"><p class="text-gray-700 m-0 text-center"><img :src="'data:image/jpeg;base64, ' + file.src" style="width: 50px; height: 50px;"></p></td>
                                        <td class="align-middle"><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(file.created_at)}}</p></td>
                                        <td class="text-center align-middle">
                                            <button @click="delete_file(file)" type="button" class="btn btn-sm btn-outline-danger">Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="align-middle h-100">No logos uploaded yet.</div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Delete Account -->
        <div v-if="user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/users')" class="row mb-3">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center bg-white rounded shadow-sm px-4 py-3 border border-danger">
                    <div>
                        <strong class="text-gray-600">Delete Organization</strong>
                        <div>Completely erase all data associated to your organization. This is a destructive action that cannot be reverted.</div>
                        <div v-if="removals.length > 0" class="mt-3">
                            <span class="bg-coral-500 text-white px-3 py-2 d-inline-block rounded shadow-sm">{{$t('console.scheduled_for_removal', { DATE: date_format(removals[0].remove_at) })}}</span>
                        </div>
                    </div>
                    <div v-if="removals.length === 0">
                        <button @click="request_confirm_delete_account()" type="button" class="btn btn-outline-danger">Delete</button>
                    </div>
                    <div v-else>
                        <button v-if="user.role === 'admin' || user.permissions.api.includes('POST_/v1/users/keep')" @click="keep_account()" type="button" class="btn btn-brand text-white">Cancel removal</button>
                    </div>
                </div>
            </div>
        </div>

        <ModalManageUserEnrollment :user="selected_user" />
        <ModalConfirmDeleteAccount />
        <ModalConfirmWithdrawUser :user="selected_user" />
    </div>
</template>

<script>
// import BootstrapIcon from '@/common/directive/BootstrapIcon'
// import Paginator from '@/common/directive/Paginator.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
// import FileUpload from '@/common/directive/FileUpload.vue'
import Tooltip from '@/common/directive/Tooltip.vue'
import UserStore from '@/common/store/user.js'
import OrganizationStore from '@/common/store/organization.js'
import ModalConfirmDeleteAccount from '@/common/modal/console/modal-confirm_delete_account.vue'
import ModalManageUserEnrollment from '@/common/modal/console/modal-manage_user_enrollment.vue'
import ModalConfirmWithdrawUser from '@/common/modal/console/modal-confirm_withdraw_user.vue'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import InvoiceStore from '@/common/store/invoice.js'
import {
    ORGANIZATION_PROPERTIES,
    BILLING_PROPERTIES,
    STORE_PLAN_ID_MEMBER,
    STORE_PLAN_ID_ULTIMATE,
    STORE_PLAN_ID_PRO,
    STORE_PLAN_ID_BASIC,
    SUBSCRIPTION_PLAN_NAME,
    BILLING_CYCLE
} from '@/common/constants.js'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        EmptyResponse,
        BootstrapIcon,
        // FileUpload,
        Tooltip,
        ModalConfirmWithdrawUser,
        ModalManageUserEnrollment,
        ModalConfirmDeleteAccount
        // Paginator
    },
    data: function () {
        return {
            STORE_PLAN_ID_MEMBER,
            STORE_PLAN_ID_ULTIMATE,
            STORE_PLAN_ID_PRO,
            STORE_PLAN_ID_BASIC,
            BILLING_CYCLE,
            SUBSCRIPTION_PLAN_NAME,
            selected_user: {},
            error_code: -1,
            form: {
                name: null,
                address: null,
                zipcode: null,
                state: null,
                city: null,
                country: null,
                phone: null,
                vat_number: null,
                trade_registry: null,
                bank_account: null,
                bank_name: null,
                bank_currency: null
            },
            removals: [],
            users: []
        }
    },
    computed: {
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        user: function () {
            return this.$store.state.user
        },
        locale: function () {
            return this.$i18n.locale
        },
        business_data: () => InvoiceStore.state.business_data,
        invoices: () => InvoiceStore.state.invoices,
        paginator: () => InvoiceStore.state.paginator
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        // this.$bus.$off('FileUpload/completed/organization_logo')
        // this.$bus.$on('FileUpload/completed/organization_logo', async (value) => {
        //     await this.$store.dispatch('GET_INFO')
        // })

        this.$bus.$off('Organization/info')
        this.$bus.$on('Organization/info', () => {
            this.get_organization_info()
        })

        await this.get_organization_info()
        await this.paginate()
    },
    methods: {
        get_organization_info: async function () {
            const response = await OrganizationStore.dispatch('INFO')
            if (response.status === 200) {
                this.removals = response.data.organization.removals
                this.users = response.data.users
                this.form.name = response.data.organization.name
                this.form.address = response.data.organization.address
                this.form.zipcode = response.data.organization.zipcode
                this.form.state = response.data.organization.state
                this.form.city = response.data.organization.city
                this.form.country = response.data.organization.country
                this.form.phone = response.data.organization.phone
                this.form.bank_account = response.data.organization.bank_account
                this.form.bank_name = response.data.organization.bank_name
                this.form.bank_currency = response.data.organization.bank_currency
                this.form.vat_number = response.data.organization.vat_number
                this.form.trade_registry = response.data.organization.trade_registry
            }
        },
        keep_account: async function () {
            const response = await UserStore.dispatch('KEEP_ACCOUNT')
            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.$bus.$emit('Notification/show', { title: 'Account Removal Canceled', msg: 'The scheduled account removal action has been canceled. The account will not be removed.', level: 'success' })
                    await this.$store.dispatch('GET_INFO')
                    await this.get_organization_info()
                }
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        date_format: function (date) {
            return DateFormat(new Date(date), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS }).toUpperCase()
        },
        request_confirm_delete_account: function () {
            this.$bus.$emit('Modal/show/confirm-delete-account')
        },
        request_confirm_withdraw_user: function (user) {
            this.selected_user = user
            this.$bus.$emit('Modal/show/confirm-withdraw-user')
        },
        request_user_enrollment: function () {
            this.selected_user = {}
            this.$bus.$emit('Modal/show/manage-user-enrollment')
        },
        request_manage_user_enrollment: function (user) {
            this.selected_user = user
            this.$bus.$emit('Modal/show/manage-user-enrollment')
        },
        delete_file: async function (file) {
            await this.$store.dispatch('DELETE_FILE', {
                file_id: file.id
            })
        },
        update_organization_settings: async function () {
            const payload = {}
            const self = this
            ORGANIZATION_PROPERTIES.forEach(function (key) {
                if (self.form[key] !== null) {
                    payload[key] = self.form[key]
                }
            })
            const response = await OrganizationStore.dispatch('ORGANIZATION_SETTINGS', payload)

            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Success', msg: 'Operation completed!', level: 'success' })
                await this.$store.dispatch('GET_INFO')
            } else if (response.status === 1) {
                this.$bus.$emit('Notification/show', { title: 'Nothing to update', msg: 'No changes were identified!', level: 'info' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Error', msg: 'Please retry again or refresh the page!', level: 'danger' })
            }
        },
        update_billing: async function () {
            const payload = {}
            const self = this
            BILLING_PROPERTIES.forEach(function (key) {
                if (self.form[key] !== null) {
                    payload[key] = self.form[key]
                }
            })
            const response = await OrganizationStore.dispatch('ORGANIZATION_BILLING', payload)

            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.$bus.$emit('Notification/show', { title: 'Success', msg: 'Operation completed!', level: 'success' })
                    await this.$store.dispatch('GET_INFO')
                } else if (response.data.status === 1) {
                    this.$bus.$emit('Notification/show', { title: 'Nothing to update', msg: 'No changes were identified!', level: 'info' })
                }
            } else if (response.status === 422) {
                this.error_code = response.data.data.code
                this.$bus.$emit('Notification/show', { title: 'Invalid values provided', msg: 'Please review the form and adjust the values.', level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us.', level: 'danger' })
            }
        },
        set_page: async function (page) {
            InvoiceStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                await InvoiceStore.dispatch('PAGINATE', {
                    page: self.paginator.page
                })
            }, 200)
        },
        download: async function (invoice) {
            const res = await InvoiceStore.dispatch('DOWNLOAD', {
                id: invoice.id
            })

            if (res.status === 200) {
                this.$bus.$emit('Notification/show', { title: 'Success', msg: 'Download will start soon!', level: 'success' })
                const a = document.createElement('a')
                a.href = window.URL.createObjectURL(res.data)
                a.download = invoice.filename
                a.dispatchEvent(new MouseEvent('click'))
            } else {
                this.$bus.$emit('Notification/show', { title: 'Error', msg: 'Please retry again or refresh the page!', level: 'danger' })
            }
        }
    }
}
</script>
