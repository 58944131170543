/**
 * This pretty doggo returns boolean representing if input is number
 *
 */
export default {
    methods: {
        is_numeric: function (str) {
            if (typeof str !== 'string' && typeof str !== 'number') {
                return false
            }
            return !isNaN(str) && !isNaN(parseFloat(str))
        }
    }
}
