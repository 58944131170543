<template>
    <div v-if="_setting && _setting.name" class="d-flex align-items-center bg-white rounded shadow-sm px-4 py-3">
        <div class="w-50">
            <div class="pe-5">
                <strong class="text-gray-600">{{$t('settings.' + setting.name + '.title')}}</strong>
                <p class="m-0">{{$t('settings.' + setting.name + '.description')}}</p>
            </div>
        </div>
        <!-- Toggle Inputs & Input Required or not -->
        <div v-if="typeof setting.data.display !== 'undefined'" class="w-50">
            <div class="form-check form-switch">
                <input :disabled="(user.role === 'custom' && !user.permissions.api.includes('PUT_/v1/settings/:setting_id'))" v-model="setting.data.display" class="form-check-input" type="checkbox" :id="'setting-display-'+setting.name">
                <label class="form-check-label pe-pointer noselect" :for="'setting-display-'+setting.name">{{setting.data.display ? $t('console.yes') : $t('console.no')}}</label>
            </div>
            <div v-if="typeof setting.data.required === 'boolean'" class="form-check form-switch mt-2">
                <input :disabled="!setting.data.display" v-model="setting.data.required" class="form-check-input" type="checkbox" :id="'setting-required-'+setting.name">
                <label class="form-check-label pe-pointer noselect" :for="'setting-required-'+setting.name">{{$t('console.mandatory_field_description')}}</label>
            </div>
        </div>
        <!-- Toggle Automatic Order Deletion -->
        <div v-else-if="typeof setting.data.active !== 'undefined' && typeof setting.data.expiry_d !== 'undefined'" class="w-50">
            <div class="form-check form-switch">
                <input v-model="setting.data.active" class="form-check-input" type="checkbox" :id="'setting-active-'+setting.name">
                <label class="form-check-label pe-pointer noselect" :for="'setting-active-'+setting.name">{{setting.data.active ? $t('console.yes') : $t('console.no')}}</label>
            </div>
            <div class="mt-2">
                <div class="dropdown">
                    <button :disabled="(user.role === 'custom' && !user.permissions.api.includes('PUT_/v1/settings/:setting_id'))" type="button" class="btn btn-outline-secondary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span v-if="setting.data.expiry_d === 5">{{$t('console.after_days', { DAYS: 5 })}}</span>
                        <span v-else-if="setting.data.expiry_d === 15">{{$t('console.after_days', { DAYS: 15 })}}</span>
                        <span v-else-if="setting.data.expiry_d === 30">{{$t('console.after_days', { DAYS: 30 })}}</span>
                        <span v-else-if="setting.data.expiry_d === 60">{{$t('console.after_days', { DAYS: 60 })}}</span>
                        <span v-else-if="setting.data.expiry_d === 90">{{$t('console.after_days', { DAYS: 90 })}}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-start">
                        <li v-if="setting.data.expiry_d !== 5" @click="setting.data.expiry_d = 5" class="p-2 pe-pointer">{{$t('console.after_days', { DAYS: 5 })}}</li>
                        <li v-if="setting.data.expiry_d !== 15" @click="setting.data.expiry_d = 15" class="p-2 pe-pointer">{{$t('console.after_days', { DAYS: 15 })}}</li>
                        <li v-if="setting.data.expiry_d !== 30" @click="setting.data.expiry_d = 30" class="p-2 pe-pointer">{{$t('console.after_days', { DAYS: 30 })}}</li>
                        <li v-if="setting.data.expiry_d !== 60" @click="setting.data.expiry_d = 60" class="p-2 pe-pointer">{{$t('console.after_days', { DAYS: 60 })}}</li>
                        <li v-if="setting.data.expiry_d !== 90" @click="setting.data.expiry_d = 90" class="p-2 pe-pointer">{{$t('console.after_days', { DAYS: 90 })}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Online Shop Activation + Limit of daily max orders -->
        <div v-else-if="typeof setting.data.active !== 'undefined' && typeof setting.data.limit_d !== 'undefined'" class="w-50">
            <div class="form-check form-switch">
                <input v-model="setting.data.active" class="form-check-input" type="checkbox" :id="'setting-active-'+setting.name">
                <label class="form-check-label pe-pointer noselect" :for="'setting-active-'+setting.name">{{setting.data.active ? $t('console.yes') : $t('console.no')}}</label>
            </div>
            <div class="mt-2">
                <div class="dropdown">
                    <button :disabled="(user.role === 'custom' && !user.permissions.api.includes('PUT_/v1/settings/:setting_id'))" type="button" class="btn btn-outline-secondary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span v-if="setting.data.limit_d === -1">{{$t('console.unlimited_orders')}}</span>
                        <span v-else-if="setting.data.limit_d === 10">{{$t('console.max_daily_orders', { LIMIT: 10 })}}</span>
                        <span v-else-if="setting.data.limit_d === 50">{{$t('console.max_daily_orders', { LIMIT: 50 })}}</span>
                        <span v-else-if="setting.data.limit_d === 100">{{$t('console.max_daily_orders', { LIMIT: 100 })}}</span>
                        <span v-else-if="setting.data.limit_d === 200">{{$t('console.max_daily_orders', { LIMIT: 200 })}}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-start">
                        <li v-if="setting.data.limit_d !== -1" @click="setting.data.limit_d = -1" class="p-2 pe-pointer">{{$t('console.unlimited_orders')}}</li>
                        <li v-if="setting.data.limit_d !== 10" @click="setting.data.limit_d = 10" class="p-2 pe-pointer">{{$t('console.max_daily_orders', { LIMIT: 10 })}}</li>
                        <li v-if="setting.data.limit_d !== 50" @click="setting.data.limit_d = 50" class="p-2 pe-pointer">{{$t('console.max_daily_orders', { LIMIT: 50 })}}</li>
                        <li v-if="setting.data.limit_d !== 100" @click="setting.data.limit_d = 100" class="p-2 pe-pointer">{{$t('console.max_daily_orders', { LIMIT: 100 })}}</li>
                        <li v-if="setting.data.limit_d !== 200" @click="setting.data.limit_d = 200" class="p-2 pe-pointer">{{$t('console.max_daily_orders', { LIMIT: 200 })}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingStore from '@/common/store/setting.js'

export default {
    props: {
        _setting: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            setting: null,
            timeout: null
        }
    },
    watch: {
        'setting.data.display': function (newv, oldv) {
            this.changed(newv, oldv)
        },
        'setting.data.required': function (newv, oldv) {
            this.changed(newv, oldv)
        },
        'setting.data.active': function (newv, oldv) {
            this.changed(newv, oldv)
        },
        'setting.data.expiry_d': function (newv, oldv) {
            this.changed(newv, oldv)
        },
        'setting.data.limit_d': function (newv, oldv) {
            this.changed(newv, oldv)
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        }
    },
    created: async function () {
        this.setting = this._setting
    },
    methods: {
        changed: function (newv, oldv) {
            const self = this
            if (this.setting && ['boolean', 'number'].includes(typeof oldv) && oldv !== newv) {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async function () {
                    if (self.setting.id) {
                        self.update()
                    } else {
                        self.create()
                    }
                }, 500)
            }
        },
        update: async function () {
            const q = {
                id: this.setting.id,
                params: {
                    data: this.setting.data
                }
            }

            const res = await SettingStore.dispatch('UPDATE', q)
            if (res.status === 200) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.operation_success'), msg: this.$t('notifications.operation_success_description'), level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        },
        create: async function () {
            const q = {
                params: {
                    scope: this.setting.scope,
                    name: this.setting.name,
                    data: this.setting.data
                }
            }

            const res = await SettingStore.dispatch('CREATE', q)

            if (res.status === 200) {
                this.setting.id = res.data.id
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.operation_success'), msg: this.$t('notifications.operation_success_description'), level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        }
    }
}
</script>
