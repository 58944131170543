<template>
    <div v-if="!loading" id="content">
        <router-view />
        <PageFooter />
    </div>
</template>

<script>
import PageFooter from '@/components/Footer.vue'
import Cookies from 'js-cookie'
import {
    TLD,
    COOKIE_TOKEN,
    REGEX_JWT,
    COOKIE_LOCALE
} from '@/common/constants'

export default {
    components: {
        PageFooter
    },
    data: function () {
        return {
            loading: true
        }
    },
    created: async function () {
        if (TLD.endsWith('ondigitalocean.app')) {
            document.body.innerHTML = 'oops!'
            return
        }

        // Init locale cookie with ro value
        const _locale = Cookies.get(COOKIE_LOCALE)
        if (!_locale && TLD.endsWith('.ro')) {
            this.$bus.$emit('App/locale', 'ro')
        }

        await this.$router.isReady()
        const fresh_authenticated = await this.init_master_token()

        // redirect to console
        if (fresh_authenticated) {
            this.$router.replace('/console/store')

        // attempt to read token cookie
        } else {
            const _token = Cookies.get(COOKIE_TOKEN)
            if (_token) {
                await this.$store.commit('INIT_TOKEN', _token)
            }
        }

        await this.$store.dispatch('GET_INFO')
        await this.loaded()
    },
    methods: {
        init_master_token: async function () {
            const _master_token = this.$route.query._mt
            if (_master_token) {
                const query = Object.assign({}, this.$route.query)
                delete query._mt
                this.$router.replace({ query })
            }
            if (REGEX_JWT.test(_master_token)) {
                const response = await this.$store.dispatch('LOGIN', _master_token)
                if (typeof response.error_code === 'number' && response.error_code === 40001) {
                    return false
                }
                return true
            }
            return false
        },
        loaded: async function () {
            this.loading = false
            setTimeout(function () {
                document.body.classList.toggle('loading')
                document.getElementById('app-loader').remove()
            }, 500)
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/style.scss';
</style>