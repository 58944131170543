<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="speedometer2" :size="20" />
                    <span class="align-middle">Subscriptions</span>
                </h1>
                <h2 class="h6">Manage subscriptions settings, payments and quota usage.</h2>
                <p>All plans are available on the <router-link to="/pricing">Pricing</router-link> page.</p>
            </div>
        </div>

        <!-- Members -->
        <div v-if="has_pricing && pricing.checkout_active === 1 && subscriptions.length > 0 && pricing.addons.store_member && pricing.addons.store_member.plan_id">
            <div class="mb-3 bg-white rounded border border-dark shadow-sm py-2 px-3 d-flex align-items-center">
                <p class="mb-0 flex-fill">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-900 text-gray-900" name="people" :size="22" />
                    <span class="align-middle">Increase member cap by purchasing additional member seat ({{pricing.currency_symbol}}{{pricing.addons.store_member.price_month}}/month per additional member).</span>
                </p>
                <div class="d-flex justify-content-center align-items-end px-2">
                    <div @click="billing_interval = false" class="mx-2 pe-pointer">Bill monthly</div>
                    <div class="form-check form-switch">
                        <input v-model="billing_interval" class="form-check-input form-check-input-lg me-0" type="checkbox" checked>
                    </div>
                    <div @click="billing_interval = true" class="mx-2 fw-bold text-center pe-pointer">
                        <span>Bill annually</span>
                    </div>
                </div>
                <button v-if="pricing.gateway !== 'NONE'" @click="checkout(pricing.addons.store_member)" type="button" class="btn btn-dark bg-gradient">+ MEMBER SEAT</button>
            </div>
        </div>

        <!-- Trials -->
        <div v-if="trials.length > 0">
            <div v-for="(plan_id, index) in trials" :key="index" class="mb-3 bg-white rounded border border-yellow-600 shadow-sm py-2 px-3 d-flex justify-content-between align-items-center">
                <p class="mb-0">
                    <BootstrapIcon class="me-3 align-middle" color="fill-yellow-600 text-yellow-600" name="x-diamond-fill" :size="22" />
                    <span class="align-middle">You are eligible for a 14-day subscription: <strong>{{SUBSCRIPTION_PLAN_NAME[plan_id]}}</strong></span>
                </p>
                <button @click="activate_trial(plan_id)" type="button" class="btn btn-dark bg-gradient">ACTIVATE NOW</button>
            </div>
        </div>

        <!-- Subscriptions -->
        <div v-if="subscriptions.length > 0">
            <div v-for="(subscription, index) in subscriptions" :key="index" class="row mb-4">
                <div class="col">
                    <div class="bg-white rounded shadow-sm px-4 py-4">
                        <div class="mb-3 d-flex justify-content-between">
                            <div>
                                <BootstrapIcon v-if="subscription.plan_id === STORE_PLAN_ID_TRIAL" class="me-3 align-middle" color="fill-yellow-600 text-yellow-600" name="x-diamond-fill" :size="22" />
                                <BootstrapIcon v-else-if="subscription.plan_id === STORE_PLAN_ID_ULTIMATE" class="me-3 align-middle" color="fill-teal-500 text-teal-500" name="x-diamond-fill" :size="22" />
                                <BootstrapIcon v-else-if="subscription.plan_id === STORE_PLAN_ID_PRO" class="me-3 align-middle" color="fill-coral-500 text-coral-500" name="x-diamond-fill" :size="22" />
                                <BootstrapIcon v-else-if="subscription.plan_id === STORE_PLAN_ID_BASIC" class="me-3 align-middle" color="fill-purple-500 text-purple-500" name="x-diamond-fill" :size="22" />
                                <BootstrapIcon v-else-if="subscription.plan_id === STORE_PLAN_ID_MEMBER" class="me-3 align-middle" color="fill-red-500 text-red-500" name="x-diamond-fill" :size="22" />
                                <strong class="text-gray-600 d-inline-block align-middle">{{SUBSCRIPTION_PLAN_NAME[subscription.plan_id]}}</strong>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="d-flex flex-wrap gap-4">
                                    <div class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">ID</p>
                                        <span class="badge bg-gray-600 bg-gradient">{{subscription.vendor_subscription_id || subscription.id.split('-')[0]}}</span>
                                    </div>
                                    <div class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">Status</p>
                                        <span :class="{'bg-teal-500': subscription.status === PLAN_ACTIVE, 'bg-gray-500': subscription.status === PLAN_INACTIVE, 'bg-orange-500': subscription.status === PLAN_CANCELED, 'bg-red-500': subscription.status === PLAN_PASTDUE}" class="badge bg-gradient">{{PLAN_STATUS[subscription.status]}}</span>
                                    </div>
                                    <div class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">Billing Cycle</p>
                                        <span class="text-gray-600">{{BILLING_CYCLE[subscription.billing_cycle]}}</span>
                                    </div>
                                    <div v-if="subscription.plan_id !== STORE_PLAN_ID_TRIAL" class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">Auto Renewal</p>
                                        <span v-if="subscription.auto_renewal" class="badge bg-teal-500">ENABLED</span>
                                        <span v-else class="badge bg-red-500">DISABLED</span>
                                    </div>
                                    <div class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <p class="m-0 text-gray-500">Started On</p>
                                                <span class="text-gray-600">UTC {{$filters.PrettyDate(subscription.created_at)}}</span>
                                            </div>
                                            <div class="px-4">
                                                <BootstrapIcon color="text-gray-700" name="arrow-right" :size="18" />
                                            </div>
                                            <div>
                                                <p v-if="(subscription.cancel_at && subscription.canceled_at) || subscription.billing_cycle === BILLING_CYCLE_NA" class="m-0 text-gray-500">Ends On</p>
                                                <p v-else class="m-0 text-gray-500">Renew On</p>
                                                <span v-if="subscription.reset_at" class="text-gray-600">UTC {{$filters.PrettyDate(subscription.reset_at)}}</span>
                                                <span v-else-if="subscription.cancel_at" class="text-gray-600">UTC {{$filters.PrettyDate(subscription.cancel_at)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="subscription.canceled_at && subscription.plan_id !== STORE_PLAN_ID_TRIAL" class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">Canceled At</p>
                                        <span class="text-gray-600">UTC {{$filters.PrettyDate(subscription.canceled_at)}}</span>
                                    </div>
                                    <div v-if="subscription.paused_at" class="bg-gradient bg-gray-100 border px-3 rounded py-2">
                                        <p class="m-0 text-gray-500">Paused At</p>
                                        <span class="text-gray-600">UTC {{$filters.PrettyDate(subscription.paused_at)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="subscription.data.quota.data_retention" class="col-12 mt-2 mt-xl-0 col-md-6 col-lg-4 col-xl-auto">
                                <div class="bg-gray-200 border border-gray-300 bg-gradient rounded px-3 py-2">
                                    <p class="m-0 text-gray-600">Data retention <Tooltip class="float-end ms-3" :value="'Total number of months for which your order and activity history will be stored.'" icon="info-circle" /></p>
                                    <p v-if="subscription.data.quota.data_retention < 30" class="text-blue-800 m-0 fs-5">{{subscription.data.quota.data_retention}}-Day<!-- <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span>--></p>
                                    <p v-else class="text-blue-800 m-0 fs-5">{{subscription.data.quota.data_retention/30}}-Month<!-- <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span>--></p>
                                    <!-- <p class="text-gray-600 m-0">{{$filters.NumberShortener(subscription.requests_limit)}} reserved</p> -->
                                </div>
                            </div>
                            <div v-if="subscription.data.quota.workplace_limit" class="col-12 mt-2 mt-xl-0 col-md-6 col-lg-4 col-xl-auto">
                                <div class="bg-gray-200 border border-gray-300 bg-gradient rounded px-3 py-2">
                                    <p class="m-0 text-gray-600">Workplaces <Tooltip class="float-end ms-3" :value="'Limit of the total workplaces provisioned by this account.'" icon="info-circle" /></p>
                                    <p class="text-blue-800 m-0 fs-5">{{subscription.data.quota.workplace_limit}}<!-- <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span>--></p>
                                    <!-- <p class="text-gray-600 m-0">{{$filters.NumberShortener(subscription.requests_limit)}} reserved</p> -->
                                </div>
                            </div>
                            <div v-if="subscription.data.quota.item_limit" class="col-12 mt-2 mt-xl-0 col-md-6 col-lg-4 col-xl-auto">
                                <div class="bg-gray-200 border border-gray-300 bg-gradient rounded px-3 py-2">
                                    <p class="m-0 text-gray-600">Products <Tooltip class="float-end ms-3" :value="'Product cap for your account.'" icon="info-circle" /></p>
                                    <p class="text-blue-800 m-0 fs-5">{{subscription.data.quota.item_limit}}<!-- <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span>--></p>
                                    <!-- <p class="text-gray-600 m-0">{{$filters.NumberShortener(subscription.requests_limit)}} reserved</p> -->
                                </div>
                            </div>
                            <div v-if="subscription.data.quota.member_limit" class="col-12 mt-2 mt-xl-0 col-md-6 col-lg-4 col-xl-auto">
                                <div class="bg-gray-200 border border-gray-300 bg-gradient rounded px-3 py-2">
                                    <p class="m-0 text-gray-600">Members included <Tooltip class="float-end ms-3" :value="'Total active members included for your account.'" icon="info-circle" /></p>
                                    <p class="text-blue-800 m-0 fs-5">{{subscription.data.quota.member_limit}}<!-- <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span>--></p>
                                    <!-- <p class="text-gray-600 m-0">{{$filters.NumberShortener(subscription.requests_limit)}} reserved</p> -->
                                </div>
                            </div>
                            <!-- <div class="col-4">
                                <div class="bg-gray-100 border border-gray-200 rounded px-3 py-2">
                                    <p class="m-0 text-gray-500">Domains <Tooltip class="float-end" :value="'Total number of domains on which you can use the CAPTCHA.'" icon="info-circle" /></p>
                                    <p class="text-gray-600 m-0 fs-5">-</p>
                                    <p class="text-gray-600 m-0">{{subscription.domain_limit}} reserved</p>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-gray-100 border border-gray-200 rounded px-3 py-2">
                                    <p class="m-0 text-gray-500">Spike Protection <Tooltip class="float-end" :value="'Total number of CAPTCHAs displayed in your sites in a small  timeframe but which are not counted against your Requests Usage.'" icon="info-circle" /></p>
                                    <p class="text-gray-600 m-0 fs-5">{{subscription.generate_total_count}}</p>
                                    <span class="badge bg-teal-500">Enabled</span>
                                </div>
                            </div> -->
                        </div>
                        <div v-if="subscription.vendor_data.update_url || subscription.vendor_data.cancel_url" class="row mt-4">
                            <div class="col">
                                <a v-if="subscription.vendor_data.update_url" :href="subscription.vendor_data.update_url" target="_blank" class="btn btn-sm text-decoration-none btn-outline-primary me-3">Change Payment Information</a>
                                <a v-if="subscription.vendor_data.cancel_url" :href="subscription.vendor_data.cancel_url" target="_blank" class="btn btn-sm text-decoration-none btn-outline-danger">Cancel Subscription</a>
                                <!-- <div v-if="subscription.vendor_data && subscription.vendor_data.receipts && subscription.vendor_data.receipts.length > 0">
                                    <a v-for="(receipt, index) in subscription.vendor_data.receipts" :key="index" target="_blank" :href="receipt.receipt_url" class="btn btn-outline-primary">Receipt - {{$filters.PrettyDate(receipt.date)}}</a>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-4">
                                <div class="bg-gray-100 border border-gray-200 rounded px-3 py-2">
                                    <p class="m-0 text-gray-500">Requests Usage <Tooltip class="float-end" :value="'Total number of CAPTCHAs displayed in your site plus total number of server validations'" icon="info-circle" /></p>
                                    <p class="text-gray-600 m-0 fs-5">{{$filters.NumberFormatter(subscription.requests_count)}} <span class="fs-5 float-end">{{(subscription.requests_count/subscription.requests_limit*100).toFixed(2)}}%</span></p>
                                    <p class="text-gray-600 m-0">{{$filters.NumberShortener(subscription.requests_limit)}} reserved</p>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-gray-100 border border-gray-200 rounded px-3 py-2">
                                    <p class="m-0 text-gray-500">Domains <Tooltip class="float-end" :value="'Total number of domains on which you can use the CAPTCHA.'" icon="info-circle" /></p>
                                    <p class="text-gray-600 m-0 fs-5">-</p>
                                    <p class="text-gray-600 m-0">{{subscription.domain_limit}} reserved</p>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-gray-100 border border-gray-200 rounded px-3 py-2">
                                    <p class="m-0 text-gray-500">Spike Protection <Tooltip class="float-end" :value="'Total number of CAPTCHAs displayed in your sites in a small  timeframe but which are not counted against your Requests Usage.'" icon="info-circle" /></p>
                                    <p class="text-gray-600 m-0 fs-5">{{subscription.generate_total_count}}</p>
                                    <span class="badge bg-teal-500">Enabled</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="You do not have any subscription plans active." />
            </div>
        </div>
    </div>
</template>

<script>
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import SubscriptionStore from '@/common/store/subscription.js'
import {
    PLAN_ACTIVE,
    PLAN_INACTIVE,
    PLAN_CANCELED,
    PLAN_STATUS,
    PLAN_PASTDUE,
    SUBSCRIPTION_PLAN_NAME,
    BILLING_CYCLE,
    BILLING_CYCLE_NA,
    BILLING_CYCLE_MONTHLY,
    BILLING_CYCLE_YEARLY,
    STORE_PLAN_ID_MEMBER,
    STORE_PLAN_ID_TRIAL,
    STORE_PLAN_ID_ULTIMATE,
    STORE_PLAN_ID_PRO,
    STORE_PLAN_ID_BASIC
} from '@/common/constants.js'
import Tooltip from '@/common/directive/Tooltip'

export default {
    components: {
        Tooltip,
        EmptyResponse,
        BootstrapIcon
    },
    data: function () {
        return {
            PLAN_ACTIVE,
            PLAN_PASTDUE,
            PLAN_INACTIVE,
            PLAN_CANCELED,
            STORE_PLAN_ID_TRIAL,
            STORE_PLAN_ID_BASIC,
            STORE_PLAN_ID_PRO,
            STORE_PLAN_ID_MEMBER,
            STORE_PLAN_ID_ULTIMATE,
            SUBSCRIPTION_PLAN_NAME,
            billing_interval: true,
            has_pricing: false
        }
    },
    computed: {
        email: function () {
            return this.$store.state.user.email
        },
        organization: function () {
            return this.$store.state.organization
        },
        billing_interval_key: function () {
            return this.billing_interval ? 'year' : 'month'
        },
        trials: function () {
            return this.$store.state.trials
        },
        BILLING_CYCLE_NA: () => BILLING_CYCLE_NA,
        BILLING_CYCLE_MONTHLY: () => BILLING_CYCLE_MONTHLY,
        BILLING_CYCLE_YEARLY: () => BILLING_CYCLE_YEARLY,
        PLAN_STATUS: () => PLAN_STATUS,
        BILLING_CYCLE: () => BILLING_CYCLE,
        is_authorized: function () {
            return this.$store.state.is_authorized
        },
        subscriptions: function () {
            return this.$store.state.subscriptions
        },
        pricing: function () {
            return this.$store.state.pricing
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        await this.get_pricing()
    },
    methods: {
        get_pricing: async function () {
            const response = await this.$store.dispatch('GET_PRICING')
            this.loaded = true

            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.has_pricing = true
                }
            }
        },
        update_subscription: async function (subscription, field) {
            const payload = {}
            payload.id = subscription.id
            payload.data = {}
            payload.data[field] = !subscription[field]
            const response = await SubscriptionStore.dispatch('UPDATE', payload)
            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Success', msg: 'Operation completed!', level: 'success' })
            } else if (typeof response.error_code !== 'undefined') {
                if (response.error_code === 409) {
                    this.$bus.$emit('Notification/show', { title: 'Error Saving Data', msg: 'Operation not permitted!', level: 'danger' })
                }
            } else {
                this.$bus.$emit('Notification/show', { title: 'Error', msg: 'Please retry again or refresh the page!', level: 'danger' })
            }
        },
        checkout_plan: async function () {
            await SubscriptionStore.dispatch('CHECKOUT')
        },
        activate_trial: async function (plan_id) {
            const res = await SubscriptionStore.dispatch('ACTIVATE_TRIAL', plan_id)
            if (res.status === 200) {
                this.$bus.$emit('Notification/show', { title: 'Success', msg: 'Trial subscription successfully activated!', level: 'success' })
                this.$store.commit('TRACK_GOAL', { goal: 'Activate-store_trial', props: {} })
                await this.$store.dispatch('GET_INFO')
            } else {
                if (res.status === 409) {
                    this.$bus.$emit('Notification/show', { title: 'Not eligible', msg: 'It seems that you have already benefit from this trial subscription.', level: 'warning' })
                } else {
                    this.$bus.$emit('Notification/show', { title: 'Error', msg: 'Please retry again or refresh the page!', level: 'danger' })
                }
            }
        },
        checkout: async function (plan) {
            const self = this
            const q = {
                product: plan.product_id[this.billing_interval_key],
                successCallback: function (data) {
                    self.$store.commit('TRACK_GOAL', {
                        goal: `Subscribe-${plan.plan_id}`,
                        props: {
                            completed: data.checkout.completed,
                            cycle: self.billing_interval_key,
                            country: data.user.country
                        }
                    })
                    self.checkout_data = {
                        plan,
                        product: data.product,
                        checkout: data.checkout,
                        user: data.user
                    }
                    self.$bus.$emit('Modal/show/checkout-completed')
                },
                closeCallback: function (data) {
                    self.$store.commit('TRACK_GOAL', {
                        goal: `Subscribe-${plan.plan_id}`,
                        props: {
                            completed: data.checkout.completed,
                            cycle: self.billing_interval_key,
                            country: data.user.country
                        }
                    })
                }
            }
            if (this.is_authorized) {
                q.email = this.email
                q.passthrough = JSON.stringify({
                    organization_id: this.organization.id
                })
            }

            if (this.pricing.environment === 'SANDBOX') {
                window.Paddle.Environment.set('sandbox')
            }
            window.Paddle.Setup({
                vendor: this.pricing.vendor_id
                // eventCallback: function (data) {
                //     if (data.event === 'Checkout.PaymentComplete') {
                //         // Check to ensure the payment has not been flagged for manual fraud review
                //         if (!data.eventData.flagged) {
                //             var checkoutId = data.eventData.checkout.id
                //             window.Paddle.Order.details(checkoutId, function (data) {
                //                 // Order data, downloads, receipts etc... available within 'data' variable.
                //                 console.log(data)
                //             })
                //         } else {
                //             // Payment has not been fully processed at the moment, so order details cannot be retrieved
                //             console.log('Transaction pending review')
                //         }
                //     }
                // }
            })
            window.Paddle.Checkout.open(q)
        }
    }
}
</script>
