<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">Audit Log</h1>
                <h2 class="h6">View complete audit logs for your organization.</h2>
            </div>
        </div>
        <div v-if="paginator.count>0" class="row">
            <div class="col">
                <div class="border rounded shadow-sm bg-white">
                    <table class="table table-hover">
                        <thead class="fw-normal small text-gray-600">
                            <tr>
                                <th scope="col">User</th>
                                <th scope="col">Action</th>
                                <th scope="col">IP</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in logs" :key="index">
                                <td>{{log.user_email}}</td>
                                <td><span class="badge bg-secondary fs-6 fw-normal">{{log.name}}</span></td>
                                <td>{{log.ip}}</td>
                                <td>{{$filters.PrettyDate(log.created_at)}}</td>
                            </tr>
                            <!-- <tr>
                                <td colspan="4">
                                    <div v-if="Object.keys(log.new_data).length > 0 || Object.keys(log.old_data).length > 0">
                                        <p class="m-0">New values</p>
                                        <pre><code>{{log.new_data}}</code></pre>
                                        <p class="m-0">Previous</p>
                                        <pre><code>{{log.old_data}}</code></pre>
                                    </div>
                                    <div v-else>N/A</div>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="Audit log is empty." />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mt-4">
                    <Paginator v-if="paginator.count>0" :tag="'audit_logs'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BootstrapIcon from '@/common/directive/BootstrapIcon'
// import Tooltip from '@/common/directive/Tooltip'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import Paginator from '@/common/directive/Paginator.vue'
import AuditLogStore from '@/common/store/auditlog.js'

export default {
    components: {
        Paginator,
        EmptyResponse
    },
    data: function () {
        return {
            paginate_timeout: 0
        }
    },
    computed: {
        logs: () => AuditLogStore.state.logs,
        paginator: () => AuditLogStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        await this.paginate()

        this.$bus.$off('Paginator/audit_logs/set_page')
        this.$bus.$on('Paginator/audit_logs/set_page', (value) => {
            this.set_page(value)
        })
    },
    methods: {
        set_page: async function (page) {
            AuditLogStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                await AuditLogStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
                // self.$bus.$emit('ListingLoader/hide')
            }, 50)
        }
    }
}
</script>