<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col">
                <h1 class="h5">
                    <BootstrapIcon class="me-3 align-middle" color="fill-gray-700 text-gray-700" name="people" :size="20" />
                    <span class="align-middle">Team Members</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="bg-white shadow-sm rounded px-3 py-2 mb-3 d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1">
                        <span>You have <span class="fs-5">{{paginator.count}}/</span>{{quota.member_limit}} members.</span>
                        <Tooltip class="ms-2" value="View complete quota details in the Subscriptions page." icon="info-circle" />
                    </div>
                    <button v-if="paginator.count < quota.member_limit && (user.role === 'admin' || user.permissions.api.includes('POST_/v1/members'))" @click="request_create_member()" type="button" class="btn btn-sm btn-primary bg-gradient px-4">Add member</button>
                </div>
            </div>
        </div>

        <div v-if="paginator.count > 0" class="row">
            <div class="col">
                <div class="rounded shadow-sm bg-white px-3 py-3 table-responsive">
                    <table class="table table-sm table-hover">
                        <thead class="fw-normal small text-gray-700">
                            <tr class="">
                                <th scope="col" class="border-0 pb-3">Username</th>
                                <th scope="col" class="border-0 pb-3">Role</th>
                                <th scope="col" class="border-0 pb-3">Sessions</th>
                                <th scope="col" class="border-0 pb-3">Assigned Workplace(s)</th>
                                <th scope="col" class="border-0 pb-3">PIN</th>
                                <th scope="col" class="border-0 pb-3 text-end">Actions</th>
                            </tr>
                            <tr>
                                <th>
                                    <input v-model="filter.username" type="text" class="form-control" placeholder="Filter by username">
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700  border-top-0">
                            <tr v-for="(member, index) in members" :key="index" class="align-middle">
                                <td>
                                    <div class="fw-bolder">
                                        <HighlightText :search="filter.username" :value="member.username" />
                                    </div>
                                    <div class="text-nowrap small">{{$filters.PrettyDate(member.created_at)}}</div>
                                </td>
                                <td>
                                    <span :class="{'bg-purple-400': member.role==='delivery', 'bg-orange-400': member.role==='sales'}" class="badge rounded-pill text-white fw-bolder d-block">{{member.role}}</span>
                                </td>
                                <td>
                                    <div v-if="member.sessions.length > 0" class="rounded overflow-hidden border">
                                        <table class="table table-light small text-gray-700 mb-0">
                                            <thead>
                                                <tr class="align-middle">
                                                    <td class="text-gray-600 text-center">IP</td>
                                                    <td class="text-gray-600 text-center">Browser</td>
                                                    <td class="text-gray-600 text-center">Created at</td>
                                                    <td class="text-gray-600 text-center">Actions</td>
                                                </tr>
                                            </thead>
                                            <tbody class="border-top-0">
                                                <tr v-for="(session, index) in member.sessions" :key="index">
                                                    <td><p class="text-gray-700 m-0 text-center">{{session.ip}}</p></td>
                                                    <td><p class="text-gray-700 m-0 text-center">{{$filters.ParseUserAgent(session.ua)}}</p></td>
                                                    <td><p class="text-gray-700 m-0 text-center">{{$filters.PrettyDate(session.created_at)}}</p></td>
                                                    <td class="text-center">
                                                        <button v-if="user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/members/sessions/:member_id/:session_id')" @click="delete_session(member, session)" type="button" class="btn btn-sm btn-outline-danger">Remove</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else>
                                        No active sessions
                                    </div>
                                </td>
                                <td>
                                    <span v-if="Object.keys(member.locations).length === 0">All</span>
                                    <div v-else>
                                        <div v-for="(name, id) in member.locations" :key="id">{{name}}</div>
                                    </div>
                                </td>
                                <td>
                                    <span class="fw-bolder">{{member.pin}} <Clipboard class="ms-2" :value="member.pin.toString()" /></span>
                                </td>
                                <td class="text-nowrap text-end">
                                    <button v-if="(user.role === 'admin' || user.permissions.api.includes('PUT_/v1/members/:member_id/pin'))" type="button" @click="generate_pin(member)" class="btn btn-sm btn-success bg-gradient mx-1">
                                        Generate PIN
                                    </button>
                                    <button v-if="(user.role === 'admin' || user.permissions.api.includes('PUT_/v1/members/:member_id'))" type="button" @click="request_edit_member(member)" class="btn btn-sm btn-secondary bg-gradient mx-1">
                                        Edit
                                    </button>
                                    <button v-if="(user.role === 'admin' || user.permissions.api.includes('DELETE_/v1/members/:member_id'))" type="button" @click="request_confirm_delete_member(member)" class="btn btn-sm btn-danger">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse description="No members created so far. Use the create button." />
            </div>
        </div>

        <Paginator v-if="paginator.count>0" :tag="'members'" :page="paginator.page" :limit="paginator.limit" :records="paginator.count" class="pt-3" />

        <ModalManageStoreMember :member="selected_member" />
        <ModalConfirmDeleteMember :member="selected_member" />
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import Tooltip from '@/common/directive/Tooltip'
import HighlightText from '@/common/directive/HighlightText.vue'
import Clipboard from '@/common/directive/Clipboard'
import InventoryMemberStore from '@/common/store/inventory_member.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import Paginator from '@/common/directive/Paginator.vue'
import ModalManageStoreMember from '@/common/modal/console/modal-manage_store_member.vue'
import ModalConfirmDeleteMember from '@/common/modal/console/modal-confirm_delete_member.vue'

export default {
    components: {
        Tooltip,
        Clipboard,
        Paginator,
        BootstrapIcon,
        HighlightText,
        // ModalManageKey,
        // ModalConfirmDeleteKey,
        // BootstrapIcon,
        EmptyResponse,
        ModalManageStoreMember,
        ModalConfirmDeleteMember
    },
    data: function () {
        return {
            paginate_timeout: 0,
            selected_member: {},
            filter: {
                username: '',
                name: ''
            }
        }
    },
    computed: {
        user: function () {
            return this.$store.state.user
        },
        quota: function () {
            return this.$store.state.quota
        },
        members: () => InventoryMemberStore.state.members,
        paginator: () => InventoryMemberStore.state.paginator,
        is_authorized: function () {
            return this.$store.state.is_authorized
        }
    },
    watch: {
        filter: {
            handler () {
                this.set_page(1)
            },
            deep: true
        }
    },
    created: async function () {
        if (!this.is_authorized) {
            this.$router.replace('/login')
        }

        this.$bus.$off('Paginator/members/set_page')
        this.$bus.$on('Paginator/members/set_page', (value) => {
            this.set_page(value)
        })
        await this.paginate()
    },
    methods: {
        set_page: async function (page) {
            InventoryMemberStore.commit('SET_PAGE', page)
            window.scroll({ top: 0 })
            this.paginate()
        },
        paginate: async function () {
            const self = this
            clearTimeout(this.paginate_timeout)
            // self.$bus.$emit('ListingLoader/show')
            this.paginate_timeout = setTimeout(async function () {
                const query = {}
                if (self.filter.username && self.filter.username.length >= 1) {
                    query.username = self.filter.username
                }
                if (self.filter.name && self.filter.name.length >= 1) {
                    query.name = self.filter.name
                }
                await InventoryMemberStore.dispatch('PAGINATE', {
                    page: self.paginator.page,
                    ...query
                })
            }, 200)
        },
        request_create_member: function () {
            this.selected_member = {}
            this.$bus.$emit('Modal/show/manage-store-member')
        },
        request_edit_member: function (member) {
            this.selected_member = member
            this.$bus.$emit('Modal/show/manage-store-member')
        },
        request_confirm_delete_member: function (member) {
            this.selected_member = member
            this.$bus.$emit('Modal/show/confirm-delete-member')
        },
        generate_pin: async function (member) {
            const response = await InventoryMemberStore.dispatch('GENERATE_PIN', {
                id: member.id
            })

            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        delete_session: async function (member, session) {
            const response = await InventoryMemberStore.dispatch('DELETE_SESSION', {
                session_id: session.id,
                member_id: member.id
            })

            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The session has been deleted!', level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        }
    }
}
</script>
